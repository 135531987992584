<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import {Ref, ref, onMounted, onBeforeUnmount, watch, computed, ComputedRef, PropType, nextTick} from 'vue';
import OptionValue from '@/interfaces/option.value.interface';
import {useDefine} from '@/Composables/Define';
import {useTranslate} from '@/Composables/Translate';
import OptionsListItem from '@/interfaces/options.list.item.interface';
import Popup from '@/services/popup.service';
import {Subscription} from 'rxjs';
import Countries from '@/services/countries.service';
import PopupType from '@/Enums/PopupTypeEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppPopup from '@/Components/Popup/Popup.vue';
import AppCountry from '@/assets/libraries/app/app-country';

const props = defineProps({
    formField: {type: FormField<OptionValue[]>, default: () => new FormField('')},
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    showOnMobile: {type: Boolean, default: true},
    message: {type: String, default: ''},
    locked: {type: Boolean, default: false},
    popupLabel: {type: String, default: ''},
    maxDefaultElements: {type: Number, default: 5},
    iconPattern: {type: String, default: '/images/one/flags/%.png'},
    customClass: {type: String, default: ''},
    staticText: {type: String, default: ''},
    validRegions: {type: Array, default: () => []},
    countryCodes: {
        type: Object as PropType<DynamicDictionary>, default: () => {
            return {};
        }
    },
});

const emit = defineEmits(['close', 'change', 'input', 'close-message', 'country-selection', 'options-updated']);

const {translate} = useTranslate();
const popup: Popup = Popup.getInstance();
const inputSearch: Ref<HTMLElement | null> = ref(null);
const MaxVisibleSelectedCountriesInOpener: number = 1;
const {isSet} = useDefine();
const isVisibleDropDown: ComputedRef<boolean> = computed(() => {
    return typedValue.value !== '' || inputInFocus.value;
});
const openerSelectedCountries: ComputedRef<OptionValue[]> = computed(() => {
    let result: OptionValue[] = [];
    if (country.value.length > 0) {
        for (let i: number = 0; i < MaxVisibleSelectedCountriesInOpener; i++) {
            result.push(country.value[i]);
        }
    }

    return result;
});
const countryMore: ComputedRef<string> = computed(() => {
    let result: string = '';
    if (country.value.length > 1) {
        result = '(' + (country.value.length - 1) + ')+';
    }

    return result;
});
const countriesText: ComputedRef<string> = computed(() => {
    let result: string = '';
    if (props.formField.value) {
        result = props.formField.value
            .map((country: DynamicDictionary): string[] => country.value)
            .join(' / ');
    }

    return result;
});

let countries: Ref<DynamicDictionary> = ref({});
let country: Ref<OptionValue[]> = ref([]);
let backup: Ref<OptionValue[]> = ref([]);
let popularCountries: Ref<DynamicDictionary> = ref({});
let visible: Ref<boolean> = ref(false);
let typedValue: Ref<string> = ref('');
let options: Ref<OptionsListItem[]> = ref([]);
let popularOptions: Ref<OptionsListItem[]> = ref([]);
let index: number = 0;
let inputInFocus: Ref<boolean> = ref(false);
let isVisibleSearch: Ref<boolean> = ref(false);
let messageIsVisible: Ref<boolean> = ref(false);
let patchSubscribe: Ref<Subscription | null> = ref(null);

const UnlimitedMaxElement: number = -1;

onMounted((): void => {
    setupCountries();
    checkMessageVisibility();
    patchSubscribe.value = props.formField.onPatch.subscribe(() => {
        onPatch();
    });
});

onBeforeUnmount((): void => {
    if (patchSubscribe.value) {
        patchSubscribe.value.unsubscribe();
    }
});

watch(() => props.message, (newValue) => {
    messageIsVisible.value = newValue !== '';
})

watch(() => props.formField.value, () => {
    setupCountries();
    props.formField.validate();
    emitChange();
})

function showListSearch(): void {
    if (!props.disabled) {
        prepareOptionsList();
    }
}

function isVisibleItem(current: OptionsListItem): boolean {
    let result: boolean = false;
    if (countryIsPresent(current)) {
        if (typedValue.value || props.maxDefaultElements === UnlimitedMaxElement) {
            const normalizedTitle: string = Countries.normalizedSearchCountry(current.title);
            result = normalizedTitle.includes(Countries.normalizedSearchCountry(typedValue.value));
        } else if (inputInFocus.value) {
            result = current.index < props.maxDefaultElements;
        }
    }

    return result;
}

function isVisiblePopularItem(current: OptionsListItem): boolean {
    return countryIsPresent(current);
}

function addCountry(): void {
    isVisibleSearch.value = true;
    nextTick(() => {
        $(inputSearch).trigger('focus');
    });
}

function onFocus(): void {
    inputInFocus.value = true;
}

function buttonApplyClick(): void {
    close();
}

function closePopup(): void {
    restoreCountries();
    close();
}

function closeMessage(): void {
    messageIsVisible.value = false;
    emit('close-message');
}

function addCountryText(): string {
    return translate('btar_add_country');
}

function removeCountry(item: OptionValue): void {
    let countries: OptionValue[] = [];
    country.value.forEach((value: OptionValue) => {
        if (value.id !== item.id) {
            countries.push(value);
        }
    });
    country.value = countries;
    props.formField.patch(country.value, false);
    props.formField.touch();
}

function onOptionSelectClick(option: OptionsListItem): void {
    const countryCodeIndex: number = 1;
    let countryValue: OptionValue = new class implements OptionValue {
        id: string = option.id;
        value: string = option.title;
    };
    if (useCountryCodes()) {
        const valueParts: string[] = String(countryValue.id).split(':');
        const countryCode: string = valueParts[countryCodeIndex];
        if (Object.keys(props.countryCodes).includes(countryCode)) {
            (countryValue as OptionValue).custom = props.countryCodes[countryCode];
        }
    }
    country.value.push(countryValue);
    props.formField.patch(country.value, false);
    props.formField.touch();
    isVisibleSearch.value = false;
    inputInFocus.value = false;
    emitChange();
}

function selectedCountryIcon(item: OptionValue): string {
    return mappedIcon(item, props.iconPattern);
}

function useCountryCodes(): boolean {
    return isSet(props.countryCodes) && Object.keys(props.countryCodes).length > 0;
}

function applyButtonText(): string {
    return translate('btar_apply');
}

function checkMessageVisibility(): void {
    messageIsVisible.value = props.message !== ''
}

function countryIsPresent(current: OptionsListItem): boolean {
    let found: boolean = false;
    country.value.forEach((value: OptionValue) => {
        if (value.id === current.id) {
            found = true;
        }
    });

    return !found;
}

function countriesIconMapper(values: string[]): string {
    const id: string = values[0];
    const iconPattern: string = values[1];

    return mappedIcon(id, iconPattern);
}

function mappedIcon(idValue: any, iconPattern: string): string {
    const valueParts: string[] = String(idValue).split(':');
    const countryIso: string = String(valueParts[1]).toLowerCase();
    const pathParts: string[] = iconPattern.split('%');

    return pathParts[0] + countryIso + pathParts[1];
}

function setupCountries(): void {
    const regionIcIndex: number = 4;
    const countryCodeIndex: number = 1;
    if (Object.keys(countries.value).length === 0) {
        const countriesService: Countries = Countries.getInstance();
        const fetchedCountries: DynamicDictionary = countriesService.fetchFilteredCountries();
        if (isSet(props.validRegions) && props.validRegions.length > 0 && useDefine().objectMembersCount(countries) > 0) {
            for (let key in fetchedCountries) {
                const valueParts: string[] = String(key).split(':');
                const regionIc: string = valueParts[regionIcIndex];
                if (props.validRegions.includes(regionIc)) {
                    countries.value[key] = fetchedCountries[key];
                }
            }
        } else {
            if (useCountryCodes()) {
                for (let key in fetchedCountries) {
                    const valueParts: string[] = String(key).split(':');
                    const countryCode: string = valueParts[countryCodeIndex];
                    if (Object.keys(props.countryCodes).includes(countryCode)) {
                        countries.value[key] = fetchedCountries[key];
                    }
                }
            } else {
                countries.value = fetchedCountries;
            }
        }
    }
    if (Object.keys(popularCountries.value).length === 0) {
        popularCountries.value = Countries.getInstance().fetchFilteredPopularCountries();
        preparePopularCountries();
    }
    if (!isEmptyValue()) {
        country.value = Array.isArray(props.formField.value) ? props.formField.value : [];
    }
}

function onPatch(): void {
    country.value = [];
    props.formField.patch(country.value, false)
    props.formField.touch();
}

function prepareOptionsList(): void {
    clear();
    for (let o in countries.value) {
        let icon: string = countriesIconMapper([o, props.iconPattern]);
        addItem(o, countries.value[o], icon);
    }
    typedValue.value = '';
    visible.value = true;
    backupCountries();
    popup.showPopup(PopupType.CustomPopup);
}

function preparePopularCountries(): void {
    for (let o in popularCountries.value) {
        let icon: string = countriesIconMapper([o, props.iconPattern]);
        addPopularItem(o, popularCountries.value[o], icon);
    }
}

function clear(): void {
    options.value = [];
    index = 0;
}

function isEmptyValue(): boolean {
    return props.formField.value.length === 0;
}

function addItem(id: string, title: string, icon: string = ''): void {
    const itemIndex: number = index++;
    options.value.push(
        new class implements OptionsListItem {
            id: string = id;
            title: string = title;
            icon: string = icon;
            index: number = itemIndex;
        }
    );
}

function addPopularItem(id: string, title: string, icon: string = ''): void {
    popularOptions.value.push(
        new class implements OptionsListItem {
            id: string = id;
            title: string = title;
            icon: string = icon;
            index: number = 0;
        }
    );
}

function close(): void {
    visible.value = false;
    popup.showPopup(PopupType.None);
    emit('close')
    emit('close-message');
}

function backupCountries(): void {
    if (Array.isArray(props.formField.value)) {
        backup.value = JSON.parse(JSON.stringify(props.formField.value));
    } else {
        backup.value = [];
    }
}

function restoreCountries(): void {
    country.value = backup.value;
    backup.value = [];
    props.formField.patch(country.value, false);
    props.formField.touch();
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('change', props.formField.value);
}

function onDropDownClickOutside(): void {
    if (inputInFocus.value) {
        inputInFocus.value = false;
        isVisibleSearch.value = false;
    }
}
</script>

<template>
    <div class="input input-country"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <div class="label informative"
             :class="{'hide-on-mobile' : !showOnMobile}">
            <span>{{ label }}</span>
            <slot name="tooltipster"></slot>
        </div>
        <div class="wrapper select"
             :class="customClass">
            <div class="current button cursor-pointer"
                 :id="formField.name + '-showListSearch'"
                 @click="showListSearch">
                <div class="countries-list">
                    <div class="country"
                         :key="index"
                         v-if="staticText === ''"
                         v-for="(item, index) in openerSelectedCountries">
                        <img class="icon"
                             :id="formField.name + '-icon-' + index"
                             v-if="selectedCountryIcon(item.id) !== ''"
                             :src="selectedCountryIcon(item.id)"
                             alt="">
                        <span class="text">{{ item.value }}</span>
                    </div>
                    <div class="more"
                         v-if="countryMore !== '' && staticText === ''"
                    >{{ countryMore }}
                    </div>
                </div>
                <div class="text"
                     v-if="staticText !== ''">{{ countriesText || staticText }}
                </div>
                <slot v-if="staticText !== ''" name="static-text-tooltip"></slot>
                <span class="icon arrow-icon">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1L7 7L1 1" stroke="#E30613" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </span>
            </div>
        </div>
        <div class="popups">
            <app-popup v-if="visible" class="simple list-search" @close="closePopup()">
                <div class="title">
                    {{ popupLabel || label }}
                    <slot name="tooltipster-opener"></slot>
                </div>
                <div class="elements">
                    <div class="field field-gap">
                        <div class="added"></div>
                        <div class="selected-countries">
                            <button v-for="(item, index) in country"
                                    :key="index"
                                    :id="formField.name + '-removeCountry-' + index"
                                    class="country-button"
                                    @click="removeCountry(item)">
                                <img class="icon"
                                     v-if="selectedCountryIcon(item.id) !== ''"
                                     :src="selectedCountryIcon(item.id)"
                                     alt="">
                                <span class="text">{{ item.value }}</span>
                                <span class="remove">
                                    <svg width="10" height="10" viewBox="0 0 14 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L13 13M13 1L1 13" stroke="#9297A0" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </span>
                            </button>
                            <button class="country-button country-button-add"
                                    :id="formField.name + '-addCountry'"
                                    @click="addCountry()">
                                <span class="add">
                                    <svg width="16" height="16" viewBox="0 0 14 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L13 13M13 1L1 13" stroke="#9297A0" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </span>
                                <span class="text add-text">{{ addCountryText() }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="message"
                         v-if="messageIsVisible">
                        <span class="message-text" v-html="message"></span>
                        <button class="message-close"
                                :id="formField.name + '-closeMessage'"
                                @click="closeMessage()">
                            <svg width="10" height="10" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L13 13M13 1L1 13" stroke="#FFFFFF" stroke-width="2"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                    <div :id="formField.name"
                         class="field"
                         v-click-outside="onDropDownClickOutside"
                         v-if="isVisibleSearch">
                        <div class="search">
                            <div class="icon">
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.48">
                                        <path d="M14 14L20 20" stroke="#9297A0" stroke-width="2"></path>
                                        <circle cx="8.5" cy="8.5" r="7.5" stroke="#9297A0"
                                                stroke-width="2"></circle>
                                    </g>
                                </svg>
                            </div>
                            <input v-model="typedValue"
                                   :id="formField.name + '-typedValue'"
                                   @focus="onFocus()"
                                   class="text"
                                   ref="inputSearch"
                                   :placeholder="placeholder"/>
                        </div>
                        <div class="dropdown"
                             :class="{'hidden':!isVisibleDropDown}">
                            <button v-for="(item, index) in options"
                                    :key="index"
                                    class="country-item"
                                    :id="formField.name + '-selectItem'"
                                    :class="{'hidden':!isVisibleItem(item)}"
                                    :data-value="item.title"
                                    :data-id="item.id"
                                    @click="onOptionSelectClick(item)">
                                <img class="icon"
                                     v-if="item.icon"
                                     :src="item.icon"
                                     alt="">
                                <span class="text">{{ item.title }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="popular-items">
                    <button v-for="(item, index) in popularOptions"
                            :key="index"
                            class="popular-country-item"
                            :class="{'hidden':!isVisiblePopularItem(item)}"
                            :data-id="item.id"
                            :data-value="item.title"
                            :id="formField.name + '-popularItem-' + index"
                            @click="onOptionSelectClick(item)">
                        <img class="icon"
                             v-if="item.icon"
                             :src="item.icon"
                             alt="">
                        <span class="text">{{ item.title }}</span>
                    </button>
                </div>
                <div class="apply-button-container">
                    <button class="button red"
                            :disabled="locked"
                            :id="formField.name + '-applyButton'"
                            @click="buttonApplyClick()">{{ applyButtonText() }}
                    </button>
                </div>
            </app-popup>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-country {
    > .informative.hide-on-mobile {
        display: none;

        @include respond-above('sm') {
            display: inline-flex;
        }
    }

    > .wrapper > .current {
        > .flag {
            margin-right: var(--size-pico);
        }

        > .countries-list {
            display: flex;
            width: 100%;
            overflow: hidden;
            position: relative;
            padding: 0 var(--size-big) 0 0;
            align-items: center;
            height: 52px;
            flex-shrink: 0;

            > .country {
                img {
                    width: 34px;
                    height: 20px;
                    margin-right: var(--size-nano);
                }
            }

            > .country > .text,
            > .more {
                font-size: var(--font-size-tiny);
                font-weight: 500;
                color: var(--black-500);
            }

            > .more {
                margin-left: 5px;
            }
        }

        .text {
            color: var(--black-500);
        }
    }

    &.invalid {
        .country-panel {
            .button {
                border-color: var(--brand-red);
            }
        }
    }
}

.panel {
    height: 100%;

    .current.button {
        height: 100%;
        border: none;

        .countries-list {
            padding: 0;
            width: auto;
        }

        .text {
            font-size: var(--font-size-tiny);
            font-weight: 400;
            text-align: center;
        }

        &:hover,
        &:active,
        &:focus {
            .text {
                color: var(--brand-red);
            }
        }
    }

    .flag {
        display: none;
    }

    .icon {
        display: none;
    }
}

.country-panel {
    height: 100%;

    .current.button {
        height: 100%;

        .countries-list {
            padding: 0;
            width: auto;
        }

        .text {
            font-size: var(--font-size-tiny);
            color: var(--text-color-default);
            font-weight: 600;
        }

        &:hover,
        &:active,
        &:focus {
            .text {
                color: var(--brand-red);
            }
        }
    }

    .flag {
        display: none;
    }
}

.field-gap {
    margin-bottom: var(--size-femto);
}
</style>
