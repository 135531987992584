<script lang="ts">
import {computed, defineComponent, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import {useTranslate} from '@/Composables/Translate';
import {useNavigate} from "@/Composables/Navigate";
import VueEvent from '@/Classes/VueEventClass';
import CssClass from '@/Enums/CssClassEnum';
import FormField from '@/assets/libraries/form/form-field';
import moment from 'moment';
import Validation from '@/services/validation.service';
import User from '@/services/user.service';
import UserCredentials from '@/interfaces/user.credentials.interface';
import Value from '@/assets/libraries/form/value';
import Sanitizer from '@/services/sanitizer.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {Subscription} from 'rxjs';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import {useScroll} from '@/Composables/Scroll';
import OneDate from '@/assets/libraries/Date/OneDate';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import SummaryValidators from './SummaryValidators';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/ButtonWithCallback/Enums/button.border.enum';
import {CoveredPopupBuilder} from '@/pages/Bicycle/Classes/CoveredPopupBuilder';
import CoveredPopupTypes from '@/Components/CoveredPopup/Enums/CoveredPopupTypes';
import UserStorage from '@/services/user.storage.service';
import {Bicycle} from '@/interfaces/resources/Bicycles/BicycleInterface';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import SettingsService from '@/services/settings.service';
import AppCountry from '@/assets/libraries/app/app-country';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const user: User = User.getInstance();
        const {translate} = useTranslate();
        const {translateForType} = useTranslate();
        const translationType: string = 'bicycle_policy';
        const stepsSubmitter = useStepsSubmitter();
        const CurrentStep: number = 3;
        const Facility: string = 'bicycle_policy';
        const ConsentsGlue: string = ':';
        const form: Form = new Form();
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
        const formIsReady: Ref<boolean> = ref(false);
        const FormFields: string[] = [
            'policyStartDate',
            'policyHolderIsResident',
            'policyHolderFirstName',
            'policyHolderLastName',
            'policyHolderIdentityNumber',
            'policyHolderCountry',
            'policyHolderBirthDate',
            'policyHolderEmail',
            'policyHolderPhone',
            'policyHolderSameAsInsured',
            'agreeTerms',
            'marketingConsents',
        ];
        const policyStartMinDate: Date = moment().add(1, 'days').toDate();
        const policyStartMaxDate: Date = moment().add(30, 'days').toDate();
        const bankLinkId: Ref<number> = ref(0);
        const detailsBirthDate: Ref<string> = ref('');
        const validators: SummaryValidators = new SummaryValidators();
        const informationText: Ref<string | null> = ref(null);

        const showMarketingConsents: Ref<boolean> = computed(() => {
            return !btaBase.user.current.receiveMarketing;
        });

        const isAuthenticatedUser: Ref<boolean> = computed(() => {
            return user.isLogged();
        });

        const canProceed: Ref<Boolean> = computed(() => {
            return form.field('agreeTerms').value
                && form.field('policyStartDate').value !== '';
        });

        const policyStartDate: Ref<string> = computed(() => {
            const result: Date = moment(form.field('policyStartDate').value.startDate).toDate();

            return OneDate.short(result);
        });

        const policyEndDate: Ref<string> = computed(() => {
            const startDate: Date = form.field('policyStartDate').value.startDate;
            const result: Date = moment(startDate)
                .add(1, 'year')
                .subtract(1, 'day')
                .toDate();

            return OneDate.short(result);
        });

        const isPaymentMethodSelected: Ref<boolean> = computed(() => {
            return bankLinkId.value !== 0;
        });

        const isLithuanian: Ref<boolean> = computed(() => {
            return form.field('policyHolderCountry').value.iso === 'LT';
        });

        const isEstonian: Ref<boolean> = computed(() => {
            return form.field('policyHolderCountry').value.iso === 'EE';
        });

        const policyHolderIsNonResident: Ref<boolean> = computed(() => {
            return !form.field('policyHolderIsResident').value;
        });

        const policyHolderSameAsInsured: Ref<boolean> = computed(() => {
            return form.field('policyHolderSameAsInsured').value;
        });

        const policyHolderBirthDateRequired: Ref<boolean> = computed(() => {
            return policyHolderIsNonResident.value || policyHolderCodeIsAnonymous.value;
        });

        const policyHolderCodeIsAnonymous: Ref<boolean> = computed(() => {
            const holderCodeField: FormField = form.field('policyHolderIdentityNumber');
            const holderCode: string = String(holderCodeField.value);

            return holderCodeField.isNotEmpty() && Validation.isValidPersonCodeWithoutDate(holderCode);
        });

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketingConsents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ?
                selectedConsents.split(ConsentsGlue) : [];
        }

        function touchAndRequireUserFields(): void {
            [
                'policyHolderFirstName',
                'policyHolderLastName',
                'policyHolderEmail',
                'policyHolderPhone',
                'agreeTerms',
            ].forEach((field: string): void => {
                form.field(field)
                    .addValidators('required')
                    .touch();
            })
        }

        function touchAndRequireGuestFields(): void {
            const guestFields: string[] = [
                'policyHolderEmail',
                'policyHolderPhone',
                'agreeTerms',
            ];
            if (!policyHolderSameAsInsured.value) {
                if (!policyHolderIsNonResident.value) {
                    guestFields.push('policyHolderFirstName');
                    guestFields.push('policyHolderLastName');
                    guestFields.push('policyHolderIdentityNumber');
                }
                if (policyHolderIsNonResident.value) {
                    guestFields.push('policyHolderFirstName');
                    guestFields.push('policyHolderLastName');
                    guestFields.push('policyHolderIdentityNumber');
                    guestFields.push('policyHolderCountry');
                }
                if (requiresBirthDate()) {
                    guestFields.push('policyHolderBirthDate');
                }
            }

            guestFields.forEach((field: string): void => {
                form.field(field)
                    .addValidators('required')
                    .touch();
            });
        }

        function patchDefaultValues(): void {
            form.field('policyStartDate').patch({startDate: minPolicyStartDate(), endDate: ''});
            isAuthenticatedUser.value ? patchUserFields() : patchGuestFields();
        }

        function patchUserFields(): void {
            const currentUser: UserCredentials = btaBase.user.current;
            form.field('policyHolderFirstName').patch(currentUser.firstname);
            form.field('policyHolderLastName').patch(currentUser.lastname);
            form.field('policyHolderIdentityNumber').setValue(currentUser.personCode);
            if (form.field('policyHolderEmail').isEmpty()) {
                form.field('policyHolderEmail').patch(currentUser.email);
            }
            if (form.field('policyHolderPhone').isEmpty()) {
                form.field('policyHolderPhone').patch({
                    code: currentUser.code,
                    iso: currentUser.code,
                    country: currentUser.phoneCode,
                    phone: currentUser.phone,
                });
            }
        }

        function patchGuestFields(): void {
            patchHolderAsInsured();
        }

        function onSameAsInsuredClick(): void {
            if (policyHolderSameAsInsured.value) {
                patchHolderAsInsured();
            } else {
                patchHolderAsGuest();
            }
        }

        function onIsResidentClick(): void {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            let residentCountry: DynamicDictionary = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            form.field('policyHolderIdentityNumber').clear();
            form.field('policyHolderPhone').patch({
                code: '',
                iso: settingsInstance.localeIso(),
                country: '',
                phone: '',
            });
            form.field('policyHolderPhone').markAsUntouched();
            if (form.field('policyHolderIsResident').value) {
                form.field('policyHolderCountry').patch(residentCountry);
            } else {
                form.field('policyHolderCountry').clear();
            }
        }

        function patchHolderAsInsured(): void {
            const policyHolder: DynamicDictionary = btaBase.userStorage.stepStorageData.insuredPerson;
            form.field('policyHolderIdentityNumber').clearSanitizer();
            form.field('policyHolderSameAsInsured').patch(true);
            form.field('policyHolderIsResident').patch(policyHolder.isResident);
            form.field('policyHolderFirstName').patch(policyHolder.firstName);
            form.field('policyHolderLastName').patch(policyHolder.lastName);
            form.field('policyHolderIdentityNumber').setValue(policyHolder.personCode);
            form.field('policyHolderBirthDate').patch(policyHolder.birthDate);
            form.field('policyHolderCountry').patch(policyHolder.country);
            if (form.field('policyHolderPhone').isEmpty()) {
                form.field('policyHolderPhone').patch({
                    code: '',
                    iso: policyHolder.country.iso,
                    country: policyHolder.country.phoneCode,
                    phone: '',
                });
            }
            form.field('policyHolderPhone').markAsUntouched();
        }

        function patchHolderAsGuest(): void {
            const policyHolder: DynamicDictionary = btaBase.userStorage.stepStorageData.insuredPerson;
            form.field('policyHolderSameAsInsured').patch(false);
            form.field('policyHolderIsResident').patch(true);
            form.field('policyHolderFirstName').clear();
            form.field('policyHolderLastName').clear();
            form.field('policyHolderIdentityNumber').clear();
            form.field('policyHolderCountry').clear();
            form.field('policyHolderBirthDate').clear();
            form.field('policyHolderPhone').patch({
                code: '',
                iso: form.field('policyHolderIsResident').value
                    ? new AppCountry().iso()
                    : policyHolder.country.iso,
                country: '',
                phone: '',
            });
            form.field('policyHolderPhone').markAsUntouched();
            form.field('policyHolderFirstName').markAsUntouched();
            form.field('policyHolderLastName').markAsUntouched();
            form.field('policyHolderIdentityNumber').markAsUntouched();
        }

        function isVisibleInformationalText(): boolean {
            return false;
        }

        function requiresBirthDate(): boolean {
            return notFromBaltics() && (policyHolderIsNonResident.value || policyHolderCodeIsAnonymous.value);
        }

        function notFromBaltics(): boolean {
            return !(isLithuanian.value || isEstonian.value);
        }

        function onCoveredClick(): void {
            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        function onFormSubmit(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            form.validate().then((): void => {
                if (form.isValid()) {
                    if (isPaymentMethodSelected.value) {
                        prepareSubmit();
                        stepsSubmitter.submitMethod(Method.Post);
                        stepsSubmitter.submitStep(Url.Ajax.bicyclePolicyPurchase);
                    } else {
                        scrollToBanksListAndMark();
                    }
                } else {
                    const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                    if (invalidElements.length > 0) {
                        invalidElements[0].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
                    }
                }
            });
        }

        function scrollToBanksListAndMark(): void {
            $('.payments > .title').addClass(CssClass.NotFilled);
            useScroll().scrollToLast('[data-alias="banks-list"]').then();
        }

        function redirectToBank(): void {
            useNavigate().navigate(Url.PaymentsApi.paymentsPay);
        }

        function policyHolder(): DynamicDictionary {
            return {
                firstName: form.field('policyHolderFirstName').value,
                lastName: form.field('policyHolderLastName').value,
                identityNumber: form.field('policyHolderIdentityNumber').value,
                country: country(),
                birthDate: birthDate(),
                email: form.field('policyHolderEmail').value,
                phone: form.field('policyHolderPhone').value,
                isResident: btaBase.user.isLogged() ? true : form.field('policyHolderIsResident').value,
                marketingConsents: marketingConsents(),
            };
        }

        function birthDate(): string {
            const user: User = btaBase.user;
            let result: string = '';
            if (user.isLogged()) {
                result = OneDate.iris(user.current.birthDate);
            } else {
                if (requiresBirthDate()) {
                    result = OneDate.iris(new Date(form.field('policyHolderBirthDate').value));
                }
            }

            return result;
        }

        function country(): DynamicDictionary {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            let source: DynamicDictionary = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            if (policyHolderIsNonResident.value) {
                source = form.field('policyHolderCountry').value
            }

            return source;
        }

        function touchAndRequireNecessaryFields(): void {
            isAuthenticatedUser.value
                ? touchAndRequireUserFields()
                : touchAndRequireGuestFields();
        }

        function choosePayment(event: VueEvent): void {
            let newBankLinkId: number = event.params.bankLinkId;
            if (newBankLinkId > 0 && bankLinkId.value !== newBankLinkId) {
                const newInformationText: string = event.params.informationText;
                event.sender.siblings('.' + CssClass.Active).removeClass(CssClass.Active);
                event.sender.toggleClass(CssClass.Active);
                bankLinkId.value = newBankLinkId;
                informationText.value = newInformationText ? translate(String(newInformationText).toLowerCase()) : null;
                $('.payments > .title').removeClass(CssClass.NotFilled);
            }
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', stepsSubmitter.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitParam('policyStartDate', policyDate(), false);
            stepsSubmitter.addSubmitParam('policyHolder', policyHolder(), false);
            stepsSubmitter.addSubmitParam('bankLinkId', bankLinkId.value, false);
            stepsSubmitter.addSubmitParam('paymentFailRedirectUrl',
                SubmitterUrls.getInstance().currentStep(), false);
            stepsSubmitter.addAjaxResponseCallbackAfterStepsStorage(redirectToBank);
        }

        function policyDate(): string {
            const date: Date = moment(form.field('policyStartDate').value.startDate).toDate();

            return OneDate.iris(date);
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function minPolicyStartDate(): Date {
            const offset: number = btaBase.settings.movablePropertySettings().minPolicyStartDateOffset;

            return moment().add(offset, 'day').toDate();
        }

        function onBeforeFormRestore(): void {
            patchDefaultValues();
        }

        function onAppReady(): void {
            applyFormValidationAndSanitization();
            buildCoverageOptions();
            applyDetailsBirthDate();
        }

        function applyFormValidationAndSanitization(): void {
            form.field('policyHolderFirstName')
                .addValidators(validators.captionValidator('policyHolderFirstName'));
            form.field('policyHolderLastName')
                .addValidators(validators.captionValidator('policyHolderLastName'));
            form.field('policyHolderIdentityNumber')
                .addValidators(validators.identityNumberValidator());
            form.field('policyHolderFirstName').addSanitizer(Sanitizer.cleanUppercaseName);
            form.field('policyHolderLastName').addSanitizer(Sanitizer.cleanUppercaseName);
            form.field('policyHolderPhone').addValidators('required');
            form.field('policyHolderEmail').addValidators('required');
            form.field('policyHolderBirthDate').addValidators('required');
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
                field.markAsUntouched();
            });
            if (!requiresBirthDate()) {
                form.field('policyHolderBirthDate').clear().then();
            }
        }

        function coveredButtonParams(): ButtonWithCallbackParams {
            return {
                title: translateForType('what_covered_bicycle', translationType),
                textColor: ButtonTextColor.Black,
                backgroundColor: ButtonBackground.White,
                backgroundColorHover: ButtonBackground.White,
                icon: ButtonIcon.Covered,
                iconColor: ButtonIconColor.Green,
                borderColor: ButtonBorder.Pale,
            };
        }

        function setupForm(): Promise<void> {
            return new Promise(resolve => {
                FormFields.forEach((field: string): void => {
                    form.addField(new FormField(field));
                });
                form.setReady();
                formIsReady.value = true;
                resolve();
            });
        }

        function buildCoverageOptions(): void {
            const productFromStorage: Bicycle | undefined = selectedProduct();
            if (productFromStorage) {
                coveredPopup.withCoveredTitle('what_covered_bicycle')
                    .withTranslationKey('bicycle_policy')
                    .withCoveredType(CoveredPopupTypes.CoveredPopupBicycleSingle)
                    .withContent(productFromStorage)
                    .build();
            }
        }

        function selectedProduct(): Bicycle | undefined {
            const storage: UserStorage = UserStorage.getInstance();
            const storedProductId: string = selectedProductId();
            return (storage.storageData as Bicycle[])
                .find((product: Bicycle): boolean => product.id === storedProductId);
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function applyDetailsBirthDate(): void {
            if (btaBase.userStorage.stepStorageData.insuredPerson.birthDate) {
                const birthDate: Date = moment(btaBase.userStorage.stepStorageData.insuredPerson.birthDate).toDate();
                detailsBirthDate.value = OneDate.short(birthDate);
            }
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                validators,
                formIsReady,
                onBackClick,
                onBeforeFormRestore,
                onAppReady,
                onCoveredClick,
                coveredButtonParams,
                applyStepUrls,
                isVisibleInformationalText,
                choosePayment,
                detailsBirthDate,
                selectedProductId,
                showMarketingConsents,
                canProceed,
                policyStartDate,
                policyEndDate,
                policyStartMinDate,
                policyStartMaxDate,
                policyHolderIsNonResident,
                policyHolderCodeIsAnonymous,
                policyHolderBirthDateRequired,
                isAuthenticatedUser,
                policyHolderSameAsInsured,
                isPaymentMethodSelected,
                isLithuanian,
                isEstonian,
                informationText,
                coveredPopup,
                setupForm,
                requiresBirthDate,
                onFormSubmit,
                onSameAsInsuredClick,
                onIsResidentClick,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        this.dynamicStepper.enableAll();
        this.validators.init(this.form);

        const onBeforeFormRestoredSubscription: Subscription =
            this.userStorage.onBeforeFormStorageDataIsRestored.subscribe((): void => {
                this.onBeforeFormRestore();
                onBeforeFormRestoredSubscription.unsubscribe();
            });

        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
