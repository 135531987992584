<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { onMounted, PropType, watch } from 'vue';
import { InputOption } from '@/interfaces/InputOptionInterface';

const props = defineProps({
    label: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
    dataStoreDisabled: {type: Boolean, default: false},
    options: {type: Array as PropType<InputOption[]>, default: () => []},
});

const emit = defineEmits(['change']);

watch(() => props.formField.value, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        emitChange();
    }
});

onMounted((): void => {
    props.formField.setIsValid(true);
});

function select(option: InputOption): void {
    if (option.value !== props.formField.value) {
        props.formField.setValue(option.value as string);
    }
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('change', props.formField.value);
}

function buttonHasDelimiter(index: number): boolean {
    const activeIndex: number = props.options
        .findIndex((option: InputOption): boolean => option.value === props.formField.value)

    return index !== props.options.length - 1
        && index !== activeIndex
        && (activeIndex > index + 1 || index + 1 > activeIndex);
}
</script>

<template>
    <div class="input input-radio-overlayed"
         :data-type="formField.name"
         :class="{ ...formField.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : `${formField.value}`">
        <div v-if="label" class="label informative">
            <p>{{ label }}</p>
            <slot name="app-tooltipster"></slot>
            <slot name="badge"></slot>
        </div>
        <div class="buttons">
            <button v-for="(option, index) in options"
                    class="overlay-button"
                    :key="index"
                    :class="{
                    'active': formField.value === option.value && !(option.disabled || disabled),
                    'with-delimiter': buttonHasDelimiter(index),
                }"
                    :disabled="option.disabled || disabled"
                    :data-type="formField.name + '-' + index"
                    :data-index="index"
                    @click="select(option)">
                <div class="badge" v-if="option.badgeText">{{ option.badgeText }}</div>
                <span class="option-text" v-html="option.name"></span>
                <span class="subtitle" v-if="option.custom" v-html="option.custom"></span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-radio-overlayed {
    width: 100%;
    scroll-margin-top: 4em;
    border-radius: 9px;

    .label {
        p {
            color: var(--text-color-default);
        }
    }

    .buttons {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--component-color-border-default);
        border-radius: 9px;
        height: 52px;
        background: var(--white);

        @include respond-above('sm') {
            gap: 4px;
        }

        .overlay-button {
            display: flex;
            flex-direction: column;
            gap: var(--size-femto);
            justify-content: center;
            align-items: center;
            align-content: flex-start;
            border-radius: var(--size-pico);
            position: relative;
            padding: var(--size-tiny);
            height: 100%;
            flex: 1 0 0;
            color: var(--text-color-default);
            font-size: var(--size-tiny);
            font-weight: 600;

            @include respond-above('sm') {
                padding: var(--size-small);
            }

            .badge {
                position: absolute;
                top: -24px;
                min-width: 153px;
                height: var(--size-medium);
                padding: var(--size-pico);
                background: var(--system-color-success-dark);
                color: var(--text-color-inverse);
                font-size: var(--font-size-nano);
                line-height: 18px;
                font-weight: 400;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: var(--size-huge);
                z-index: 1;
            }

            .subtitle {
                font-size: var(--font-size-nano);
                color: var(--text-color-subtle);
                font-weight: 500;
            }

            &:hover {
                color: var(--button-color-background-primary-green-hover);
            }

            &:active,
            &:focus {
                color: var(--button-color-text-secondary-default);
            }

            &.with-delimiter {
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    height: var(--size-small);
                    border-left: 1px solid var(--component-color-border-default);
                    margin-right: -4px;
                }
            }

            &.active {
                background: linear-gradient(0deg, var(--system-color-success-light) 0%, var(--system-color-success-light) 100%), var(--white);

                .option-text {
                    background: linear-gradient(0deg, var(--system-color-success-light) 0%, var(--system-color-success-light) 100%), var(--white);
                }

                &::after {
                    position: absolute;
                    left: inherit;
                    content: '';
                    height: 103%;
                    border: 2px solid var(--component-color-border-active);
                    width: 101%;
                    border-radius: var(--size-pico);
                }
            }
        }
    }

    &.invalid {
        .buttons {
            border-color: var(--system-color-error-default);
        }
    }
}
</style>
