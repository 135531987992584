import { useNumbers } from '@/Composables/Numbers';
import { AxiosResponse } from 'axios';
import { useDefine } from '@/Composables/Define';
import PayByLinkService from '@/Apps/PayByLink/Pay/Services/PayByLinkService';
import { MtplOffer } from '@/interfaces/resources/Offers/MtplOfferInterface';
import AdditionalOptionsMap from '@/Apps/PayByLink/Classes/AdditionalOptionsMap';

export const usePayByLink = (): PayByLinkParams => {
    const Fluctuation: number = 0.01;

    const duePayment = (premiums: number[], paymentCountOrIc: string): number => {
        const monthsCount: number = Number(paymentCountOrIc.replace(/\D/, ''));
        const totalPremium: number = useNumbers().arraySum(premiums);
        const onePaymentValue: number = fraction(division(totalPremium, monthsCount), 5);
        const onePaymentValueFraction: number = fraction(onePaymentValue);
        const difference: number = subtraction(onePaymentValue, onePaymentValueFraction);
        const totalDifference: number = multiplication(difference, monthsCount, 100);

        return addition(fraction(totalDifference), onePaymentValueFraction, 100);
    }

    const laterPayment = (premiums: number[], paymentCountOrIc: string): number => {
        const monthsCount: number = Number(paymentCountOrIc.replace(/\D/, ''));
        const totalPremium: number = useNumbers().arraySum(premiums);
        const onePaymentValue: number = fraction(division(totalPremium, monthsCount));
        const onePaymentValueFraction: number = fraction(onePaymentValue);
        const difference: number = subtraction(onePaymentValue, onePaymentValueFraction);
        const totalDifference: number = multiplication(difference, monthsCount, 100);

        return subtraction(onePaymentValueFraction, fraction(totalDifference), 100);
    }

    const acceptCalculationResponse = (response: AxiosResponse): boolean => {
        let result: boolean = false;
        const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
        const periodIc: string = payByLinkService.payByLink.value.periodIc;
        const pblTotalPremium: number = payByLinkService.payByLink.value.premium;
        const includedRisks: string[] = payByLinkService.payByLink.value.offerRisks
            .filter((risk => risk.included))
            .map((risk => `${new AdditionalOptionsMap().keyByIc(risk.ic)}Premium`));
        if (useDefine().validResponse(response)) {
            const offer: MtplOffer = response.data.data.body.offer;
            const basePrice: number = offerBasePrice(offer);
            const riskPrices: number[] = includedRisks.map((riskKey => offer.prices[periodIc][riskKey]));
            result = [addition(pblTotalPremium, Fluctuation), pblTotalPremium, subtraction(pblTotalPremium, Fluctuation)]
                .includes(useNumbers().arraySum([basePrice].concat(riskPrices)));
        }

        return result;
    }

    function offerBasePrice(offer: MtplOffer): number {
        const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
        const periodIc: string = payByLinkService.payByLink.value.periodIc;
        const paymentCountIc: string = `M${payByLinkService.payByLink.value.paymentCount}`;
        const hasMultiplePremiums: boolean = typeof offer.prices[periodIc].premium === 'object';

        return hasMultiplePremiums ? offer.prices[periodIc].premium[paymentCountIc] : offer.prices[periodIc].premium;
    }

    /**
     * @param value float value
     * @param decimals decimals count
     * @return value with given decimals without any rounding
     */
    function fraction(value: number, decimals: number = 2): number {
        const expression: RegExp = new RegExp(String.raw`^-?\d+(?:\.\d{0,${decimals}})?`, 'g');
        const result: string[] = value.toString().match(expression) ?? [];

        return Number(result[0]);
    }

    function division(dividend: number, divisor: number, decimalsMultiplier: number = 100000): number {
        return Math.round((dividend / divisor + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    }

    function subtraction(minuend: number, subtrahend: number, decimalsMultiplier: number = 100000): number {
        return Math.round((minuend - subtrahend + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    }

    function addition(augend: number, addend: number, decimalsMultiplier: number = 100000): number {
        return Math.round((augend + addend + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    }

    function multiplication(multiplier: number, multiplicand: number, decimalsMultiplier: number = 100000): number {
        return Math.round((multiplier * multiplicand + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    }

    return {
        duePayment,
        laterPayment,
        acceptCalculationResponse,
    }
}

export interface PayByLinkParams {
    duePayment: (premiums: number[], paymentCountOrIc: string) => number;
    laterPayment: (premiums: number[], paymentCountOrIc: string) => number;
    acceptCalculationResponse: (response: AxiosResponse) => boolean;
}
