<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import {computed, getCurrentInstance, onMounted, reactive, ref, Ref, UnwrapNestedRefs} from 'vue';
import {useTranslate} from '@/Composables/Translate';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import ErrorType from '@/Enums/ErrorTypeEnum';
import PopupService from '@/services/custom.popup.service';
import {AxiosResponse} from 'axios';
import {useAxios} from '@/Composables/Axios';
import OnePopup from '@/assets/libraries/popups/one.popup';
import {useDefine} from '@/Composables/Define';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import {Router, useRouter} from 'vue-router';
import MtplClaimsAjaxCalls from '@/Apps/ClaimsMtpl/Enums/MtplClaimsAjaxCallsEnum';
import Translations from '@/services/translations.service';
import GuardsService from '@/Apps/ClaimsMtpl/Services/GuardsService';
import MtplClaimsStepUid from '@/Apps/ClaimsMtpl/Enums/MtplClaimsStepUidEnum';
import {useClaimsMtplHtml} from '@/Apps/ClaimsMtpl/Composables/ClaimsMtplHtml';
import EventBus from '@/services/event.bus.service';

const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();
const btaBase: OneBase = OneBaseService.getInstance();

const router: Router = useRouter();
const {translate, translateForType} = useTranslate();
const {isSet, validResponse} = useDefine();
const {scrollToPanel} = useClaimsMtplHtml();

const Step: number = 4;
const IndemnityReceiverId: number = 5;
const Uploader: string = 'claims-mtpl';
const UploaderId: string = 'uploader';

const form: Form = new Form();

const filesUploader: Ref<DynamicDictionary | null> = ref(null);
const renew: Ref<boolean> = ref(false);
const uploadCategories: Ref<string[]> = ref([]);
const necessaryDocumentKeys: Ref<string[]> = ref([]);


const uploadInProgress: Ref<boolean> = computed(() => {
    return filesUploader.value ? !filesUploader.value.uploadsSuccessful : true;
});

const uploaderIsReady: Ref<boolean> = computed(() => {
    return filesUploader.value ? filesUploader.value.componentIsReady : false;
});

const isUploaded: Ref<boolean> = computed(() => {
    return filesUploader.value ?
        !filesUploader.value.filesNotUploaded && filesUploader.value.uploadsSuccessful :
        false;
});

const damageTypes: Ref<string[]> = computed(() => {
    const damageTypes: DynamicDictionary = claimsMtplService.fields.whatWasDamaged!;
    return isSet(damageTypes.selected) ?
        damageTypes.selected.split('@#@') :
        [];
});


function setupForm(): void {
    form.setReady();
}

function onProceed(): void {
    const page: string = claimsMtplService.isIndemnityReceiverPageVisible() ?
        MtplClaimsStepUid.IndemnityReceiver :
        MtplClaimsStepUid.ContactDetails;
    updateStepVisibility();
    storeFormToService();
    GuardsService.getInstance().applyStepValidity(IndemnityReceiverId, true, true);
    router.push({name: page});
}

function updateStepVisibility(): void {
    const steps: DynamicDictionary[] = [
        {
            stepName: MtplClaimsStepUid.IndemnityReceiver,
            isVisible: claimsMtplService.isIndemnityReceiverPageVisible(),
        }
    ]
    EventBus.getInstance().emit('updateStepVisibility', steps);
}

function storeFormToService(): void {
    claimsMtplService.fields.filesUploaded = Boolean(isUploaded.value);
}

function fetchUploadCategories(): void {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    const params: DynamicDictionary = {
        damageTypes: damageTypes.value,
        claimType: claimsMtplService.fields.typeOfClaim!.selected,
    };
    useAxios().get(MtplClaimsAjaxCalls.UploadCategories, {params})
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (validResponse(response)) {
                uploadCategories.value = response.data.data.body.uploadCategories;
                necessaryDocumentKeys.value = response.data.data.body.documentKeys ?? [];
            }
        })
        .catch((reason: LimitedVariant): void => {
            btaBase.error.show(ErrorType.Error, 'uploadCategories', reason as DynamicDictionary);
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
        });
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function proceedButton(): DynamicDictionary {
    return {
        title: isUploaded.value ? localized('proceed') : localized('upload_later'),
        textColor: isUploaded.value ? ButtonTextColor.White : ButtonTextColor.Black,
        backgroundColor: isUploaded.value ? ButtonBackground.Red : ButtonBackground.Transparent,
        backgroundColorHover: isUploaded.value ? ButtonBackground.Red : ButtonBackground.Transparent,
        icon: isUploaded.value ? ButtonIcon.LongArrowRight : '',
        iconPosition: ButtonIconPosition.Right,
    };
}


onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    setupForm();
    fetchUploadCategories();
    GuardsService.getInstance().applyStepValidity(Step, true, false);
});

defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form
            v-if="form.isReady()"
            :form="form"
            @change="storeFormToService()"
            class="form">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <router-link
                    class="back back-margin"
                    :to="{name: claimsMtplService.isExtraDetailsPageVisible() ?
                        MtplClaimsStepUid.ExtraDetails :
                        MtplClaimsStepUid.AccidentDetails}"
                    :tag="'button'"
                    :disabled="!form.isValid()">
                    <img src="images/one/arrow-left.svg" alt="back">
                    <span>{{ translate('back_button') }}</span>
                </router-link>
                <label>{{ localized('upload_files') }}</label>
                <div class="whiteboard" v-bind:data-scroll="UploaderId">
                    <div class="documents-descriptions">
                        {{ localized('description_upload_documents') }}
                        <ul>
                            <li v-for="document in necessaryDocumentKeys" v-html="localized(document)"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="uploader">
                <app-files-uploader ref="filesUploader"
                                    :uploader="Uploader"
                                    :renew="renew"
                                    :translation-type="Translations.getInstance().type"
                                    :upload-categories='uploadCategories'>
                </app-files-uploader>
            </div>
            <div class="input">
                <app-button-with-callback class="button"
                                          data-type="upload-submit"
                                          v-if="uploaderIsReady"
                                          :disabled="uploadInProgress"
                                          v-bind="proceedButton()"
                                          v-on:button-callback-click="onProceed()">
                </app-button-with-callback>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .input:not(:last-child) {
        margin-bottom: 24px;
    }

    .title {
        font-weight: 700;
        font-size: var(--font-size-normal);
    }

    .documents-descriptions {
        list-style-position: inside;
        color: var(--text-color-subtlest);
        font-weight: 600;

        ul {
            list-style: disc inside;
            font-weight: 500;

            li {
                margin-top: var(--size-pico);
                font-size: var(--font-size-nano);
            }
        }
    }

    .button {
        margin-top: 32px;
    }

    .uploader {
        margin-top: var(--size-small);

        :deep(.files-uploader) {
            .category {
                padding: var(--size-small);

                @include respond-above('sm') {
                    padding: var(--size-big);
                }

                .category-header {
                    font-weight: 600;
                }

                .files-uploader-area {
                    .upload-container {
                        width: 100%;

                        @include respond-above('sm') {
                            width: 75%;
                        }
                    }

                    .qr {
                        width: 0;

                        @include respond-above('sm') {
                            width: 25%;
                        }
                    }
                }
            }
        }
    }

    .input {
        width: 100%;
        margin-bottom: var(--size-big);

        .button-with-callback {
            max-width: fit-content;
            height: 52px;
            margin-top: var(--size-tiny);
            padding: 0 var(--button-horizontal-padding);
        }
    }
}
</style>
