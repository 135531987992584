import { markRaw } from 'vue';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';
import FormField from '@/assets/libraries/form/form-field';
import MtplOfferOption from '@/Components/AdditionalOptionsList/Interfaces/MtplOfferOptionInterface';

export default class MtplOfferOptionBuilder {
    private option: MtplOfferOption = new class implements MtplOfferOption {
        public name: string = '';
        public risk!: CoverageRisk;
        public component: string = 'AppMtplOfferOption';
        public enabled: FormField<boolean> = markRaw(new FormField('enabled', false));
        public periodIc: string = '';
        public value: string = '';
    }

    public withName(value: string): MtplOfferOptionBuilder {
        this.option.name = value;

        return this;
    }

    public withPeriodIc(value: string): MtplOfferOptionBuilder {
        this.option.periodIc = value;

        return this;
    }

    public withValue(value: string): MtplOfferOptionBuilder {
        this.option.value = value;

        return this;
    }

    public withCoverageRisk(value: CoverageRisk): MtplOfferOptionBuilder {
        this.option.risk = value;

        return this;
    }

    public withState(value: boolean): MtplOfferOptionBuilder {
        this.option.enabled.setValue(value);

        return this;
    }

    public build(): MtplOfferOption {
        return this.option;
    }
}
