<script lang="ts">

import { defineComponent, nextTick, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import { Router, useRouter, RouteRecordRaw } from 'vue-router';
import RouteStepperStep from '@/Components/RouteStepper/RouteStepperStepInterface';
import { Subscription } from 'rxjs';
import Translations from '@/services/translations.service';
import TwoWayGuardsService from '@/Apps/TwoWayCommunication/Services/TwoWayGuardsService';


export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const twoWayGuardsService: TwoWayGuardsService = TwoWayGuardsService.getInstance();

        const router: Router = useRouter();

        const CurrentStep: number = 1;
        const Facility: string = 'communication';
        const TranslationType: string = 'communication';

        const appPreparedAndReady: Ref<boolean> = ref(false);

        const steps: RouteStepperStep[] = (
            router.options.routes || []
        ).map((routeConfig: RouteRecordRaw, index: number): RouteStepperStep => {
            return {
                routeConfig: routeConfig,
                label: routeConfig.meta?.title as string,
                rowIndex: index + 1,
                visible: true,
            }
        });

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                TranslationType,
                steps,
                appPreparedAndReady,
                twoWayGuardsService,
            }
        }
    },
    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        this.setStorageUsage(false);
        Translations.getInstance().addType(this.TranslationType);
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.appPreparedAndReady = true;
                this.twoWayGuardsService.init();
                nextTick(() => {
                    this.sticky.init();
                });
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
