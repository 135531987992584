<script setup lang="ts">
import {computed, onMounted, PropType, Ref, ref} from 'vue';
import {useTranslate} from '@/Composables/Translate';
import {useHtml} from '@/Composables/Html';
import {usePrice} from '@/Composables/Price';
import {CoverageRisk} from '@/Apps/Renters/Interfaces/CoverageRiskInterface';
import {Renters} from '@/Apps/Renters/Interfaces/RentersInterface';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import IconSide from '@/Components/TextWithTip/IconSide.enum';
import CoveredPopupAutomationIterator from '@/Components/CoveredPopup/Classes/CoveredPopupAutomationIterator';
import {useFormatter} from '@/Composables/Formatter';
import RentersRiskGroups from '@/Apps/Renters/Enums/RentersRiskGroups';
import RentersRisks from '@/Apps/Renters/Enums/RentersRisks';
import AppButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import AppTextWithTip from '@/Components/TextWithTip/TextWithTip.vue';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';

export interface CoveredRisks {
    id: string,
    risks: (CoverageRisk | null) [],
}

export interface RiskMapping {
    risks: Ref<CoveredRisks[]>;
    group: RentersRiskGroups;
}

const props = defineProps({
    title: {type: String, default: ''},
    policies: {type: Array as PropType<Renters[]>, default: () => []},
    excludedRisks: {type: Array, default: () => []},
    coveragePlanKey: {type: Number, default: 0},
    translationType: {type: String, default: ''},
});

const emit = defineEmits(['selected', 'blur', 'close']);
const viewIsReady: Ref<boolean> = ref(false);

const {translate, translateForType} = useTranslate();
const {sparse} = usePrice();
const {sparsePrice} = useFormatter();

const propertyRisks: Ref<CoveredRisks[]> = ref([]);
const liabilityRisks: Ref<CoveredRisks[]> = ref([]);
const territory: Ref<CoveredRisks[]> = ref([]);
const additionalRisks: Ref<CoveredRisks[]> = ref([]);
const riskMappings: RiskMapping[] = [
    {risks: propertyRisks, group: RentersRiskGroups.Property},
    {risks: liabilityRisks, group: RentersRiskGroups.Liability},
    {risks: territory, group: RentersRiskGroups.Territory},
    {risks: additionalRisks, group: RentersRiskGroups.Additional},
];


const buttonParams: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        backgroundColor: ButtonBackground.Red,
        textColor: ButtonTextColor.White,
        iconColor: ButtonIconColor.White,
    };
});

onMounted((): void => {
    init();
    CoveredPopupAutomationIterator.init();
});

function init(): void {
    riskMappings.forEach(({risks, group}): void => {
        setupRisks(risks, group);
    });
    viewIsReady.value = true;
}

function setupRisks(risks: Ref<CoveredRisks[]>, group: RentersRiskGroups): void {
    props.policies.forEach((policy: Renters): void => {
        risks.value.push({
            id: policy.id,
            risks: policy.risks.filter(
                (risk: CoverageRisk) => risk.riskGroup === group && risk.isWhatCovered,
            ),
        });
    });
}

function risksStatusInfo(plan: CoveredRisks, riskId: null | string): string {
    let result: string = '';
    const risk = plan.risks.find((risk) => risk?.id === riskId);
    if (risk) {
        result = risk.riskGroup === RentersRiskGroups.Additional ? monthlyPrice(risk.price) : statusSumOrIcon(risk);
    }

    return result;
}

function monthlyPrice(fullPrice: number): string {
    return '+&nbsp;' + sparse(fullPrice, false) + '&nbsp;' + translate('btar_policy_price_text');
}

function statusSumOrIcon(risk: CoverageRisk): string {
    return risk.withoutInsuredSum ? selectIcon(risk) : risk.insuredSum + '&nbsp;&euro;';
}

function selectIcon(risk: CoverageRisk): string {
    return risk.isIncluded ? `${iconContent(checkmarkIconAttributes())}` : `${iconContent(crossIconAttributes())}`;
}

function checkmarkIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon checkmark',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    };
}

function crossIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon',
        src: 'images/one/cross-icon.svg',
        width: 14,
        height: 14,
    };
}

function iconContent(iconAttributes: ImageTagAttributes): string {
    return useHtml()
        .imgTag(iconAttributes);
}

function onSelectedClick(product: string): void {
    emit('selected', [product]);
}

</script>

<template>
    <div class="covered-popup-multiple" v-if="viewIsReady">
        <div class="coverage-title">{{ translateForType(title, translationType) }}</div>
        <div class="insurance-plans">
            <div class="plan" v-for="(plan) in props.policies">
                <p class="plan-title">{{ translateForType(plan.id.toLowerCase(), translationType) }}</p>
                <p class="price">
                    {{
                        translateForType('covered_price_from', 'components', {
                            '%PRICE%': sparse(plan.defaultPrice, false),
                        })
                    }}</p>
                <app-button-with-callback
                    :title="translateForType('covered_select', 'components')"
                    v-bind="buttonParams"
                    v-on:button-callback-click="onSelectedClick(plan.id)">
                </app-button-with-callback>
            </div>
        </div>
        <div class="covered-information">
            <div class="title-main">{{ translateForType('coverage', translationType) }}</div>
            <div class="description">{{ translateForType('coverage_description', translationType) }}</div>
            <div class="risks">
                <div class="title additional">
                    {{ translateForType('property_risks', translationType) }}
                </div>
                <div class="standard-risk">
                    <div class="sub-title">
                        {{ translateForType('insurance_limits', translationType) }}
                    </div>
                    <div class="status"
                         :id="'covered-block-all-risks'">
                        <div class="status" :id="'covered-block-all-risks'">
                            <div class="item" v-for="(policy) in props.policies">
                                <div v-for="plan in policy.coveragePlans" :key="plan.ic"
                                     v-html="sparsePrice(plan.insuredSum, true) + ' &euro;'"></div>
                            </div>
                        </div>
                    </div>
                    <div class="standard-risk" v-for="(risk) in propertyRisks[0].risks">
                        <app-text-with-tip class="sub-title"
                                           :info-icon-side="IconSide.Before"
                                           :title="translateForType(risk!.id + '_DETAILED', translationType)"
                                           :tip-description="translateForType(risk!.id + '_DETAILED_TOOLTIP', translationType)">
                        </app-text-with-tip>
                        <div class="status"
                             :id="'covered-block-multiple-' + CoveredPopupAutomationIterator.blockIndexIterate()">
                            <div class="item icon"
                                 v-for="(policy) in propertyRisks"
                                 :id="'covered-block-multiple-item-' + CoveredPopupAutomationIterator.blockIndex() + '-' + policy">
                                <div v-html="risksStatusInfo(policy, risk!.id)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title additional">
                    {{ translateForType('liability_risks', translationType) }}
                </div>
                <div class="standard-risk">
                    <div class="standard-risk" v-for="(risk) in liabilityRisks[0].risks">
                        <p class="sub-title" v-if="risk!.id === RentersRisks.LiabilitySum">
                            {{ translateForType(risk!.id + '_DETAILED', translationType) }}</p>
                        <app-text-with-tip class="sub-title"
                                           v-if="risk!.id !== RentersRisks.LiabilitySum"
                                           :info-icon-side="IconSide.Before"
                                           :title="translateForType(risk!.id + '_DETAILED', translationType)"
                                           :tip-description="translateForType(risk!.id + '_DETAILED_TOOLTIP', translationType)">
                        </app-text-with-tip>
                        <div class="status"
                             :id="'covered-block-multiple-' + CoveredPopupAutomationIterator.blockIndexIterate()">
                            <div class="item icon"
                                 v-for="(policy) in liabilityRisks"
                                 :id="'covered-block-multiple-item-' + CoveredPopupAutomationIterator.blockIndex() + '-' + policy">
                                <div v-html="risksStatusInfo(policy, risk!.id)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title additional">
                    {{ translateForType('territory', translationType) }}
                </div>
                <div class="standard-risk">
                    <div class="standard-risk" v-for="(risk) in territory[0].risks">
                        <app-text-with-tip class="sub-title"
                                           :info-icon-side="IconSide.Before"
                                           :title="translateForType(risk!.id + '_DETAILED', translationType)"
                                           :tip-description="translateForType(risk!.id + '_DETAILED_TOOLTIP', translationType)">
                        </app-text-with-tip>
                        <div class="status"
                             :id="'covered-block-multiple-' + CoveredPopupAutomationIterator.blockIndexIterate()">
                            <div class="item icon"
                                 v-for="(policy) in territory"
                                 :id="'covered-block-multiple-item-' + CoveredPopupAutomationIterator.blockIndex() + '-' + policy">
                                <div v-html="risksStatusInfo(policy, risk!.id)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="options">
                    <span class="options-title">{{ translateForType('additional_options', translationType) }}</span>
                    <br>
                    <span class="options-description">{{ translateForType('options_description', translationType) }}</span>
                </div>
                <div class="standard-risk">
                    <div class="standard-risk" v-for="(risk) in additionalRisks[0].risks">
                        <app-text-with-tip class="sub-title"
                                           :info-icon-side="IconSide.Before"
                                           :title="translateForType(risk!.id + '_DETAILED', translationType)"
                                           :tip-description="translateForType(risk!.id + '_DETAILED_TOOLTIP', translationType)">
                        </app-text-with-tip>
                        <div class="status"
                             :id="'covered-block-multiple-' + CoveredPopupAutomationIterator.blockIndexIterate()">
                            <div class="item icon"
                                 v-for="(policy) in additionalRisks"
                                 :id="'covered-block-multiple-item-' + CoveredPopupAutomationIterator.blockIndex() + '-' + policy">
                                <div v-html="risksStatusInfo(policy, risk!.id)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.covered-popup-multiple {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title-main {
        margin: var(--size-small) 0 0 !important;
        padding-bottom: var(--size-small);
        padding-top: var(--size-small);
        font-size: var(--font-size-medium);
        font-weight: 700;
    }

    .insurance-plans {
        width: 100%;
        min-height: 235px;
        max-height: 235px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        align-items: flex-end;
        background: var(--background-light);
        padding: var(--size-small);
        gap: var(--size-small);
        border-top-left-radius: var(--size-tiny);
        border-top-right-radius: var(--size-tiny);

        @include respond-above('sm') {
            padding: var(--size-big);
            min-height: 277px;
            gap: var(--size-big);
        }

        .plan {
            max-height: 117px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-self: center;
            justify-content: flex-end;
            align-items: center;

            @include respond-above('sm') {
                margin-bottom: 0;
                justify-content: center;
            }

            .plan-title {
                text-align: center;
                font-size: var(--font-size-small);
                font-weight: 700;
                color: var(--text-color-default);
            }

            .price {
                font-weight: 500;
                text-align: center;
                color: var(--text-color-subtle);
            }

            button {
                width: 100%;
                height: 100%;
                margin-top: var(--size-small);
                min-height: 52px;
            }
        }
    }

    .no-plans {
        min-height: 120px;
        max-height: 120px;
    }

    .covered-information {
        display: grid;
        padding: 0 var(--size-small) var(--size-small);
        width: 100%;
        overflow-y: auto;

        .description {
            text-align: left;
        }

        @include respond-above('sm') {
            padding: var(--size-small) var(--size-small) 0 var(--size-big);
        }

        .options {
            width: 100%;
            margin: var(--size-small) 0 0 !important;
            background: var(--system-color-success-light, rgba(0, 180, 173, 0.08));
            border-radius: var(--size-femto, 4px);
            padding: 16px 20px;

            @include respond-above('sm') {
                top: 50px;
                left: var(--size-big);
                width: 100%;
            }

            .options-title,
            .options-description {
                display: block;
            }

            .options-title {
                font-size: var(--size-small);
                font-weight: 700;
            }

            .options-description {
                font-weight: 500;
                font-size: var(--font-size-tiny);
                color: var(--text-color-subtle);
            }
        }

        .standard-risk {
            .sub-title {
                margin: var(--size-tiny) 0;

                @include respond-above('sm') {
                    margin: var(--size-small) 0;
                }
            }

            .status {
                border-top: 1px solid var(--black-100);
                border-bottom: 1px solid var(--black-100);
                min-height: 46px;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
                justify-content: center;
                align-items: stretch;

                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid var(--black-100);
                    color: var(--text-color-subtle);
                    padding: var(--size-nano);
                    gap: var(--size-femto);
                }

                .item:last-child {
                    border-right: none;
                }
            }
        }
    }

    .popup-title {
        position: absolute;
        padding-bottom: var(--size-small);
        border-bottom: 1px solid var(--black-100);
    }

    .coverage-title {
        position: absolute;
        max-width: 225px;
        top: 30px;
        left: 25px;
        font-size: var(--font-size-normal);
        font-weight: 700;

        @include respond-above('sm') {
            top: 50px;
            left: var(--size-big);
            max-width: 600px;
        }
    }

    .title {
        margin: var(--size-small) 0 0 !important;
        padding-bottom: var(--size-small);
        padding-top: var(--size-small);
        padding-left: var(--size-small);
        font-size: var(--font-size-normal);
        font-weight: 700;
    }

    .additional {
        border-radius: var(--size-femto, 4px);
        background: var(--system-color-success-light, rgba(0, 180, 173, 0.08));
    }

    .sub-title {
        font-size: var(--font-size-tiny);
        font-weight: 600;
    }
}
</style>
