import {Subject} from 'rxjs';
import 'unorm';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppCountry from '@/assets/libraries/app/app-country';

export default class Countries {
    private static instance: Countries;
    private countries: DynamicDictionary = {};
    private popularCountries: DynamicDictionary = {};
    public ready: boolean = false;
    public onExternalDataIsReady:Subject<void> = new Subject<void>();

    private constructor() {
    }

    public static getInstance(): Countries {
        if (!Countries.instance) {
            Countries.instance = new Countries();
        }

        return Countries.instance;
    }

    public addCountries(
        countries: DynamicDictionary,
        popularCountries: DynamicDictionary,
    ): void {
        this.countries = countries;
        this.popularCountries = popularCountries;
        this.ready = true;
        this.onExternalDataIsReady.next();
    }


    public fetchCountries(): DynamicDictionary {
        return this.countries;
    }

    public fetchPopularCountries(): DynamicDictionary {
        return this.popularCountries;
    }

    public fetchFilteredCountries(): DynamicDictionary {
        return this.filteredCountries(this.countries);
    }

    public fetchFilteredPopularCountries(): DynamicDictionary {
        return this.filteredCountries(this.popularCountries);
    }

    public static normalizedSearchCountry(term: string): string {
        return term.toLowerCase().normalize('NFKD').replace(/[\u0300-\u036F]/g, '');
    }

    private exclusions(): string[] {
        let result: string[] = [];
        if (new AppCountry().isEE()) {
            result = ['BLR', 'RUS'];
        }
        if (new AppCountry().isLV()) {
            result = ['100000000'];
        }

        return result;
    }

    private filteredCountries(source: DynamicDictionary): DynamicDictionary {
        let result: DynamicDictionary = [];
        const exclusions: string[] = this.exclusions();
        Object.keys(source).forEach((key: string) => {
            const countryParts: string[] = String(key).split(':');
            if (!exclusions.includes(countryParts[0])) {
                result[key] = source[key];
            }
        });

        return result;
    }
}
