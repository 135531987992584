<script lang="ts">
import {computed, defineComponent, nextTick, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {useTranslate} from '@/Composables/Translate';
import RecipientValidators from '@/pages/ClaimsAccident/RecipientData/RecipientDataValidators';
import InsuredPerson from '@/interfaces/insured.person.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {Subscription} from 'rxjs';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import ClaimsEnum from '@/Enums/ClaimsEnum';
import FormField from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';
import Translations from '@/services/translations.service';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import {SanitizerCallback} from '@/Types/SanitizerCallbackType';
import Sanitizer from '@/services/sanitizer.service';
import AppCountry from '@/assets/libraries/app/app-country';
import AccidentTypeEnum from '@/Enums/ClaimsAccidentAccidentTypeEnum';
import {useScroll} from '@/Composables/Scroll';
import moment from 'moment';
import ExtractDataService from '@/services/extract.data.service';
import {useDefine} from '@/Composables/Define';
import {AxiosResponse} from 'axios';
import {useAxios} from '@/Composables/Axios';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const stepsSubmitter = useStepsSubmitter();
        const {translateForType} = useTranslate();
        const {isSet} = useDefine();

        const CurrentStep: number = 5;
        const Facility: string = 'one-claims-accident';
        const ThankYouStepFacility: string = 'claims-thank-you';
        const CompensationPanelDataScroll: string = '[data-scroll="possible-compensation"]';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const validators: RecipientValidators = new RecipientValidators();
        const insuredPerson: Ref<InsuredPerson> = ref({} as InsuredPerson);
        const thankYouStepUrl: Ref<string> = ref('');

        const compensationPanelVisible: Ref<boolean> = ref(false);
        const additionalInjuryDescriptionVisible: Ref<boolean> = ref(false);
        const injuryData: Ref<DynamicDictionary> = ref({});
        const beneficiaryOptions: Ref<InputOption[]> = ref([]);
        const includedExpenses: Ref<DynamicDictionary[]> = ref([]);

        const personTypeOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder()
                    .setValue('privateResident')
                    .setName(translation('claims_accident_person_type_resident'))
                    .build(),
                new InputOptionBuilder()
                    .setValue('privateNonResident')
                    .setName(translation('claims_accident_person_type_non_resident'))
                    .build(),
            ];
        });

        const currencyOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder()
                    .setValue(translation('claims_accident_euro'))
                    .setName(translation('claims_accident_euro'))
                    .build(),
                new InputOptionBuilder()
                    .setValue(translation('claims_accident_usd'))
                    .setName(translation('claims_accident_usd'))
                    .build(),
            ];
        });

        const agreeWithPossibleCompensationOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder()
                    .setValue(true)
                    .setName(translation('claims_accident_agree'))
                    .build(),
                new InputOptionBuilder()
                    .setValue(false)
                    .setName(translation('claims_accident_disagree'))
                    .build(),
            ];
        });

        const currentUserIsInsuredPerson: Ref<boolean> = computed(() => {
            return btaBase.user.current.personCode === btaBase.userStorage.stepStorageData.identityNumber;
        });

        const currentUserIsPolicyHolder: Ref<boolean> = computed(() => {
            return btaBase.user.current.personCode === insuredPerson.value.policyHolder;
        });

        const showPrivateOtherFields: Ref<boolean> = computed(() => {
            return !currentUserIsBeneficiary.value && isPrivatePerson.value;
        });

        const showNonResidentFields: Ref<boolean> = computed(() => {
            return otherPersonIsBeneficiary.value && isNonResident.value;
        });

        const showPrivatePersonFields: Ref<boolean> = computed(() => {
            return currentUserIsBeneficiary.value;
        });

        const policyHolderCodeIsAnonymous: Ref<boolean> = computed(() => {
            const holderCodeField: FormField = form.field('beneficiaryIdentityNumber');
            const holderCode: string = String(holderCodeField.value);

            return holderCodeField.isNotEmpty() && Validation.isValidPersonCodeWithoutDate(holderCode);
        });

        const insurantIsOtherAndResident: Ref<boolean> = computed(() => {
            return btaBase.userStorage.stepStorageData.insurant === 'other' &&
                btaBase.userStorage.stepStorageData.isResident &&
                policyHolderCodeIsAnonymous.value;
        });

        const birthDateRequired: Ref<boolean> = computed(() => {
            return isNonResident.value || policyHolderCodeIsAnonymous.value;
        });

        const isPrivatePerson: Ref<boolean> = computed(() => {
            return form.field('personType').value === 'privateResident';
        });

        const isResident: Ref<boolean> = computed(() => {
            return !isNonResident.value;
        });

        const isNonResident: Ref<boolean> = computed(() => {
            return form.field('personType').value === 'privateNonResident';
        });

        const otherPersonIsBeneficiary: Ref<boolean> = computed(() => {
            return !currentUserIsBeneficiary.value;
        });

        const currentUserIsBeneficiary: Ref<boolean> = computed(() => {
            return form.field('beneficiaryType').value === 'userBeneficiary';
        });

        const lockUserFields: Ref<boolean> = computed(() => {
            return currentUserIsBeneficiary.value;
        });

        const isPossibleCompensationVisible: Ref<boolean> = computed(() => {
            return compensationPanelVisible.value;
        });

        const selectedInjuryName: Ref<string> = computed(() => {
            return btaBase.userStorage.stepStorageData.injuredParts[0].name;
        });

        const selectedInjuryPrice: Ref<string> = computed(() => {
            return ((limitForPolicyType() * injuryData.value.percentage) / 100).toFixed(2);
        });

        const expenses: Ref<{ [key: string]: number }> = computed(() => {
            const expensesObject = btaBase.userStorage.stepStorageData.expenses.extra || [];
            const numericExpenses: { [key: string]: number } = {};

            Object.keys(expensesObject).forEach((key: string): void => {
                const value: number = parseFloat(expensesObject[key]);
                if (!isNaN(value)) {
                    numericExpenses[key] = value;
                }
            });

            return numericExpenses;
        });

        const totalCompensation: Ref<string> = computed(() => {
            let sum: number = 0;
            Object.keys(expenses.value).forEach((key: string): void => {
                sum += expenses.value[key];
            });

            return (sum + parseFloat(selectedInjuryPrice.value)).toFixed(2);
        });

        const compensationInfoText: Ref<string> = computed(() => {
            return translateForType('info_text_compensation', Translations.getInstance().type,
                {
                    '%insuranceterms%': btaBase.userStorage.stepStorageData.policyObject.custom.insuranceTerms,
                    '%table%': injuryData.value.table,
                    '%point%': injuryData.value.point,
                    '%percent%': injuryData.value.percentage,
                    '%limit%': limitForPolicyType(),
                    '%subriskText%': translation(injuryData.value.subrisk_key)
                });
        });

        const isInjuryDescriptionVisible: Ref<boolean> = computed(() => {
            return additionalInjuryDescriptionVisible.value;
        });

        const disagreeWithCompensation: Ref<boolean> = computed(() => {
            return form.field('possibleCompensation').value.selected === 'false';
        });

        const riskDocumentHref: Ref<string> = computed(() => {
            const table: string = injuryData.value.table;
            let path: string = '';

            switch (btaBase.portalLocaleIso()) {
                case 'LV':
                    path = documentHrefLv(table);
                    break;
                case 'EE':
                    path = documentHrefEe()
                    break;
                case 'LT':
                    path = documentHrefLt()
                    break;
                default:
            }

            return path;
        });

        const getBeneficiaryOptions: Ref<InputOption[]> = computed(() => {
            return beneficiaryOptions.value;
        });

        const isViableForAutomaticSettlement: Ref<boolean> = computed(() => {
            const baseConditions: boolean = isAccidentTypeInjury.value &&
                hasOnlyOneInjurySelected() && hasEmptyOrAllowedExpenses() && hasUploadedFiles() &&
                isInjuredPersonAuthenticatedPerson.value && currentUserIsBeneficiary.value;
            let additionalConditions: boolean = true;
            if (isAccidentTypeInjury.value) {
                switch (btaBase.userStorage.stepStorageData.injuryType.selected) {
                    case 'sport':
                        additionalConditions = isValidSportInjuryForAutomaticSettlement.value;
                        break;
                    default:
                        additionalConditions = true;
                }
            }

            return baseConditions && additionalConditions;
        });

        const identityNumberSanitizer: Ref<Function | null> = computed(() => {
            const isInjuredPersonBeneficiaryResident: boolean = !isInjuredPersonAuthenticatedPerson.value &&
                !isNonResident.value && otherPersonIsBeneficiary.value;
            let result: Function | null = isInjuredPersonBeneficiaryResident ? Sanitizer.cleanPersonCode : null;
            const useCountry: boolean = isNonResident.value
                && otherPersonIsBeneficiary.value
                && form.field('beneficiaryCountry').value.iso !== (new AppCountry()).iso();
            const country: string = form.field('beneficiaryCountry').value.iso ?? '';
            if (useCountry) {
                switch (country) {
                    case 'EE':
                        result = Sanitizer.clearPersonCodeEE;
                        break;
                    case 'LT':
                        result = Sanitizer.clearPersonCodeLT;
                        break;
                    case 'LV':
                        result = Sanitizer.cleanPersonCodeLV;
                        break;
                    default:
                }
            }

            return result;
        });

        const isAccidentTypeDeath: Ref<boolean> = computed(() => {
            return btaBase.userStorage.stepStorageData.type === AccidentTypeEnum.Death;
        });

        const isInjuredPersonAuthenticatedPerson = computed(() => {
            return btaBase.userStorage.stepStorageData.insurant !== 'other';
        })

        const isAccidentTypeInjury = computed(() => {
            return btaBase.userStorage.stepStorageData.type === 'INJURIES';
        })

        const isValidSportInjuryForAutomaticSettlement: Ref<boolean> = computed(() => {
            const policyObjectAttributes: DynamicDictionary =
                btaBase.userStorage.stepStorageData.policyObject.custom.objectAttribute;

            return btaBase.userStorage.stepStorageData.injuryType.selected === 'sport' &&
                isSet(policyObjectAttributes) && btaBase.settings.claimsSettings().accident.objectAttributeTypeIcs
                    .includes(policyObjectAttributes.atttypeic) && policyObjectAttributes.value === 'Y';
        });

        function onSubmitStep(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            form.validate().then((): void => {
                if (form.isValid()) {
                    if (isViableForAutomaticSettlement.value && !isPossibleCompensationVisible.value) {
                        PopupService.getInstance().show(new OnePopup().withType().loading);
                        fetchInjuryData().then((): void => {
                            PopupService.getInstance().hide();
                            showPossibleCompensationPanel();
                            scrollToCompensationPanel();
                        });
                    } else {
                        prepareSubmit();
                        const thankYouStepStorageNumber: number = 6;
                        stepsSubmitter.saveParamsToStorageWithStep(thankYouStepStorageNumber, ThankYouStepFacility)
                            .then((): void => {
                                useThankYouStepUrlToProceed();
                                stepsSubmitter.submitMethod(Method.Post);
                                stepsSubmitter.submitStep(Url.AccidentClaims.submit);
                            });
                    }
                }
            });
        }

        function applyIncludedExpenses(value: string): void {
            includedExpenses.value = JSON.parse(value);
        }

        function applyInsuredPerson(value: string): void {
            insuredPerson.value = JSON.parse(value);
        }

        function applyThankYouStepUrl(value: string): void {
            thankYouStepUrl.value = value;
        }

        function useThankYouStepUrlToProceed(): void {
            stepsSubmitter.applyStepUrls(thankYouStepUrl.value, stepsSubmitter.previousStep());
        }

        function onBeneficiaryTypeChange(): void {
            nextTick(() => {
                form.field('personType').patch(personTypeOptions.value[0].value);
                clearInputs().then((): void => {
                    resetForm();
                    addCustomSanitizers();
                    if (isAccidentTypeDeath.value) {
                        patchPrivateType();
                    }
                    if (currentUserIsBeneficiary.value) {
                        patchBeneficiaryFields();
                    } else {
                        resetPhoneCountry();
                    }
                });
                compensationPanelVisible.value = false;
            });
        }

        function onPersonTypeChange(): void {
            clearInputs();
            resetForm();
            compensationPanelVisible.value = false;
            addCustomSanitizers();
        }

        function fixedPointSum(sum: string): string {
            return parseFloat(sum).toFixed(2);
        }

        function onCountryChange(): void {
            form.field('beneficiaryIdentityNumber')
                .addSanitizer(identityNumberSanitizer.value as SanitizerCallback);
        }

        function scrollToCompensationPanel(): void {
            useScroll().scrollToView(CompensationPanelDataScroll, 'center');
        }

        function clearInputs(): Promise<void> {
            return new Promise(resolve => {
                [
                    'beneficiaryFirstName',
                    'beneficiaryLastName',
                    'beneficiaryIdentityNumber',
                    'beneficiaryEmail',
                    'beneficiaryPhone',
                    'beneficiaryAccountNumber',
                    'beneficiaryCountry',
                ].forEach((fieldName: string): void => {
                    if (!form.field(fieldName).isRestored) {
                        form.field(fieldName).clear().then();
                    }
                });
                resolve();
            });
        }

        function showPossibleCompensationPanel(): void {
            compensationPanelVisible.value = true;
        }

        function showAdditionalInjuryInformation(): void {
            additionalInjuryDescriptionVisible.value = !additionalInjuryDescriptionVisible.value;
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
                field.markAsUntouched();
            })
        }

        function touchAndRequireNecessaryFields(): void {
            const requiredFieldsValue: string[] = requiredFields();
            requiredFieldsValue.forEach((field: string): void => {
                form.field(field).addValidators('required');
                form.field(field).touch();
            });
        }

        function requiredFields(): string[] {
            const fields: string[] = [
                btaBase.cmsFields.beneficiaryEmail.name,
                btaBase.cmsFields.beneficiaryPhone.name,
                btaBase.cmsFields.beneficiaryAccountNumber.name,
                btaBase.cmsFields.beneficiaryCurrency.name,
                btaBase.cmsFields.agreeTerms.name,
            ];
            if (!currentUserIsBeneficiary.value) {
                fields.push(
                    btaBase.cmsFields.authorizedReceiveCompensation.name
                );
            }
            if (showPrivateOtherFields.value) {
                fields.push(
                    btaBase.cmsFields.beneficiaryFirstName.name,
                    btaBase.cmsFields.beneficiaryLastName.name,
                    btaBase.cmsFields.beneficiaryIdentityNumber.name,
                    btaBase.cmsFields.beneficiaryIdentityNumber.name
                );
                if (birthDateRequired.value) {
                    fields.push(
                        btaBase.cmsFields.beneficiaryBirthDate.name
                    );
                }
            }
            if (showNonResidentFields.value) {
                fields.push(
                    btaBase.cmsFields.beneficiaryFirstName.name,
                    btaBase.cmsFields.beneficiaryLastName.name,
                    btaBase.cmsFields.beneficiaryIdentityNumber.name,
                    btaBase.cmsFields.beneficiaryBirthDate.name,
                    btaBase.cmsFields.beneficiaryCountry.name,
                    btaBase.cmsFields.authorizedReceiveCompensation.name
                );
            }
            if (isViableForAutomaticSettlement.value && isPossibleCompensationVisible.value) {
                fields.push(btaBase.cmsFields.possibleCompensation.name);
            }

            return fields.filter((field) => {
                return btaBase.cmsFields[field]?.enabled ?? true;
            });
        }

        function setupForm(): void {
            Object.keys(btaBase.cmsFields).forEach((field: string): void => {
                form.addField(new FormField(btaBase.cmsFields[field].name, ''));
            })
            validators.init(
                form,
                isNonResident,
                isInjuredPersonAuthenticatedPerson,
                otherPersonIsBeneficiary,
                currentUserIsBeneficiary
            );
            form.setReady();
        }

        function onBeforeFormRestore(): void {
            patchDefaultCurrency();
        }

        function onAppReady(): void {
            btaBase.dynamicStepper.enableAll();
            buildBeneficiaryOptions();
            patchDefaultBeneficiaryTypes();
            if (currentUserIsBeneficiary.value && !isAccidentTypeDeath.value) {
                patchBeneficiaryFields();
            }
            applyFormValidationAndSanitization();
            form.markAsFresh().then();
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous);
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep())
            stepsSubmitter.addSubmitCustomParam('facility', btaBase.facility())
            stepsSubmitter.addSubmitParams(btaBase.userStorage.stepStorageData, true)
            stepsSubmitter.addSubmitParam('claimType', 'accident', true)
            stepsSubmitter.addSubmitParam('uploader', 'claims-accident', true)
            stepsSubmitter.addSubmitParam('applicant', applicant(), true)
            stepsSubmitter.addSubmitParam('beneficiary', beneficiary(), true)
            stepsSubmitter.addSubmitParam('sufferer',
                isInjuredPersonAuthenticatedPerson.value ? applicant() : sufferer(), true)
            stepsSubmitter.addSubmitParam('policyNumber', policyNumber(), true)
            stepsSubmitter.addSubmitParam('filesUploaded', hasUploadedFiles(), true)
            stepsSubmitter.addSubmitParam('caseType', caseType(), true)
            stepsSubmitter.addSubmitParam('caseDescription', caseDescription(), true)
            stepsSubmitter.addSubmitParam('beneficiaryType', form.field('beneficiaryType').value, true)
            if (isViableForAutomaticSettlement.value) {
                stepsSubmitter.addSubmitParam('totalCompensation', totalCompensation.value, true)
                stepsSubmitter.addSubmitParam('agreeWithCompensation', agreeWithCompensation(), true);
            }
        }

        function buildBeneficiaryOptions(): void {
            beneficiaryOptions.value.push(
                new InputOptionBuilder()
                    .setValue('userBeneficiary')
                    .setName(translation('claims_accident_beneficiary_type_me'))
                    .setHidden(isAccidentTypeDeath.value)
                    .build(),
                new InputOptionBuilder()
                    .setValue('otherBeneficiary')
                    .setName(translation('claims_accident_beneficiary_type_other'))
                    .build()
            )
        }

        function patchDefaultBeneficiaryTypes(): void {
            form.field('beneficiaryType').setValue('userBeneficiary');
        }

        function resetPhoneCountry(): void {
            form.field('beneficiaryPhone').patch({
                code: new AppCountry().iso(),
                iso: new AppCountry().iso(),
                country: btaBase.settings.phoneCode(),
                phone: '',
            });
            form.field('beneficiaryPhone').markAsUntouched();
        }

        function patchBeneficiaryFields(): void {
            if (isInjuredPersonAuthenticatedPerson.value) {
                form.field('beneficiaryFirstName').patch(btaBase.user.current.firstname);
                form.field('beneficiaryLastName').patch(btaBase.user.current.lastname);
                form.field('beneficiaryIdentityNumber').patch(btaBase.user.current.personCode);
                form.field('beneficiaryEmail').patch(btaBase.user.current.email);
                form.field('beneficiaryAccountNumber').patch(btaBase.user.current.bank);
                form.field('beneficiaryPhone').patch({
                    code: btaBase.user.current.code,
                    iso: btaBase.user.current.code,
                    country: btaBase.user.current.phoneCode,
                    phone: btaBase.user.current.phone,
                });
            } else {
                form.field('beneficiaryFirstName').patch(btaBase.userStorage.stepStorageData.name);
                form.field('beneficiaryLastName').patch(btaBase.userStorage.stepStorageData.surname);
                form.field('beneficiaryIdentityNumber').patch(btaBase.userStorage.stepStorageData.identityNumber);
            }
            form.field('beneficiaryCurrency').patch(translation('claims_accident_euro'));
        }

        function patchDefaultCurrency(): void {
            form.field('beneficiaryCurrency').patch(translation('claims_accident_euro'));
        }

        function applyFormValidationAndSanitization(): void {
            addCustomSanitizers();
            addCustomValidators();
        }

        function addCustomSanitizers(): void {
            form.field('beneficiaryIdentityNumber').addSanitizer(identityNumberSanitizer.value as SanitizerCallback);
            form.field('beneficiaryFirstName').addSanitizer(Sanitizer.cleanName);
            form.field('beneficiaryLastName').addSanitizer(Sanitizer.cleanName);
        }

        function addCustomValidators(): void {
            form.field('beneficiaryBirthDate').addValidators(validators.birthDateValidator());
            form.field('beneficiaryEmail').addValidators(validators.emailValidator());
            form.field('beneficiaryFirstName').addValidators(validators.firstNameValidator());
            form.field('beneficiaryLastName').addValidators(validators.lastNameValidator());
            form.field('beneficiaryIdentityNumber').addValidators(validators.identityNumberValidator());
            form.field('beneficiaryPhone').addValidators(validators.phoneValidator());
            form.field('beneficiaryAccountNumber').addValidators(validators.bankAccountValidator());
            form.field('possibleCompensation').addValidators(validators.possibleCompensationValidator());
            form.field('beneficiaryCountry').addValidators(validators.countryValidator());
        }

        function patchPrivateType(): void {
            form.field('beneficiaryType').setValue('otherBeneficiary');
            form.field('personType').setValue('privateResident');
        }

        function applicant(): InsuredPerson {
            return {
                identityNumber: btaBase.user.current.personCode,
                firstName: btaBase.user.current.firstname,
                lastName: btaBase.user.current.lastname,
                companyName: '',
                companyType: '',
                registrationNumber: '',
                isLegal: false,
                isResident: true,
                residenceCountryIso: new AppCountry().iso(),
                birthDate: btaBase.user.current.birthDate,
                email: btaBase.user.current.email,
                phoneCode: btaBase.user.current.phoneCode,
                phone: btaBase.user.current.phone,
                bankAccount: btaBase.user.current.bank,
                currency: btaBase.user.current.bankCurrency,
            }
        }

        function beneficiary(): InsuredPerson {
            const result: InsuredPerson = {
                identityNumber: form.field('beneficiaryIdentityNumber').value,
                firstName: form.field('beneficiaryFirstName').value,
                lastName: form.field('beneficiaryLastName').value,
                companyName: form.field('beneficiaryCompanyName').value,
                companyType: '',
                registrationNumber: form.field('beneficiaryRegistrationNumber').value,
                isLegal: false,
                isResident: isResidentBeneficiary(),
                residenceCountryIso: residenceCountryIso(),
                email: form.field('beneficiaryEmail').value,
                phoneCode: form.field('beneficiaryPhone').value.country,
                phone: form.field('beneficiaryPhone').value.phone,
                bankAccount: form.field('beneficiaryAccountNumber').value,
                currency: form.field('beneficiaryCurrency').value,
            };
            if (!insurantIsOtherAndResident.value) {
                result.birthDate = beneficiaryBirthDate();
            }

            return result;
        }

        function sufferer(): InsuredPerson {
            const isBirthDateEmpty: boolean = btaBase.userStorage.stepStorageData.birthDate === '';
            const result: InsuredPerson = {
                identityNumber: btaBase.userStorage.stepStorageData.identityNumber,
                firstName: btaBase.userStorage.stepStorageData.name,
                lastName: btaBase.userStorage.stepStorageData.surname,
                companyName: '',
                companyType: '',
                registrationNumber: '',
                isLegal: false,
                isResident: isBirthDateEmpty,
                residenceCountryIso: '',
                email: '',
                phoneCode: '',
                phone: '',
                bankAccount: '',
                currency: '',
            };
            if (!insurantIsOtherAndResident.value) {
                result.birthDate = isBirthDateEmpty ?
                    birthDateFromIdentityNumber(btaBase.userStorage.stepStorageData.identityNumber) :
                    btaBase.userStorage.stepStorageData.birthDate;
            }

            return result;
        }

        function residenceCountryIso(): string {
            return isResident.value
                ? (new AppCountry()).iso()
                : form.field('beneficiaryCountry').value.iso;
        }

        function beneficiaryBirthDate(): string {
            let birthDate: string;
            if (isInjuredPersonAuthenticatedPerson.value) {
                birthDate = btaBase.user.current.birthDate;
            } else {
                if (!btaBase.userStorage.stepStorageData.isResident) {
                    birthDate = btaBase.userStorage.stepStorageData.birthDate;
                } else {
                    birthDate = isResident.value && !birthDateRequired.value
                        ? moment((new ExtractDataService()).birthDateFromPersonCode(
                            form.field('beneficiaryIdentityNumber').value
                        )).format()
                        : moment(form.field('beneficiaryBirthDate').value).format();
                }
            }

            return birthDate;
        }


        function policyNumber(): string {
            return btaBase.userStorage.stepStorageData.policyObject.custom.policy;
        }

        function caseDescription(): string {
            return btaBase.userStorage.stepStorageData.caseDescription as string;
        }

        function hasUploadedFiles(): boolean {
            return isSet(btaBase.userStorage.stepStorageData.filesUploaded)
                && btaBase.userStorage.stepStorageData.filesUploaded;
        }

        function caseType(): string {
            return form.field('possibleCompensation').value.selected ? 'FPAYOUT' : 'STANDARD';
        }

        function limitForPolicyType(): number {
            const matchingRisk = btaBase.userStorage.stepStorageData.risksOfSelectedPolicy
                .find((risk: DynamicDictionary): boolean => {
                    return risk.agrrisk === btaBase.userStorage.stepStorageData.injuredParts[0].risk_ic;
                });

            return matchingRisk.limit;
        }

        function fetchInjuryData(): Promise<void> {
            return new Promise(resolve => {
                const params: Record<string, number | string> = {
                    riskIc: btaBase.userStorage.stepStorageData.injuredParts[0].risk_ic,
                    subriskIc: btaBase.userStorage.stepStorageData.injuredParts[0].subrisk,
                    termsIc: btaBase.userStorage.stepStorageData.policyObject.custom.insuranceTerms,
                    injuryKey: btaBase.userStorage.stepStorageData.injuredParts[0].name,
                };
                useAxios().get(Url.AccidentClaims.selectedInjuryData, {params}).then(
                    (response: AxiosResponse<DynamicDictionary>): void => {
                        injuryData.value = response.data.data.body.injuryData[0];
                        resolve();
                    }
                );
            });
        }

        function hasExpenses(): boolean {
            return btaBase.userStorage.stepStorageData.expenses &&
                btaBase.userStorage.stepStorageData.expenses.selected !== '';
        }

        function hasEmptyOrAllowedExpenses(): boolean {
            return !hasExpenses() || hasAllowedIncludedExpenses();
        }

        function hasAllowedIncludedExpenses(): boolean {
            const insuredObject: DynamicDictionary = btaBase.userStorage.stepStorageData.policyObject.custom;
            const currentExpenses: DynamicDictionary = JSON.parse(JSON.stringify(btaBase.userStorage.stepStorageData.expenses.extra));
            const currentIncludedExpenses: DynamicDictionary[] = includedExpenses.value
                .filter((item: DynamicDictionary): boolean => insuredObject.insuranceTerms === item.terms_ic)
                .filter((item: DynamicDictionary): boolean => insuredObject.risks
                    .filter((risk: DynamicDictionary): boolean => risk.agrrisk === item.risk_ic
                        && risk?.payouttable === item.table_ic).length > 0);
            let hasRestrictedExpenses: boolean = true;
            const hasIncludedExpenses: boolean = currentIncludedExpenses.length > 0;
            if (hasIncludedExpenses) {
                currentIncludedExpenses.forEach((expense: DynamicDictionary): void => {
                    delete currentExpenses[expense.risk_ic];
                });
                hasRestrictedExpenses = Object.keys(currentExpenses).length !== 0;
            }

            return hasIncludedExpenses && !hasRestrictedExpenses;
        }

        function hasOnlyOneInjurySelected(): boolean {
            const injuredParts = btaBase.userStorage.stepStorageData.injuredParts;

            return injuredParts.length === 1 && injuredParts[0].subrisk !== '';
        }

        function agreeWithCompensation(): boolean {
            const selectedValue = form.field('possibleCompensation').value.selected;

            return selectedValue === 'true' || false;
        }

        function birthDateFromIdentityNumber(identityNumber: string): string {
            return moment((new ExtractDataService()).birthDateFromPersonCode(identityNumber)).format();
        }

        function documentHrefLv(table: string): string {
            let path: string = '';
            switch (table) {
                case '1a':
                    path = translateForType('table_type_1a_pdf', 'href');
                    break;
                case '1':
                    path = translateForType('table_type_1_pdf', 'href');
                    break;
                default:
            }

            return path;
        }

        function documentHrefEe(): string {
            return translateForType('table_type_annex2_pdf', 'href');
        }

        function documentHrefLt(): string {
            return translateForType('table_type_t2_pdf', 'href');
        }

        function isResidentBeneficiary(): boolean {
            const storageData: DynamicDictionary = btaBase.userStorage.stepStorageData;

            return !isInjuredPersonAuthenticatedPerson.value && (isSet(storageData.isResident) &&
                storageData.isResident === false) && !otherPersonIsBeneficiary.value ?
                false :
                isResident.value;
        }

        function translation(key: string): string {
            return translateForType(key, ClaimsEnum.TranslationTypeAccident);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                validators,
                insuredPerson,
                thankYouStepUrl,
                personTypeOptions,
                currencyOptions,
                agreeWithPossibleCompensationOptions,
                currentUserIsInsuredPerson,
                currentUserIsPolicyHolder,
                showPrivateOtherFields,
                showNonResidentFields,
                showPrivatePersonFields,
                policyHolderCodeIsAnonymous,
                birthDateRequired,
                isPrivatePerson,
                isResident,
                isNonResident,
                otherPersonIsBeneficiary,
                currentUserIsBeneficiary,
                lockUserFields,
                isPossibleCompensationVisible,
                selectedInjuryName,
                selectedInjuryPrice,
                expenses,
                totalCompensation,
                compensationInfoText,
                isInjuryDescriptionVisible,
                disagreeWithCompensation,
                riskDocumentHref,
                getBeneficiaryOptions,
                isInjuredPersonAuthenticatedPerson,
                setupForm,
                applyStepUrls,
                onSubmitStep,
                onAppReady,
                onBeforeFormRestore,
                prepareSubmit,
                applyInsuredPerson,
                applyThankYouStepUrl,
                onBeneficiaryTypeChange,
                onPersonTypeChange,
                fixedPointSum,
                onCountryChange,
                translation,
                showAdditionalInjuryInformation,
                applyIncludedExpenses,
                includedExpenses,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        Translations.getInstance().addType('claims_accident');

        const onAfterFormRestoredSubscription: Subscription =
            this.userStorage.onBeforeFormStorageDataIsRestored.subscribe((): void => {
                this.onBeforeFormRestore();
                onAfterFormRestoredSubscription.unsubscribe();
            });

        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    }
});
</script>
