<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { ref, computed, onMounted, reactive, Ref, ComputedRef, onUnmounted } from 'vue';
import Translations from '@/services/translations.service';
import OptionValue from '@/interfaces/option.value.interface';
import OptionsListItem from '@/interfaces/options.list.item.interface';
import Popup from '@/services/popup.service';
import Countries from '@/services/countries.service';
import Country from '@/interfaces/country.interface';
import PopupType from '@/Enums/PopupTypeEnum';
import { CountryComponentParams } from '@/Components/InputCountry/CountryComponentParams';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppTooltipster from '@/Components/Tooltipster/Tooltipster.vue';
import AppPopup from '@/Components/Popup/Popup.vue';
import { useInputErrorMessage } from '@/Composables/InputErrorMessage';
import { Subscription } from 'rxjs';

const props = defineProps({
    placeholder: {type: String, default: () => ''},
    formField: {type: FormField<CountryComponentParams>, default: () => new FormField('')},
    label: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    disableErrorText: {type: Boolean, default: false},
    supportTextMessage: {type: String, default: ''},
    feedbackMessage: {type: String, default: ''},
    popupLabel: {type: String, default: ''},
    iconPattern: {type: String, default: '/images/one/flags/%.png'},
    showValue: {type: String, default: 'country'},
    showCustomPopup: {type: Boolean, default: true},
    patchDefaultCountry: {type: Boolean, default: true},
    includeDefaultCountry: {type: Boolean, default: true},
});

const emit = defineEmits(['change', 'input', 'close']);

const subscriptions: Subscription[] = [];

const translations: Translations = Translations.getInstance();
const popup: Popup = Popup.getInstance();

const feedBackMessage: ComputedRef<string> = computed(() => props.feedbackMessage);
const supportTextMessage: ComputedRef<string> = computed(() => props.supportTextMessage);

const inputErrors = computed(() => useInputErrorMessage(props.formField, props.disableErrorText, {
    supportTextMessage: supportTextMessage.value,
    feedbackMessage: feedBackMessage.value
}));

const country: OptionValue = reactive({
    id: ':void:',
    value: '',
});
const isVisibleDropdown: Ref<boolean> = computed(() => {
    return typedValue.value !== '' || inputInFocus.value
});
const countryData: Ref<CountryComponentParams> = computed(() => {
    const parts: string[] = String(country.id).split(':');
    return {
        iso: parts[1] || '',
        phoneCode: parts[2] || '',
        ic: parts[0] || ''
    };
});
const isValueSelected: Ref<boolean> = computed((): boolean => {
    return country.id && country.value === '';
});
const isEmptyValue: Ref<boolean> = computed((): boolean => {
    return props.formField.value.iso === undefined
        && props.formField.value.phoneCode === undefined
        && props.formField.value.ic === undefined;
});
const countryInput: Ref<HTMLElement | null> = ref(null);
let visible: Ref<boolean> = ref(false);
let typedValue: Ref<string> = ref('');
let optionsTitleTipHeader: Ref<string> = ref('');
let optionsTitleTipBody: Ref<string> = ref('');
let options: Ref<OptionsListItem[]> = ref([]);
let index: number = 0;
let inputInFocus: Ref<boolean> = ref(false);
let countries: DynamicDictionary = Countries.getInstance().fetchCountries();

onMounted((): void => {
    subscriptions.push(
        props.formField.onClear.subscribe((): void => {
            country.value = '';
        }),
        props.formField.onPatch.subscribe((): void => {
            applyCountryChange();
        }),
        props.formField.onRestore.subscribe((): void => {
            applyCountryChange();
        }),
    );
    applyCountryChange(false);
    if (props.patchDefaultCountry) {
        applyLocalCountryValue();
    }
});

onUnmounted((): void => {
    subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
});

function applyCountryChange(applyLocalCountry: boolean = true): void {
    if (isEmptyValue.value) {
        props.formField?.setValue({
            ic: '',
            phoneCode: '',
            iso: '',
        });
    }
    if (applyLocalCountry || props.formField.value.iso !== '') {
        applyLocalCountryValue();
    }
}

function phoneFlagIcon(): string {
    return mappedIcon(country.id, props.iconPattern);
}

function showListSearch(): void {
    if (!props.disabled) {
        prepareOptionsList();
    }
}

function isVisibleItem(current: OptionsListItem): boolean {
    let result: boolean = false;
    if (typedValue.value) {
        const normalizedTitle: string = Countries.normalizedSearchCountry(current.title);
        result = normalizedTitle.includes(Countries.normalizedSearchCountry(typedValue.value));
    } else if (inputInFocus.value) {
        result = current.index < options.value.length;
    }

    return result;
}

function onFocus(): void {
    inputInFocus.value = true;
}

function onOptionSelectClick(option: OptionsListItem): void {
    country.id = option.id;
    country.value = option.title;
    applyFormFieldValue();
    close();
}

function closePopup(): void {
    close();
}

function mappedIcon(idValue: string, iconPattern: string): string {
    const valueParts: string[] = String(idValue).split(':');
    const countryIso: string = String(valueParts[1]).toLowerCase();
    const pathParts: string[] = iconPattern.split('%');

    return pathParts[0] + countryIso + pathParts[1];
}

function applyLocalCountryValue(): void {
    const localCountry: Country = getCountryByPartialPattern(countryPattern(), countries);
    country.id = localCountry.code + ':' + localCountry.iso + ':' + localCountry.phoneCode;
    country.value = localCountry.name;
    applyFormFieldValue();
}

function applyFormFieldValue(): void {
    props.formField?.setValue(countryData.value);
    props.formField.touch();
    props.formField.validate();
    emit('change');
}

function countryPattern(): string {
    let result: string = ':' + translations.countryIso + ':';
    if (!isEmptyValue.value) {
        if (props.formField.value.iso) {
            result = ':' + props.formField.value.iso + ':';
        } else if (props.formField.value.phoneCode) {
            result = ':' + parseInt(props.formField.value.phoneCode, 10) + ':';
        } else if (props.formField.value.ic) {
            result = props.formField.value.ic + ':';
        }
    }

    return result;
}

function getCountryByPartialPattern(pattern: string, countries: DynamicDictionary): Country {
    let country: Country = new class implements Country {
        code: string = '';
        iso: string = '';
        name: string = '';
        phoneCode: string = '';
    };
    pattern = String(pattern).toUpperCase();
    for (let o in countries) {
        if (String(o).includes(pattern)) {
            let parts: string[] = String(o).split(':');
            country.code = parts[0];
            country.iso = parts[1];
            country.phoneCode = parts[2];
            country.name = String(countries[o]);
        }
    }

    return country;
}

function prepareOptionsList(): void {
    clear();
    for (let o in countries) {
        let icon: string = mappedIcon(o, props.iconPattern);
        if (props.includeDefaultCountry) {
            addItem(o, String(countries[o]), icon);
        } else {
            if (!isDefaultCountry(o)) {
                addItem(o, String(countries[o]), icon);
            }
        }
    }
    typedValue.value = '';
    visible.value = true;
    if (props.showCustomPopup) {
        popup.showPopup(PopupType.CustomPopup);
    }
}

function clear(): void {
    optionsTitleTipHeader.value = '';
    optionsTitleTipBody.value = '';
    options.value = [];
    index = 0;
}

function addItem(id: string, title: string, icon: string = ''): void {
    const itemIndex: number = index++;
    options.value.push(
        new class implements OptionsListItem {
            id: string = id;
            title: string = title;
            icon: string = icon;
            index: number = itemIndex;
        }
    );
}

function close(): void {
    visible.value = false;
    if (props.showCustomPopup) {
        popup.showPopup(PopupType.None);
    }
    emit('close');
}

function openDropdown() {
    if (!inputInFocus.value) {
        inputInFocus.value = true;
    }
    countryInput.value?.focus();
}

function closeDropdown() {
    if (inputInFocus.value) {
        inputInFocus.value = false;
    }
    clearInput()
}

function clearInput() {
    typedValue.value = "";
}

function isDefaultCountry(country: string): boolean {
    let result: boolean;
    switch (countryIc(country)) {
        case '100000000':
            result = translations.countryIso === 'LV';
            break;
        case 'EST':
            result = translations.countryIso === 'EE';
            break;
        case 'LTU':
            result = translations.countryIso === 'LT';
            break;
        default:
            result = false;
    }

    return result;
}

function countryIc(fullCountryParams: string): string {
    const parts: string[] = fullCountryParams.split(':');

    return parts[0];
}

function onDropDownClickOutside(): void {
    if (inputInFocus.value) {
        inputInFocus.value = false;
    }
}
</script>

<template>
    <div class="input input-country"
         :id="formField.name"
         :class="{
            ...formField.classes(),
            disabled: disabled,
        }"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <div v-if="label" class="label informative">
            <p>{{ label }}</p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper select">
            <div class="current button"
                 :class="{'cursor-pointer': !disabled, 'cursor-arrow': disabled}"
                 :id="formField.name + '-showListSearch'"
                 @click="showListSearch">
                <div class="flag" v-if="showValue !== 'name'">
                    <img v-if="formField.value.phoneCode && !isValueSelected"
                         width="22"
                         height="16"
                         alt=""
                         :src="phoneFlagIcon()"
                    />
                </div>
                <div v-if="showValue === 'phone-code' && !isValueSelected" class="num">+
                    {{ formField.value.phoneCode || country.id.split(':')[2] }}
                </div>
                <div v-if="showValue === 'country' && !isValueSelected" class="text">{{ country.value }}</div>
                <div v-if="showValue === 'name' && !isValueSelected" class="text">{{ country.value + ` (${formField.value.ic})` }}</div>
                <div v-if="isValueSelected" class="text placeholder">{{ placeholder }}</div>
                <span class="icon arrow-icon" v-if="!disabled && showValue !== 'name'">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1L7 7L1 1" stroke="#E30613" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </span>
            </div>
            <div v-if="inputErrors.infoMessageIsVisible()" v-html="inputErrors.infoMessage()" class="feedback"></div>
        </div>
        <div class="popups">
            <app-popup v-if="visible" class="simple list-search" v-on:close="closePopup()">
                <div class="title">
                    {{ popupLabel || label }}
                    <app-tooltipster v-if="optionsTitleTipHeader || optionsTitleTipBody"
                                     :title="optionsTitleTipHeader"
                                     :description="optionsTitleTipBody">
                    </app-tooltipster>
                </div>
                <div class="elements">
                    <div class="field"
                         v-click-outside="onDropDownClickOutside">
                        <div class="added"></div>
                        <div class="search">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="search">
                                        <path id="Vector" d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path id="Vector_2" d="M21 21L16.65 16.65" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </svg>
                            </div>
                            <input
                                :id="formField.name + '-typedValue'"
                                ref="countryInput"
                                v-model="typedValue"
                                @focus="onFocus"
                                class="text"
                                :placeholder="placeholder"/>
                            <span class="dropdown-toggle"
                                  v-show="!isVisibleDropdown"
                                  @click="openDropdown">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="dropdown-toggle">
                                        <path id="Vector" d="M8 10L12 14L16 10" stroke="#292C31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </svg>
                            </span>
                            <span class="dropdown-toggle"
                                  v-show="isVisibleDropdown"
                                  @click="closeDropdown">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="close--clear">
                                        <path id="Vector" d="M8 8L16 16M16 8L8 16" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </svg>
                            </span>
                        </div>
                        <div class="dropdown" :class="{hidden:!isVisibleDropdown}">
                            <button v-for="(item, index) in options"
                                    :key="index"
                                    class="country-item"
                                    :id="formField.name + '-dropdown-' + item.id"
                                    :class="{hidden:!isVisibleItem(item)}"
                                    :data-value="item.title"
                                    :data-id="item.id"
                                    @click="onOptionSelectClick(item)">
                                <img class="icon"
                                     v-if="item.icon"
                                     :src="item.icon"
                                     alt="">
                                <span class="text">{{ item.title }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </app-popup>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-country {
    > .wrapper {
        > .current > .flag {
            margin-right: var(--size-pico);
        }

        .placeholder {
            opacity: .56;
            color: var(--text-color-subtlest);
        }
    }

    &.error {
        .current {
            border-color: var(--system-color-error-default);
        }
    }

    &.disabled {
        .wrapper {
            .button {
                background: unset;
                border-color: transparent;

                .text {
                    color: var(--component-color-text-disabled);
                }
            }
        }
    }

    :deep(.popups .single-popup.list-search > .wrapper) {
        border-radius: 16px;

        .title {
            font-size: 21px;
        }

        .elements .field {
            border-radius: var(--size-pico);
            min-height: unset;
            height: 40px;

            &:focus-within {
                outline: 1px solid var(--brand-blue);
                border: 1px solid var(--brand-blue);
            }

            .search {
                height: 100%;
                padding: 0 var(--size-nano);

                .icon {
                    margin-right: var(--size-nano);

                    svg {
                        display: block;
                    }
                }

                .dropdown-toggle svg {
                    display: block;
                }
            }

            .text {
                padding-left: 0;
                font-size: var(--font-size-nano);
                min-height: unset;
                background-color: var(--component-color-background-base);

                &::placeholder {
                    font-weight: 500;
                    font-size: var(--font-size-nano);
                    line-height: 16.8px;
                }
            }

            .dropdown {
                border: none;
                margin-top: var(--size-femto);
                padding: 12px;
                border-radius: var(--size-pico);

                &::-webkit-scrollbar {
                    display: none;
                }

                .country-item {
                    margin-top: var(--size-femto);
                    padding: 10px 6px;
                    width: auto;
                    height: 40px;
                    border-radius: var(--size-pico);

                    .text {
                        color: #292C31;
                    }

                    &:hover {
                        background-color: #9297A014;

                        .text {
                            background-color: unset;
                        }
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
