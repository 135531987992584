<script setup lang="ts">
import Form from '@/assets/libraries/form/form';
import {computed, getCurrentInstance, onMounted, reactive, ref, Ref, UnwrapNestedRefs} from 'vue';
import {useTranslate} from '@/Composables/Translate';
import {Router, useRouter} from 'vue-router';
import RentersService from '@/Apps/Renters/Services/RentersService';
import {InputOption} from '@/interfaces/InputOptionInterface';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonBorder from '@/Components/ButtonWithCallback/Enums/button.border.enum';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {Renters} from '@/Apps/Renters/Interfaces/RentersInterface';
import FormField from '@/assets/libraries/form/form-field';
import {CoveredPopupBuilder} from '@/Apps/Renters/Builders/CoveredPopupBuilder';
import {usePrice} from '@/Composables/Price';
import PopupService from '@/services/custom.popup.service';
import {CoveragePlan} from '@/Apps/Renters/Interfaces/CoveragePlanInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useDefine} from '@/Composables/Define';
import Value from '@/assets/libraries/form/value';
import RequestService from '@/services/request.service';
import Url from '@/Enums/UrlEnum';
import {AxiosResponse} from 'axios';
import OnePopup from "@/assets/libraries/popups/one.popup";
import StepsGuard from '@/Apps/Renters/Services/StepsGuard';
import {useFormatter} from '@/Composables/Formatter';
import OneBaseService from '@/services/OneBaseService';
import AppContentLoader from '@/Components/ContentLoader/ContentLoader.vue';

const form: Form = new Form();
const {sparse} = usePrice();
const popupService: PopupService = PopupService.getInstance();
let products: Renters[] = [];
const {sparsePrice} = useFormatter();
const {translate, translateForType} = useTranslate();
const TranslationType: string = 'renters';
const router: Router = useRouter();
const rentersService: RentersService = RentersService.getInstance();
const coverageOptions: Ref<InputOption[]> = ref([]);
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const productPrice: Ref<string> = computed(() => {
    let result: number = 0;
    const selectedCoverageId: number = Number(form.field('coverage').value);
    const product: Renters | undefined = selectedProduct();
    if (product) {
        result = product.coveragePlans[selectedCoverageId].price;
    }

    return sparse(result, false);
});

defineExpose({
    coveredPopup,
});

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    rentersService.updateRoute();
    StepsGuard.getInstance(rentersService).init();
    fetchProducts().then((): void => {
        setupForm();
        restoreValues();
        buildCoveredPopup();
        buildCoverageOptions();
    });
});

function setupForm(): void {
    form.addField(new FormField('coverage', '0'));
    form.setReady();
}

function fetchProducts(): Promise<void> {
    return new Promise(resolve => {
        RequestService.getInstance().get({
            uri: Url.Ajax.renters,
        }).then((response: AxiosResponse): void => {
            if (useDefine().validResponse(response)) {
                products = response.data.data.body.products;
                resolve();
            }
        })
    });
}

function onCustomProductClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function onSelectProductClick(): void {
    prepareSubmit();
    router.push({name: 'renters-options'});
    popupService.hide();
}

function prepareSubmit(): void {
    const products: Renters | undefined = selectedProduct()
    if (products) {
        rentersService.fields.coverage = form.field('coverage').value;
    }
}

function onCoverageChange(): void {
    coveredPopup.withCoveragePlanKey(parseInt(form.field('coverage').value));
}

function buildCoverageOptions(): void {
    const program: Renters | undefined = selectedProduct();
    if (program) {
        program.coveragePlans.forEach((plan: CoveragePlan, index: number): void => {
            coverageOptions.value.push(
                new InputOptionBuilder()
                    .setValue(String(index))
                    .setName(sparsePrice(plan.insuredSum, true) + ' &euro;')
                    .build()
            );
        });
        buildCoveredPopup();
    }
}

function buildCoveredPopup(): void {
    const product: Renters[] = [];
    product.push(selectedProduct()!)
    coveredPopup.withCoveredTitle('see_what_covered_single')
        .withTranslationKey(TranslationType)
        .withCoveredType('AppCoveredPopupRentersSingle')
        .withCoveragePlanKey(form.field('coverage').value)
        .withContent(product)
}

function selectedProduct(): Renters | undefined {
    const programIc: string = selectedProductId();
    return products.find(
        (program: Renters): boolean => program.id === programIc
    );
}

function selectedProductId(): string {
    return rentersService.fields.programIc ?? '';
}

function storeValues(): void {
    const existingValues: DynamicDictionary = JSON.parse(JSON.stringify(rentersService.fields));
    Object.assign(rentersService.fields, {
        ...existingValues,
        coverage: String(form.field('coverage').value),
    });
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = rentersService.fields;
    Object.keys(storedValues).forEach((key: string): void => {
        if (useDefine().isSet(storedValues[key]) && new Value(storedValues[key]).isNotEmpty()) {
            form.field(key).setValue(storedValues[key]);
        }
    });
}


function selectButtonParams(): ButtonWithCallbackParams {
    return {
        title: translate('btar_continue'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconColor: ButtonIconColor.White,
        iconPosition: ButtonIconPosition.Right,
    };
}

function coveredButtonParams(): ButtonWithCallbackParams {
    return {
        title: translate('see_what_covered'),
        textColor: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.White,
        backgroundColorHover: ButtonBackground.White,
        icon: ButtonIcon.Covered,
        iconColor: ButtonIconColor.Green,
        borderColor: ButtonBorder.Pale,
    };
}

</script>

<template>
    <div class="step-container">
        <app-custom-form
            v-if="form.isReady()"
            :form="form"
            @change="storeValues()"
            class="form">
            <a class="to-previous-step" @click="router.back()">
                <img src="images/one/arrow-left.svg" alt="back">
                <span>{{ translate('back_button') }}</span>
            </a>
            <section class="header">
                <h2 class="title">{{ translateForType('coverage_title', TranslationType) }}</h2>
            </section>
            <div class="summary-form">
                <section class="coverage">
                    <h3 class="title">{{ translateForType('choose_coverage_title', TranslationType) }}</h3>
                    <p class="description">{{ translateForType('choose_coverage_description', TranslationType) }}</p>
                    <app-input-radio-overlayed class="coverage-options"
                                               :form-field="form.field('coverage')"
                                               :options="coverageOptions"
                                               @change="onCoverageChange">
                    </app-input-radio-overlayed>
                    <div class="payment">
                        <span class="payment-text">{{
                                translateForType('your_monthly_payment', TranslationType)
                            }}</span>
                        <span class="price">
                            <span class="amount">{{ productPrice }}</span> {{
                                translate('btar_policy_price_text')
                            }}</span>
                    </div>
                    <div class="product-buttons">
                        <app-button-with-callback
                            v-bind="selectButtonParams()"
                            @button-callback-click="onSelectProductClick">
                        </app-button-with-callback>
                        <app-button-with-callback
                            v-bind="coveredButtonParams()"
                            @button-callback-click="onCustomProductClick">
                        </app-button-with-callback>
                    </div>
                </section>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    margin-bottom: var(--size-big);
    margin-top: calc(56px + var(--size-big));
    width: 100%;

    .header {
        padding: 0 var(--size-small);

        @include respond-above('sm') {
            padding: 0 var(--size-big);
        }

        .title,
        .description {
            text-align: center;
        }

        .title {
            font-size: var(--font-size-big);
        }
    }

    .summary-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-small);
        margin-top: var(--size-big);
        padding: var(--size-small);
        padding-top: 0;

        @include respond-above('sm') {
            gap: var(--size-normal);
            align-items: center;
        }

        .coverage {
            width: 100%;
            border-radius: var(--size-tiny);
            background: var(--component-color-background-base);
            padding: var(--size-small);

            @include respond-above('sm') {
                width: 580px;
                padding: var(--size-big);
            }

            .title {
                font-size: var(--font-size-medium);
                margin-bottom: var(--size-nano);


                @include respond-above('sm') {
                    text-align: center;
                }
            }

            .description {
                font-size: var(--font-size-tiny);

                @include respond-above('sm') {
                    text-align: center;
                }
            }

            .coverage-options {
                margin: var(--size-medium) 0;
                height: 52px;
            }

            .payment {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .price {
                    color: var(--system-color-success-dark);

                    .amount {
                        font-size: var(--font-size-big);
                        font-weight: 700;
                    }
                }
            }

            .product-buttons {
                margin-top: var(--size-medium);
                display: flex;
                flex-direction: column;
                gap: var(--size-nano);
                width: 100%;

                .button-with-callback {
                    height: 52px;
                    padding: 0 var(--button-horizontal-padding);
                }
            }
        }
    }

    @include respond-above('sm') {
        padding: 0 var(--size-big);
        margin-bottom: var(--size-huge);
    }

    .full-width {
        width: 100%;
    }

    .half-children {
        .input {
            @include respond-above('sm') {
                width: 50%;
            }
        }
    }

    .flex {
        display: flex;
        gap: var(--size-small);

        &.column {
            flex-direction: column;
        }

        &.mobile-column {
            flex-direction: column;

            @include respond-above('sm') {
                flex-direction: row;
            }
        }
    }
}
</style>
