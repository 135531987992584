import 'vite/modulepreload-polyfill';
import 'moment/dist/locale/lv'
import 'moment/dist/locale/lt'
import 'moment/dist/locale/et'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/ru'
import {App} from 'vue';
import {createPinia} from 'pinia';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import VueApp from '@/Core/Routing/VueApp';
import {ComponentPublicInstance} from '@vue/runtime-core';
import Router from '@/Core/Routing/Router';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/PayByLink/Pay/App.vue';
import DetailsPage from '@/Apps/PayByLink/Pay/Pages/DetailsPage.vue';
import DetailsLithuaniaPage from '@/Apps/PayByLink/Pay/Pages/DetailsLithuaniaPage.vue';
import EditOfferPage from '@/Apps/PayByLink/Pay/Pages/EditOfferPage.vue';
import EditOfferLithuaniaPage from '@/Apps/PayByLink/Pay/Pages/EditOfferLithuaniaPage.vue';
import SummaryPage from '@/Apps/PayByLink/Pay/Pages/SummaryPage.vue';
import SummaryLithuaniaPage from '@/Apps/PayByLink/Pay/Pages/SummaryLithuaniaPage.vue';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/RouteStepper/RouteStepper.vue';
import InputText from '@/Components/InputText/InputText.vue';
import InputNumber from '@/Components/InputNumber/InputNumber.vue';
import InputCheckbox from '@/Components/InputCheckbox/InputCheckbox.vue';
import Popup from '@/Components/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltipster/Tooltipster.vue';
import ContentLoader from '@/Components/ContentLoader/ContentLoader.vue';
import CustomForm from '@/Components/CustomForm/CustomForm.vue';
import LanguageSwitcher from '@/Components/LanguageSwitcher/LanguageSwitcher.vue';
import InputRadio from '@/Components/InputRadio/InputRadio.vue';
import InputEmail from '@/Components/InputEmail/InputEmail.vue';
import InputSelect from '@/Components/InputSelect/InputSelect.vue';
import InputDateWithCalendar from '@/Components/InputDateWithCalendar/InputDateWithCalendar.vue';
import InputDateWithDropdown from '@/Components/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputMultiCheckbox from '@/Components/InputMultiCheckbox/InputMultiCheckbox.vue';
import ButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import FilesUploader from '@/Components/FilesUploader/FilesUploader.vue';
import PersonListWithSearch from '@/Components/PersonListWithSearch/PersonListWithSearch.vue';
import PhoneWithCountry from '@/Components/PhoneWithCountry/PhoneWithCountry.vue';
import CreditCardDetails from '@/Components/CreditCardDetails/CreditCardDetails.vue';
import InformationalTextBlock from '@/Components/InformationalTextBlock/InformationalTextBlock.vue';
import Drawer from '@/Components/Drawer/Drawer.vue';
import InputRadioOverlayed from '@/Components/InputRadioOverlayed/InputRadioOverlayed.vue';
import MtplOfferOption from '@/Components/AdditionalOptionsList/Components/MtplOfferOption.vue';
import PlaceholderOption from '@/Components/AdditionalOptionsList/Components/PlaceholderOption.vue';
import Expandable from '@/Components/Expandable/Expandable.vue';

const pages: Components = {
    DetailsPage: DetailsPage,
    DetailsLithuaniaPage: DetailsLithuaniaPage,
    EditOfferPage: EditOfferPage,
    EditOfferLithuaniaPage: EditOfferLithuaniaPage,
    SummaryPage: SummaryPage,
    SummaryLithuaniaPage: SummaryLithuaniaPage,
}

const components: Components = {
    AppStepperLayout: StepperLayout,
    AppRouteStepper: RouteStepper,
    AppInputText: InputText,
    AppInputNumber: InputNumber,
    AppInputCheckbox: InputCheckbox,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppContentLoader: ContentLoader,
    AppCustomForm: CustomForm,
    AppLanguageSwitcher: LanguageSwitcher,
    AppInputRadio: InputRadio,
    AppInputEmail: InputEmail,
    AppInputSelect: InputSelect,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputMultiCheckbox: InputMultiCheckbox,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppButtonWithCallback: ButtonWithCallback,
    AppFilesUploader: FilesUploader,
    AppPersonListWithSearch: PersonListWithSearch,
    AppPhoneWithCountry: PhoneWithCountry,
    AppCreditCardDetails: CreditCardDetails,
    AppInformationalTextBlock: InformationalTextBlock,
    AppDrawer: Drawer,
    AppMtplOfferOption: MtplOfferOption,
    AppPlaceholderOption: PlaceholderOption,
    AppExpandable: Expandable,
}

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({...pages, ...components})
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());
        return app.mount(selector);
    }
}
