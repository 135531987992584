<script setup lang="ts">

import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {computed, Ref} from 'vue';
import AppButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';

const props = defineProps({
    title: {type: String, default: ''},
    subTitle: {type: String, default: ''},
    buttons: {
        /**
         *  params: ButtonWithCallbackParams[]
         */
        type: Array, default: (): DynamicDictionary[] => {
            return [];
        }
    }
});

const componentButtons: Ref<ButtonWithCallbackParams[]> = computed(() => {
    return props.buttons as ButtonWithCallbackParams[];
});
</script>

<template>
    <div class="info-with-actions">
        <h5 class="title">{{ props.title }}</h5>
        <div class="sub-title" v-html="props.subTitle"></div>
        <div class="buttons">
            <app-button-with-callback
                v-for="(button, index) in componentButtons"
                :key="index"
                v-bind="button">
            </app-button-with-callback>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.info-with-actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 656px;
    padding: 37px 32px 15px 32px;

    & {
        background-color: var(--component-color-background-base);
        border-radius: 16px;
    }

    @include respond-below('lg') {
        padding: var(--size-normal);
    }

    @include respond-below('sm') {
        max-width: 100%;
    }

    .title {
        margin-bottom: 15px;
    }

    .sub-title {
        margin-bottom: 24px;
        line-height: var(--line-height-accented);
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .button-with-callback {
            min-width: 286px;
            min-height: 52px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @include respond-below('sm') {
            flex-direction: column;
            flex-flow: column-reverse;
        }
    }
}
</style>
