import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import MapLocation from '@/interfaces/map.location.interface';

export default class ClaimsMtplFormFields {
    typeOfClaim?: DynamicDictionary;
    whenEventHappened?: DynamicDictionary;
    whatTimeDidItHappened?: string;
    whatVehicleWasInvolved?: DynamicDictionary;
    weFoundElectronically?: DynamicDictionary;
    howWasInvolvedHandled?: DynamicDictionary;
    policeProtocolNumber?: string;
    guiltyPartyVehicleNumber?: string;

    whatWasDamaged?: DynamicDictionary;
    belongingsDamagedList?: string;
    belongingsOwnerIsApplicant?: DynamicDictionary;
    belongingsOwnerDetails?: string;
    personDetails?: string;
    propertyLocation?: MapLocation;
    propertyOwnerIsApplicant?: DynamicDictionary;
    propertyOwnerDetails?: string;
    vehicleVehicleRegistrationNumber?: string;
    vehicleProceedWithAgreedStatement?: DynamicDictionary;
    vehicleAgreedStatementLabel?: string;
    vehicleVehicleIsDrivable?: DynamicDictionary;
    vehicleVehicleDriverIsApplicant?: DynamicDictionary;
    vehicleVehicleDriverResident?: string;
    vehicleNonResidentDriverName?: string;
    vehicleNonResidentDriverSurname?: string;
    vehicleNonResidentDriverBirthDate?: Date;
    vehicleNonResidentDriverLicenseNumber?: string;
    vehicleResidentDriverName?: string;
    vehicleResidentDriverSurname?: string;
    vehicleResidentDriverPersonCode?: string;
    vehicleResidentDriverBirthDate?: Date;
    vehicleVehicleDamagedBeforeAccident?: DynamicDictionary;
    vehicleDamageBeforeAccident?: string;

    whereAccidentHappened?: MapLocation;
    accidentDescription?: string;
    didGuiltyLeaveSceneBeforePolice?: DynamicDictionary;

    typeOfCompensation?: DynamicDictionary;
    otherRepairService?: string;
    repairService?: DynamicDictionary;

    filesUploaded?: boolean;

    person?: string;
    personType?: string;
    resident?: string;
    countryOfResident?: DynamicDictionary;
    name?: string;
    surname?: string;
    personCode?: string;
    birthDate?: string;
    email?: string;
    mobilePhone?: DynamicDictionary;
    bankAccountNumber?: string;
    companyName?: string;
    companyType?: string;
    registrationNumber?: string;

    contactEmail?: string;
    contactPhoneNumber?: DynamicDictionary;
}
