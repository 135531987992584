<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import {computed, onMounted, reactive, Ref, UnwrapNestedRefs} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import {useTranslate} from '@/Composables/Translate';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import {InputOption} from '@/interfaces/InputOptionInterface';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ClaimsMtplOptions from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionsInterface';
import {useStrings} from '@/Composables/Strings';
import BelongingsDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/BelongingsDamageNames';
import DamageTypes from '@/Apps/ClaimsMtpl/Enums/DamageTypesEnum';
import {SubFlowEvent} from '@/Apps/ClaimsMtpl/Interfaces/SubFlowEvent';
import Translations from '@/services/translations.service';
import ClaimsMtplClaimTypes from '@/Apps/ClaimsMtpl/Enums/ClaimsMtplClaimTypes';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import Value from '@/assets/libraries/form/value';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useDefine} from '@/Composables/Define';
import PropertyDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/PropertyDamageNames';

const props = defineProps({
    dataScroll: {type: String},
});
const emit = defineEmits<{
    (event: 'completed', subFlowEvent: SubFlowEvent): void
    (event: 'change'): void
}>();

const {translate, translateForType} = useTranslate();
const {isSet} = useDefine();

const btaBase: OneBase = OneBaseService.getInstance();
const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();

const form: Form = new Form();
const inputOptions: UnwrapNestedRefs<ClaimsMtplOptions> = reactive({});

const belongingsOwnerIsNotApplicant: Ref<boolean> = computed(() => {
    return form.field(BelongingsDamageNames.OwnerIsApplicant).value === 'no';
});

const isGuiltyFlow: Ref<boolean> = computed(() => {
    return claimsMtplService.fields.typeOfClaim?.selected === ClaimsMtplClaimTypes.Guilty
});

const buttonIsDisabled: Ref<boolean> = computed(() => {
    return !isGuiltyFlow.value ?
        !form.field(BelongingsDamageNames.DamagedList).isValid || !form.field(BelongingsDamageNames.OwnerIsApplicant).isValid :
        !form.field(BelongingsDamageNames.DamagedList).isValid;
});


function preparePanels(): void {
    for (let key in BelongingsDamageNames) {
        inputOptions[key] = new class ClaimsMtplOptions {
            public enabled: boolean = true;
            public passed: boolean = false;
            public visible: boolean = false;
            public value: LimitedVariant = '';
            public options: InputOption[] = [];
        }
    }
}

function buildOwnerIsApplicantOptions(): void {
    inputOptions.OwnerIsApplicant.options = [
        (new InputOptionBuilder).setName(translate('btar_yes')).setValue('yes').build(),
        (new InputOptionBuilder).setName(translate('btar_no')).setValue('no').build(),
    ];
}

function proceedButton(): ButtonWithCallbackParams {
    return {
        title: localized('proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function setupForm(): void {
    form.addField(new FormField(BelongingsDamageNames.DamagedList));
    form.addField(new FormField(BelongingsDamageNames.OwnerIsApplicant, '', 'required'));
    form.addField(new FormField(BelongingsDamageNames.OwnerDetails));
    addFormValidators();
    form.setReady();
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = claimsMtplService.fields;
    Object.keys(BelongingsDamageNames).forEach((field: string) => {
        const formKey: string = BelongingsDamageNames[field as keyof BelongingsDamageNames];
        const serviceKey: string = 'belongings' + formKey.charAt(0).toUpperCase() + formKey.slice(1);
        if (isSet(storedValues[serviceKey]) && new Value(storedValues[serviceKey]).isNotEmpty()) {
            form.field(formKey).setValue(storedValues[serviceKey]);
        }
    });
}

function addFormValidators(): void {
    form.field(BelongingsDamageNames.DamagedList).addValidators({
        describeEventValid: (value: string) => !(stringOutOfBounds(value) || stringHasInvalidText(value))
    });
}

function stringOutOfBounds(text: string): boolean {
    const multibyteLength: number = useStrings().multibyteLength(text);
    const minLength: number = btaBase.settings.claimsSettings().mtpl.descriptionFieldMinLength;
    const maxLength: number = btaBase.settings.claimsSettings().mtpl.descriptionFieldMaxLength;

    return multibyteLength < minLength || multibyteLength > maxLength;
}

function stringHasInvalidText(text: string): boolean {
    return !useStrings().isValidWordString(text);
}

function storeFormToService(): void {
    Object.keys(BelongingsDamageNames).forEach((field: string) => {
        const formKey: string = BelongingsDamageNames[field as keyof BelongingsDamageNames];
        const serviceKey: string = 'belongings' + formKey.charAt(0).toUpperCase() + formKey.slice(1);
        claimsMtplService.fields[serviceKey as keyof ClaimsMtplFormFields] = form.field(formKey).value;
    });
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function completedEvent(): SubFlowEvent {
    return {
        [DamageTypes.Belongings]: {
            description: form.field(BelongingsDamageNames.DamagedList).value
        }
    }
}

function onChange(): void {
    emit('change');
    storeFormToService();
}

preparePanels();
buildOwnerIsApplicantOptions();

onMounted(() => {
    setupForm();
    restoreValues();
});
defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container">
        <app-custom-form
            v-if="form.isReady()"
            :form="form"
            :data-scroll="dataScroll"
            @change="onChange()"
            class="form">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <label>{{ localized('belongings_damage_title') }}</label>
                <div class="whiteboard">
                    <h4 class="title">{{ localized('belongings_details_title') }}</h4>
                    <app-input-textarea
                        :label="localized('belongings_damaged_list_label')"
                        :form-field="form.field(BelongingsDamageNames.DamagedList)"
                        :placeholder="localized('belongings_damaged_list_placeholder')">
                        <template v-slot:app-tooltipster>
                            <app-tooltipster
                                :description="localized('belongings_damaged_list_tooltip')">
                            </app-tooltipster>
                        </template>
                    </app-input-textarea>
                    <template v-if="!isGuiltyFlow">
                        <app-input-radio
                            :options="inputOptions.OwnerIsApplicant.options"
                            :type="'radio'"
                            :label="localized('belongings_owner_is_applicant_label')"
                            :form-field="form.field(BelongingsDamageNames.OwnerIsApplicant)">
                        </app-input-radio>
                        <template v-if="belongingsOwnerIsNotApplicant">
                            <h4 class="title">{{ localized('belongings_owner_details_title') }}</h4>
                            <app-input-text
                                :style="'width: 100%;'"
                                :form-field="form.field(BelongingsDamageNames.OwnerDetails)"
                                :placeholder="localized('belongings_owner_details_placeholder')">
                            </app-input-text>
                        </template>
                    </template>
                    <app-button-with-callback class="button"
                                              data-type="owner-details-submit"
                                              v-bind="proceedButton()"
                                              @button-callback-click="emit('completed', completedEvent())"
                                              :disabled="buttonIsDisabled">
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;

    .button {
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .whiteboard.whiteboard-margin h4:first-of-type {
        margin-bottom: var(--size-big);
    }

    :deep(.input-textarea .label.informative) {
        margin-bottom: var(--size-pico);
    }

    :deep(#ownerDetails, .input-radio[data-type="ownerIsApplicant"]) {
        margin-bottom: 0;
    }

    :deep(.input-radio) {
        margin-top: var(--size-big);

        .buttons {
            width: min-content;

            button.type-radio.size-large {
                padding-left: 36px;
                padding-right: var(--size-big);

                &.active::before {
                    border-color: var(--system-color-success-dark);
                    background-color: var(--white);
                    border-width: var(--size-pico);
                }
            }

            .button {
                &::before {
                    border: solid 2px var(--component-color-border-default);
                }
            }
        }

        &.invalid:not(.untouched) {
            .buttons {
                .button {
                    &::before {
                        border-color: var(--brand-red);
                        opacity: 1;
                    }
                }
            }
        }

        + h4.title {
            margin-top: var(--size-big);
        }
    }

    :deep(.map-with-address .search-container .google-search-suggestions .geo svg .pin) {
        fill: var(--black-500);
    }
}
</style>
