<script setup lang="ts">

import {computed, PropType, Ref} from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useTranslate} from '@/Composables/Translate';
import {ClaimStatus} from '@/Components/ClaimBlock/Enums/ClaimStatus.enum';
import {usePrice} from '@/Composables/Price';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import Claim from '@/Components/ClaimBlock/Interfaces/ClaimInterface';


const props = defineProps({
    item: {
        type: Object as PropType<Claim>, default: () => {
            return {};
        }
    },
    buttons: {
        type: Array as PropType<ButtonWithCallbackParams[]>, default: () => {
            return [];
        }
    }
});


const {translateForType} = useTranslate();
const {price} = usePrice();
const translationType: string = 'dashboard';


const submitDate: Ref<string> = computed(() => {
    return translated('submit_date', {'%date%': props.item.submitDate})
})

const claimNumber: Ref<string> = computed(() => {
    return translated('claim_number', {'%number%': props.item.claimNumber})
})

const compensation: Ref<string> = computed(() => {
    return translated('compensation_amount', {'%amount%': price(props.item.sumApproved as string, false, 'EUR')});
});

const statusClass: Ref<string> = computed(() => {
    let result: string;
    switch (props.item.status) {
        case ClaimStatus.Approved:
        case ClaimStatus.DecisionDone:
            result = 'teal';
            break;
        case ClaimStatus.Declined:
        case ClaimStatus.DocumentsAwaited:
            result = 'red';
            break;
        case ClaimStatus.InProgress:
        case ClaimStatus.Registered:
        case ClaimStatus.InClaimHandling:
            result = 'orange';
            break;
        case ClaimStatus.Submitted:
            result = 'violet';
            break;
        default:
            result = 'default';
    }

    return result;
})


function translated(key: string, replacements?: DynamicDictionary): string {
    return translateForType(key, translationType, replacements);
}
</script>

<template>
    <div v-if="item" class="claims-block">
        <div class="info-wrapper">
            <div class="status-info">
            <span class=status-badge
                  :class="statusClass"
                  v-html="item.status">
            </span>
                <span class="submit-date sub-title"
                      v-html="submitDate">
            </span>
                <div class="attachments" v-if="item.attachments && item.attachments.length > 0">
                    <img src="images/one/components/claims/paperclip.svg" alt="">
                    <span class="sub-title" v-html="item.attachments.length"></span>
                </div>
            </div>
            <div class="claim-info">
                <span class="name title" v-html="item.title"></span>
                <span class="reason sub-title" v-if="item.reason" v-html="item.reason"></span>
                <span class="compensation sub-title"
                      v-if="item.sumApproved"
                      v-html="compensation">
            </span>
                <span class="claim-no sub-title" v-html="claimNumber"></span>
            </div>
        </div>
        <div class="buttons">
            <app-button-with-callback v-for="(button) in buttons"
                                      v-bind="button">
            </app-button-with-callback>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.claims-block {
    display: flex;
    flex-direction: column;
    background-color: var(--component-color-background-base);
    border-radius: var(--size-pico);
    width: 100%;
    min-height: 119px;
    padding: var(--size-small);
    font-size: var(--font-size-nano);
    font-weight: 600;
    box-shadow: 0 1px 0 var(--black-300);
    gap: var(--size-nano);

    @include respond-above('sm') {
        flex-direction: row;
        gap: var(--size-small);
        padding: var(--size-normal);
    }

    .info-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--size-nano);
        margin-right: var(--size-small);

        .status-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: var(--size-nano);

            @include respond-above('sm') {
                gap: var(--size-small);
            }

            .status-badge {
                padding: 10px var(--size-nano);
                color: var(--text-color-default);
                border-radius: 8px;
                background-color: var(--black-50);
                font-size: var(--font-size-nano);
                font-weight: 500;

                &.orange {
                    color: var(--system-color-warning-dark);
                    background-color: var(--orange-50);
                }

                &.red {
                    color: var(--system-color-error-dark);
                    background-color: var(--red-50);
                }

                &.teal {
                    color: var(--system-color-success-dark);
                    background-color: var(--teal-50);
                }

                &.blue {
                    color: var(--blue-800);
                    background-color: var(--blue-50);
                }

                &.violet {
                    color: var(--blue-800);
                    background-color: var(--blue-50);
                }
            }

            .attachments {
                display: flex;
                gap: var(--size-pico);
            }
        }

        .claim-info {
            display: flex;
            flex-direction: column;
            gap: var(--size-pico);

            @include respond-above('sm') {
                flex-direction: row;
                align-items: center;
                gap: var(--size-small);
            }

            .name {
                margin-bottom: var(--size-femto);
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        gap: var(--size-nano);
        width: 100%;

        @include respond-above('sm') {
            flex-direction: row;
            gap: var(--size-small);
            width: unset;
        }

        .button-with-callback {
            min-height: 52px;
            padding: var(--size-tiny) var(--size-medium);
            width: 100%;

            @include respond-above('sm') {
                width: unset;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .title {
        font-size: var(--font-size-tiny);
        font-weight: 600;
    }

    .sub-title {
        font-weight: 500;
    }
}
</style>
