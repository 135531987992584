<script lang="ts">
import {computed, defineComponent, reactive, ref, Ref, nextTick, UnwrapNestedRefs} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import ClaimsOptions from '@/interfaces/claims.options.interface';
import ClaimOption from '@/interfaces/claim.option.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import {InputOption} from '@/interfaces/InputOptionInterface';
import FetchParams from '@/Components/MapWithPlaces/FetchParams';
import CascoClaimsPanelsNames from '@/Enums/CascoClaimsPanelsNamesEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useTranslate} from '@/Composables/Translate';
import {useDefine} from '@/Composables/Define';
import {useAxios} from '@/Composables/Axios';
import BreakPoints from '@/Enums/BreakPointsEnum';
import FormField from '@/assets/libraries/form/form-field';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {Subscription} from 'rxjs';
import Coordinates = JQuery.Coordinates;
import OnePopup from '@/assets/libraries/popups/one.popup'
import Url from '@/Enums/UrlEnum';
import {AxiosResponse} from 'axios';
import AppCountry from '@/assets/libraries/app/app-country'
import SettingsService from '@/services/settings.service';
import CascoClaimsMandatoryReport from '@/Enums/CascoClaimsMandatoryReportEnum';
import Translations from '@/services/translations.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {translate, translateForType} = useTranslate();
        const {isSet} = useDefine();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 4;
        const Facility: string = 'one-claims-casco';
        const CompensationTypeService: string = 'service';
        const CompensationTypeMyself: string = 'myself';
        const CompensationTypeOtherService: string = 'other';
        const ServiceTypeOther: string = 'other_repair';
        const ServiceTypeGlass: string = 'glass_repair';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);

        const inputOptions: UnwrapNestedRefs<ClaimsOptions> = reactive(new class implements ClaimsOptions {
            [key: string]: ClaimOption;

            public 'how-you-want-to-receive-compensation': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'do-you-want-to-repair-vehicle-abroad': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = false;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'please-choose-repair-service': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'country-of-accident': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
            public 'repair-service-details': ClaimOption = new class implements ClaimOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            }
        });

        //TODO Move back to composable when possible to override functions
        const claimsCascoRecipientDataUrl: Ref<string> = ref('');
        const claimsCascoContactDetailsUrl: Ref<string> = ref('');
        const claimsCascoUploadFilesUrl: Ref<string> = ref('');
        const lastPreparationsName: Ref<string> = ref('');
        const canClearFormsAhead: Ref<boolean> = ref(false);
        const isSearchInProgress: Ref<boolean> = ref(false);
        const showStolenCar: Ref<boolean> = ref(false);
        const stolenCarText: Ref<string> = ref('');
        const stolenCarWhatToDo: Ref<string> = ref('');
        const singleAutoSubmitOptions: Ref<DynamicDictionary> = ref({});
        const skippedPanel: Ref<string> = ref('');
        const featuredServicesCount: Ref<number> = ref(0);
        const lastScrollTarget: Ref<string> = ref('');
        const accidentCountryIso: Ref<string> = ref('');

        const repairServicesFetchParams: Ref<FetchParams> = computed(() => {
            return {
                insuredObjectId: btaBase.userStorage.stepStorageData['what-vehicle-was-involved'].object,
                storageFacility: btaBase.facility(),
                storageDataType: 'casco-object',
                showInClaims: 'true',
                serviceType: repairServiceType.value,
            } as FetchParams;
        });

        const customPlaceholders: Ref<DynamicDictionary> = computed(() => {
            const result: DynamicDictionary = {};
            result[CompensationTypeOtherService] = 'claims_casco_extra_details_service_other';

            return result;
        });

        const repairServiceType: Ref<string> = computed(() => {
            return btaBase.userStorage.stepStorageData['select-damage-type'].selected === 'GLASS' ?
                ServiceTypeGlass :
                ServiceTypeOther;
        });

        //TODO Move back to composable when possible to override functions
        const proceedButtonIsVisible = (panel: string): boolean => {
            let result: boolean = true;
            let allowNextItemVisibilityCheck: boolean = false;
            Object.keys(inputOptions).forEach((field: string): void => {
                if (allowNextItemVisibilityCheck &&
                    inputOptions[field].enabled && inputOptions[field].visible) {
                    result = false;
                    allowNextItemVisibilityCheck = false;
                }
                if (field === panel) {
                    allowNextItemVisibilityCheck = true;
                }
            });

            return result;
        }

        const onProceed = (panel: string, initial: boolean = false, skipValidate: boolean = false): void => {
            validatePanel(panel, skipValidate).then((): void => {
                blockOnStolenCar(panel).then((): void => {
                    applyPassedOnOption(panel, true);
                    fetchEnabledPanels(initial).then((visibilities: string[]): void => {
                        enablePanels(visibilities).then((): void => {
                            showNextPanel(panel, initial);
                        }).catch((reason: DynamicDictionary): void => {
                            redirectToStep(reason);
                        });
                    });
                }).catch((reason): void => {
                    Error.log(ErrorType.Error, 'onProceed', reason, true);
                });
            }).catch((): void => {
                applyPassedOnOption(panel, false);
            }).finally((): void => {
                clearAllValidators();
            });
        }

        const formHasObjectValue = (field: string): boolean => {
            return form.field(field).value.selected !== '';
        }

        const lastVisiblePanel = (): string => {
            let result: string = '';
            Object.keys(inputOptions).forEach((field: string): void => {
                if (inputOptions[field].enabled && inputOptions[field].visible) {
                    result = field;
                }
            });

            return result;
        }

        const isValidResponse = (response: DynamicDictionary): boolean => {
            return isValidBodyResponse(response) &&
                !hasBodyErrorResponse(response.data.data.body);
        }

        const applyValueOnOption = (option: string, newValue: LimitedVariant): void => {
            if (optionIsPresent(option)) {
                inputOptions[option].value = newValue;
            }
        }

        const applyOptionsOnOption = (option: string, options: InputOption[]): void => {
            if (optionIsPresent(option)) {
                inputOptions[option].options = options;
            }
        }

        const applyEnabledOnOption = (option: string, enabled: boolean): void => {
            if (optionIsPresent(option)) {
                inputOptions[option].enabled = enabled;
            }
        }

        const applyVisibleOnOption = (option: string, visible: boolean): void => {
            if (optionIsPresent(option)) {
                inputOptions[option].visible = visible;
            }
            calculateStepperVisibility();
        }

        const scrollToDataOffset = (target: string, forced: boolean = false): void => {
            if (lastScrollTarget.value !== target || forced) {
                lastScrollTarget.value = target;
                nextTick((): void => {
                    const $panel: JQuery = $('[data-scroll="' + target + '"]');
                    if ($panel.length > 0) {
                        const scrollSpeed: number = 350;
                        const offset: Coordinates | undefined = $panel.offset();
                        const verticalOffset: number = offset ? offset.top - headerOffsetForScroll.value : 0;
                        if (offset && verticalOffset > 0) {
                            $('html,body')
                                .stop(true, false)
                                .animate({scrollTop: verticalOffset}, scrollSpeed);
                            const textField: JQuery = $panel.find('input[type=text]').first();
                            const textFieldInFocus: boolean = textField.is(":focus");
                            if (textField && !textFieldInFocus) {
                                textField.trigger('focus');
                            }
                        }
                    }
                });
            }
        }

        const isValidBodyResponse = (response: DynamicDictionary): boolean => {
            return isSet(response.data) &&
                isSet(response.data.data) &&
                isSet(response.data.data.body);
        }

        const validatePanel = (panel: string, skipValidate: boolean = false): Promise<void> => {
            let result: Promise<void> = Promise.resolve();
            if (!skipValidate) {
                form.field(panel).clearValidators();
                form.field(panel).clearCustomValidators();
                touchAndRequireNecessaryField(panel);
                result = form.field(panel).validate().then((): Promise<void> => {
                    return form.field(panel).isValid ? Promise.resolve() : Promise.reject();
                });
            }

            return result;
        }

        const applySingleDamageSubtype = (): void => {
            const field: string = CascoClaimsPanelsNames.DamageSubtype;
            if (isSet(inputOptions[field])) {
                let newValue: LimitedVariant = inputOptions[field].value;
                if (newValue === '' &&
                    inputOptions[field].options.length === 1 &&
                    field === CascoClaimsPanelsNames.DamageSubtype) {
                    newValue = {
                        selected: inputOptions[field].options[0].value
                    };
                }
                stepsSubmitter.addSubmitCustomParam(field, newValue as DynamicDictionary);
            }
        }

        const isVisiblePanel = (panel: string): boolean => {
            let result: boolean;
            if (panel === CascoClaimsPanelsNames.ReportTheAccident) {
                const canShowPanel: boolean = inputOptions[panel].enabled && inputOptions[panel].visible &&
                    inputOptions[CascoClaimsPanelsNames.HowAccidentIsReported].passed &&
                    !isAccidentReported();
                inputOptions[panel].visible = canShowPanel;
                result = canShowPanel && !skipPanel(panel);
            } else {
                const canUsePanel: boolean = inputOptions[panel].enabled && inputOptions[panel].visible;
                const currentSkipPanel: boolean = skipPanel(panel);
                result = canUsePanel && !currentSkipPanel
            }

            return result;
        }

        const clearAllValidators = (): void => {
            Object.keys(inputOptions).forEach((panel: string): void => {
                form.field(panel).clearValidators();
                if (panel !== CascoClaimsPanelsNames.SelectDamageType) {
                    form.field(panel).clearCustomValidators();
                }
            });
        }

        const clearFormsAhead = (field: string): void => {
            let clear: boolean = false;
            for (const key in inputOptions) {
                if (clear) {
                    applyResetOnOption(key);
                }
                if (key === field) {
                    clear = true;
                    applyPassedOnOption(key, false);
                    lastPreparationsName.value = '';
                }
            }
            lastScrollTarget.value = '';
        }

        const enablePanels = (options: string[] | DynamicDictionary): Promise<void> => {
            let result: Promise<void>;
            if ((options as DynamicDictionary).redirect) {
                const reason: DynamicDictionary = {
                    type: 'redirect',
                    url: (options as DynamicDictionary).redirect
                };
                result = Promise.reject(reason);
            } else {
                disableAllOptions();
                options.forEach((option: string): void => {
                    applyEnabledOnOption(option, true);
                });
                result = Promise.resolve();
            }

            return result;
        }

        const showInitialEnabledPanels = (): void => {
            disablePanelsWithSingleOptions();
            disablePanelsSkipped();
            Object.keys(inputOptions).forEach((field: string): void => {
                if (hasInitialEnabledPanel(field)) {
                    validatePanel(field).then((): void => {
                        applyVisibleOnOption(field, true);
                        applyPassedOnOption(field, true);
                        showNextPanel(field, true);
                    }).catch(() => {
                        applyVisibleOnOption(field, true);
                        form.field(field).touch();
                    });
                } else {
                    if (formHasValue(field) && previousFormHasValue(field)) {
                        applyVisibleOnOption(field, true);
                        if (!hasRedirectAction(field)) {
                            applyPassedOnOption(field, true);
                            showNextPanel(field, true);
                        }
                    }
                }
            });
        }

        const hasInitialEnabledPanel = (key: string): boolean => {
            let result: boolean = form.field(key).name === CascoClaimsPanelsNames.WhatTimeDidItHappened;
            if (hasAccidentCountryIso() && sameCountryIso() && hasSkippedRepairVehicleAbroadPanel()) {
                const hasPleaseChooseRepairService: boolean =
                    form.field(key).name === CascoClaimsPanelsNames.PleaseChooseRepairService;
                result = result || hasPleaseChooseRepairService;
            }

            return result;
        }

        const disablePanelsSkipped = (): void => {
            if (Object.keys(inputOptions).includes(skippedPanel.value)) {
                inputOptions[skippedPanel.value].enabled = false;
                inputOptions[skippedPanel.value].visible = false;
                if (panelHasSingleOption(skippedPanel.value)) {
                    addSingleOptionToAutoSubmit(skippedPanel.value);
                }
            }

            Object.keys(inputOptions).forEach((field: string): void => {
                disableOptionsIfItHasSingleOption(field);
            });
        }

        const redirectToStep = (params: DynamicDictionary): void => {
            if (isSet(params.type) && params.type === 'redirect') {
                btaBase.popupService.show(new OnePopup().withType().loading);
                const isExtraDetails: boolean = params.url.includes('extra-details');
                if (isExtraDetails) {
                    removeVehicleStepInStepper();
                }
                const extraDetailsStepNumber: number = 4;
                const vehicleStepNumber: number = 3;
                prepareSubmit();
                stepsSubmitter.proceedStep(params.url, isExtraDetails ?
                    extraDetailsStepNumber :
                    vehicleStepNumber);
            }
        }

        const disablePanelsWithSingleOptions = (): void => {
            Object.keys(inputOptions).forEach((field: string): void => {
                disableOptionsIfItHasSingleOption(field);
            });
        }

        const disableOptionsIfItHasSingleOption = (field: string): void => {
            if (panelHasSingleOption(field)) {
                inputOptions[field].enabled = false;
                inputOptions[field].visible = false;
                addSingleOptionToAutoSubmit(field);
            }
        }

        const panelHasSingleOption = (panel: string): boolean => {
            return inputOptions[panel].options.length === 1;
        }

        const addSingleOptionToAutoSubmit = (panel: string): void => {
            const newValue: DynamicDictionary = {selected: inputOptions[panel].options[0].value};
            singleAutoSubmitOptions.value[panel] = newValue;
            form.field(panel).patch(newValue);
        }

        const showNextPanel = (panel: string, initial: boolean = false): void => {
            initial ? showNextPanelInitial(panel) : showNextPanelRegular(panel);
        }

        const showNextPanelInitial = (panel: string): void => {
            const nextEnabledPanel: string = nextEnabledPanelName(panel);
            applyVisibleOnOption(nextEnabledPanel, true);
        }

        const showNextPanelRegular = (panel: string): void => {
            const nextEnabledPanel: string = nextEnabledPanelName(panel);
            prepareOptions(nextEnabledPanel).then((): void => {
                if (panelHasSingleOption(nextEnabledPanel)) {
                    addSingleOptionToAutoSubmit(nextEnabledPanel);
                    onProceed(nextEnabledPanel, false, true);
                } else {
                    applyVisibleOnOption(nextEnabledPanel, true);
                    scrollToDataOffset(lastVisiblePanel());
                }
            });
        }

        const removeVehicleStepInStepper = (): void => {
            const accidentDataStepNumber: number = 2;
            const extraDetailsStepNumber: number = 4;
            const uploadFilesStepNumber: number = 5;
            const recipientDataStepNumber: number = 6;
            const yourContactDetailsStepNumber: number = 7;
            btaBase.dynamicStepper.applyEnabled([
                1,
                accidentDataStepNumber,
                extraDetailsStepNumber,
                uploadFilesStepNumber,
                recipientDataStepNumber,
                yourContactDetailsStepNumber,
            ]);
        }

        const optionIsPresent = (option: string): boolean => {
            return isSet(inputOptions[option]);
        }

        const fetchEnabledPanels = (initial: boolean = false): Promise<string[]> => {
            let result: Promise<string[]>;
            if (initial) {
                result = Promise.resolve([]);
            } else {
                const params: DynamicDictionary = visibilityRequestParams();
                btaBase.popupService.show(new OnePopup().withType().loading);
                result = useAxios().get(Url.Ajax.claimsCascoEnabledPanels, {params})
                    .then((response: AxiosResponse<DynamicDictionary>) => {
                        if (isValidResponse(response)) {
                            return isSet(response.data.data.body.enabled) ? response.data.data.body.enabled : [];
                        } else {
                            btaBase.error.show(ErrorType.Error, 'fetchEnabledPanels', 'claims_casco_invalid_data');
                        }
                    })
                    .catch((reason: LimitedVariant) => {
                        btaBase.error.show(ErrorType.Error, 'fetchEnabledPanels', reason as DynamicDictionary);
                    })
                    .finally((): void => {
                        btaBase.popupService.hide().then();
                    });
            }

            return result;
        }

        const initialFindPanelsData = (params: DynamicDictionary): void => {
            btaBase.popupService.show(new OnePopup().withType().loading);
            useAxios().get(Url.Ajax.claimsCascoAccidentDataInitial, {params})
                .then((response: AxiosResponse<DynamicDictionary>): void => {
                    if (isValidBodyResponse(response)) {
                        buildInitialOptions(response.data.data.body);
                    } else {
                        resetToFirstPanel();
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    resetToFirstPanel();
                    btaBase.error.show(ErrorType.Error, 'initialFindPanelsData', reason as DynamicDictionary);
                })
                .finally((): void => {
                    btaBase.popupService.hide().then();
                });
        }

        const resetToFirstPanel = (): void => {
            canClearFormsAhead.value = true;
            onFormPanelInputChange(CascoClaimsPanelsNames.WhenEventHappened);
        }

        const proceedButtonIsDisabled = (panel: string, valueAsObject: boolean = false): boolean => {
            const isSelectDamageTypePanel: boolean = panel === CascoClaimsPanelsNames.SelectDamageType;
            const isPanelInvalid: boolean = isSelectDamageTypePanel ?
                !form.field(CascoClaimsPanelsNames.SelectDamageType).isValid :
                false;
            const isFormValueMissing: boolean = valueAsObject ?
                !formHasObjectValue(panel) :
                !formHasValue(panel);

            return isPanelInvalid || isFormValueMissing || isSearchInProgress.value;
        }

        const updateInitialPanelsVisibility = (): void => {
            for (const key in inputOptions) {
                if (!form.field(key).isEmpty()) {
                    applyPassedOnOption(key, true);
                    transformAndApplyValueOnOptions(key);
                    if (nextPanelFormIsNotEmpty(key)) {
                        onProceed(key, true);
                    }
                }
            }
        }

        const nextPanelFormIsNotEmpty = (panel: string): boolean => {
            return formHasValue(nextEnabledPanelName(panel));
        }

        const nextEnabledPanelName = (panel: string): string => {
            let result: string = '';
            let nextTargetPanel: boolean = false;
            Object.keys(inputOptions).forEach((key: string): void => {
                if (nextTargetPanel && inputOptions[key].enabled && !skippedPanelKey(key)) {
                    result = key;
                    nextTargetPanel = false;
                }
                if (key === panel) {
                    nextTargetPanel = true;
                }
            });
            if (hasAccidentCountryIso() && sameCountryIso() && hasSkippedRepairVehicleAbroadPanel()) {
                if (result === cascoClaimsPanelsNames.value.PleaseChooseRepairService) {
                    showNextPanel(panel);
                    initializeSkippedPanel();
                }
            }

            return result;
        }

        const skippedPanelKey = (key: string): boolean => {
            return skippedPanel.value === key;
        }

        const initializeSkippedPanel = (): void => {
            skippedPanel.value = '';
        }

        const blockOnStolenCar = (panel: string): Promise<void> => {
            let result: Promise<void> = Promise.resolve();
            if (panel === CascoClaimsPanelsNames.DamageSubtype) {
                if (formHasValue(CascoClaimsPanelsNames.DamageSubtype)) {
                    if (form.field(CascoClaimsPanelsNames.DamageSubtype).value.selected === 'STOLEN_CAR') {
                        openStolenCar();
                        result = Promise.reject();
                    }
                }
            }

            return result;
        }

        const applyResetOnOption = (option: string): void => {
            applyValueOnOption(option, '');
            applyOptionsOnOption(option, []);
            applyPassedOnOption(option, false)
            applyVisibleOnOption(option, false);
        }

        const disableAllOptions = (): void => {
            Object.keys(inputOptions).forEach((field: string): void => {
                applyEnabledOnOption(field, false);
            });
        }

        const applyPassedOnOption = (option: string, passed: boolean): void => {
            inputOptions[option].passed = passed;
        }

        const hasBodyErrorResponse = (response: DynamicDictionary): boolean => {
            return isSet(response.objects) &&
                Array.isArray(response.objects) &&
                response.objects.length > 0 &&
                isSet(response.objects[0].error);
        }

        const hasRedirectAction = (panel: string): boolean => {
            return [
                CascoClaimsPanelsNames.CountryOfAccident,
                CascoClaimsPanelsNames.PleaseChooseRepairService,
                CascoClaimsPanelsNames.DescriptionOfAccident,
            ].includes(panel);
        }

        const previousFormHasValue = (field: string): boolean => {
            let result: string = '';
            let previousField: string = '';
            let found: boolean = false;
            Object.keys(inputOptions).forEach((currentField: string): void => {
                if (inputOptions[currentField].enabled) {
                    if (currentField === field && !found) {
                        result = previousField;
                        found = true;
                    }
                    previousField = currentField;
                }
            });

            return formHasValue(result);
        }

        const openStolenCar = (): void => {
            const scrollSpeed: number = 1;
            document.getElementsByTagName('html')[0].style.scrollBehavior = 'unset';
            $('html').animate({scrollTop: 0}, scrollSpeed);
            btaBase.dynamicStepper.show(false);
            showStolenCar.value = true;
        }

        const loadStolenCarText = (): void => {
            stolenCarText.value = translateForType(stolenCarText.value, btaBase.translationType.value);
            stolenCarWhatToDo.value = translateForType(stolenCarWhatToDo.value, btaBase.translationType.value);
        }

        const skipPanel = (panel: string): boolean => {
            let skip: boolean = false;
            switch (panel) {
                case CascoClaimsPanelsNames.DoYouWantToRepairVehicleAbroad:
                    if (hasAccidentCountryIso() && sameCountryIso() && inputOptions[panel].enabled) {
                        skip = true;
                        skippedPanel.value = panel;
                    }
                    break;
                case CascoClaimsPanelsNames.CountryOfAccident:
                    if (hasAccidentCountryIso() && !sameCountryIso()) {
                        skip = true;
                        skippedPanel.value = panel;
                    }
                    break;
                default:
                    break;
            }

            return skip;
        }

        const hasSkippedRepairVehicleAbroadPanel = (): boolean => {
            return skippedPanel.value === CascoClaimsPanelsNames.DoYouWantToRepairVehicleAbroad;
        }

        const sameCountryIso = (): boolean => {
            return branchCountryIso() === accidentCountryIso.value;
        }

        const hasAccidentCountryIso = (): boolean => {
            return accidentCountryIso.value !== '';
        }

        const branchCountryIso = (): string => {
            return new AppCountry().iso();
        }

        const accidentCountryData = (): void => {
            const accidentCountry: string =
                btaBase.userStorage.stepStorageData[CascoClaimsPanelsNames.WhereAccidentHappened]?.countryIso;
            accidentCountryIso.value = (accidentCountry) ? accidentCountry : '';
        }

        const isAccidentReported = (): boolean => {
            return isSet(form.field(CascoClaimsPanelsNames.HowAccidentIsReported).value.selected) &&
                form.field(CascoClaimsPanelsNames.HowAccidentIsReported).value.selected
                    .includes(CascoClaimsMandatoryReport.Police);
        }

        const cascoClaimsPanelsNames: Ref<typeof CascoClaimsPanelsNames> = computed(() => {
            return CascoClaimsPanelsNames;
        });

        const servicesCount: Ref<string> = computed(() => {
            featuredServicesCount.value = SettingsService.getInstance().value('mapTopPlacesCount') as number;
            const replacements: DynamicDictionary = {
                '%count%': featuredServicesCount.value === 0 ? ' ' : featuredServicesCount.value
            };

            return translateForType('claims_casco_choose_accident_service_description', 'claims', replacements);
        });

        const headerOffsetForScroll: Ref<number> = computed(() => {
            const smallOffset: number = 30;
            const bigOffset: number = 130;

            return btaBase.isVerticalMode.value ? smallOffset : bigOffset;
        });

        //TODO END OF COMPOSABLE FUNCTIONS

        function onRedirect(panel: string): void {
            validatePanel(panel).then((): void => {
                prepareSubmit();
                const serviceRedirect: DynamicDictionary = repairServiceRedirect();
                const recipientDataStepNumber: number = 6;
                switch (panel) {
                    case CascoClaimsPanelsNames.RepairServiceDetails:
                        stepsSubmitter.proceedStep(claimsCascoRecipientDataUrl.value, recipientDataStepNumber);
                        break;
                    case CascoClaimsPanelsNames.CountryOfAccident:
                        stepsSubmitter.proceedStep(claimsCascoRecipientDataUrl.value, recipientDataStepNumber);
                        break;
                    case CascoClaimsPanelsNames.PleaseChooseRepairService:
                        stepsSubmitter.proceedStep(serviceRedirect.url, serviceRedirect.step);
                        break;
                    case CascoClaimsPanelsNames.HowYouWantToReceiveCompensation:
                        stepsSubmitter.proceedStep('', 0);
                        break;
                    default:
                }
            });
        }

        function repairServiceRedirect(): DynamicDictionary {
            const repairAbroadValue: DynamicDictionary | string = form
                .field(CascoClaimsPanelsNames.DoYouWantToRepairVehicleAbroad).value;
            let result: DynamicDictionary = {
                url: claimsCascoUploadFilesUrl.value,
                step: 5,
            };
            if (!!repairAbroadValue) {
                if ((repairAbroadValue as DynamicDictionary).selected === 'yes') {
                    result.url = claimsCascoRecipientDataUrl.value;
                    result.step = 6;
                } else {
                    result.url = claimsCascoContactDetailsUrl.value;
                    result.step = 7;
                }
            }

            return result;
        }

        function onProceedCompensation(panel: string): void {
            const value: DynamicDictionary = form.field(CascoClaimsPanelsNames.HowYouWantToReceiveCompensation).value;
            const mustRedirectToNextView: boolean = isSet(value.selected) &&
                (value.selected === CompensationTypeMyself || value.selected === CompensationTypeOtherService);
            mustRedirectToNextView ? onRedirect(panel) : onProceed(panel);
        }

        function applyRecipientDataUrl(url: string): void {
            claimsCascoRecipientDataUrl.value = url;
        }

        function applyContactDetailsUrl(url: string): void {
            claimsCascoContactDetailsUrl.value = url;
        }

        function applyUploadFilesUrl(url: string): void {
            claimsCascoUploadFilesUrl.value = url;
        }

        function onSuggestionsListSelect(): void {
            const width: number = btaBase.width.value;
            if (width < BreakPoints.Lg) {
                scrollToDataOffset('map-with-places', true);
            }
        }

        function visibilityRequestParams(): DynamicDictionary {
            const params: DynamicDictionary = initialRequestParams();
            Object.keys(inputOptions).forEach((field: string) => {
                params[field] = inputOptions[field].value;
            });

            return params;
        }

        function transformAndApplyValueOnOptions(field: string): void {
            const resultValue: LimitedVariant = form.field(field).value;
            applyValueOnOption(field, resultValue);
        }

        function buildInitialOptions(params: DynamicDictionary): void {
            if (isSet(params.options.enabled)) {
                enablePanels(params.options.enabled).then(() => {
                    showInitialEnabledPanels();
                });
                buildStaticOptions();
                enableFirstElementAfterDamages(params.options.enabled);
                nextTick(() => {
                    canClearFormsAhead.value = true;
                    scrollToDataOffset(lastVisiblePanel());
                    calculateStepperVisibility();
                });
            }
        }

        function enableFirstElementAfterDamages(enabled: string[]) {
            let firstFieldAfterDamages: string = '';
            let grabNextField: boolean = false;
            enabled.forEach((field: string) => {
                if (grabNextField && inputOptions[field].enabled) {
                    firstFieldAfterDamages = field;
                    grabNextField = false;
                }
                if (field === CascoClaimsPanelsNames.DamageSubtype) {
                    grabNextField = true;
                }
            });
            applyEnabledOnOption(firstFieldAfterDamages, true);
            applyVisibleOnOption(firstFieldAfterDamages, true);
        }

        function onFormPanelInputChange(field: string): void {
            clearAllValidators();
            if (canClearFormsAhead.value) {
                transformAndApplyValueOnOptions(field);
                clearFormsAhead(field);
                if (field === CascoClaimsPanelsNames.PleaseChooseRepairService) {
                    onRedirect(CascoClaimsPanelsNames.PleaseChooseRepairService)
                }
            }
        }

        function prepareOptions(field: string): Promise<void> {
            buildOptions();

            return Promise.resolve();
        }

        function calculateStepperVisibility(): void {
            const accidentDataStepNumber: number = 2;
            const vehicleStepNumber: number = 3;
            const extraDetailsStepNumber: number = 4;
            const uploadFilesStepNumber: number = 5;
            const recipientDataStepNumber: number = 6;
            const yourContactDetailsStepNumber: number = 7;
            if (isVisiblePanel(CascoClaimsPanelsNames.CountryOfAccident)) {
                btaBase.dynamicStepper.applyEnabled([
                    1,
                    accidentDataStepNumber,
                    extraDetailsStepNumber,
                    recipientDataStepNumber,
                    yourContactDetailsStepNumber,
                ]);
            } else if (isVisiblePanel(CascoClaimsPanelsNames.PleaseChooseRepairService)) {
                const steps: number[] = [
                    1,
                    accidentDataStepNumber,
                    vehicleStepNumber,
                    extraDetailsStepNumber,
                    uploadFilesStepNumber,
                    yourContactDetailsStepNumber,
                ].filter(step => step !== null) as number[];
                btaBase.dynamicStepper.applyEnabled(steps);
            } else {
                btaBase.dynamicStepper.applyEnabled(btaBase.userStorage.stepStorageData.dynamicStepper);
            }
        }

        function formHasValue(field: string): boolean {
            let result: boolean;
            switch (field) {
                case CascoClaimsPanelsNames.CountryOfAccident:
                    result = !form.field(CascoClaimsPanelsNames.CountryOfAccident).isEmpty() &&
                        form.field(CascoClaimsPanelsNames.RepairServiceDetails).value !== '';
                    break;
                case CascoClaimsPanelsNames.HowYouWantToReceiveCompensation:
                    result = receiveCompensationIsValid();
                    break;
                default:
                    result = !form.field(field).isEmpty();
            }

            return result;
        }

        function receiveCompensationIsValid(): boolean {
            let result: boolean = false;
            const value: DynamicDictionary = form.field(CascoClaimsPanelsNames.HowYouWantToReceiveCompensation).value;
            const hasValue: boolean = isSet(value.selected) && value.selected !== '';
            if (hasValue) {
                if (value.selected.includes('other')) {
                    result = value.customText.other !== '';
                } else {
                    result = true;
                }
            }

            return result;
        }

        function touchAndRequireNecessaryField(field: string): void {
            switch (field) {
                case CascoClaimsPanelsNames.CountryOfAccident:
                    form.field(field).addValidators({
                        notEmpty: () => {
                            return !form.field(field).isEmpty();
                        },
                        textFieldNotEmpty: () => {
                            return form.field(CascoClaimsPanelsNames.RepairServiceDetails).value !== '';
                        }
                    }).touch();
                    break;
                case CascoClaimsPanelsNames.HowYouWantToReceiveCompensation:
                    form.field(field).addValidators({
                        notEmpty: () => {
                            return receiveCompensationIsValid();
                        }
                    }).touch();
                    break;
                default:
                    form.field(field).addValidators({
                        notEmpty: () => {
                            return !form.field(field).isEmpty();
                        }
                    }).touch();
            }
        }


        function setupForm(): void {
            Object.keys(inputOptions).forEach((field: string) => {
                form.addField(new FormField(field));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function buildOptions(): void {
            buildStaticOptions();
            scrollToDataOffset(lastVisiblePanel());
            calculateStepperVisibility();
        }

        function buildStaticOptions(): void {
            buildRepairGlassAbroad();
            buildRepairOptions();
        }

        function buildRepairGlassAbroad(): void {
            const inputOptions: InputOption[] = [
                (new InputOptionBuilder)
                    .setValue('yes')
                    .setName(translate('btar_yes'))
                    .build(),
                (new InputOptionBuilder)
                    .setValue('no')
                    .setName(translate('btar_no'))
                    .build(),
            ];
            applyOptionsOnOption(CascoClaimsPanelsNames.DoYouWantToRepairVehicleAbroad, inputOptions);
        }

        function buildRepairOptions(): void {
            inputOptions[CascoClaimsPanelsNames.HowYouWantToReceiveCompensation].options = [];
            inputOptions[CascoClaimsPanelsNames.HowYouWantToReceiveCompensation].options.push(
                (new InputOptionBuilder())
                    .setName(translateForType('claims_casco_repair_bta', 'claims'))
                    .setValue(CompensationTypeService)
                    .build(),
                (new InputOptionBuilder())
                    .setName(translateForType('claims_casco_repair_myself', 'claims'))
                    .setValue(CompensationTypeMyself)
                    .build(),
                (new InputOptionBuilder())
                    .setName(translateForType('claims_casco_repair_other', 'claims'))
                    .setValue(CompensationTypeOtherService)
                    .setCustom(true)
                    .build()
            );
        }

        function initialRequestParams(): DynamicDictionary {
            const result: DynamicDictionary = {};
            [CascoClaimsPanelsNames.SelectDamageType, CascoClaimsPanelsNames.DamageSubtype]
                .forEach((field: string) => {
                    result[field] = btaBase.userStorage.stepStorageData[field];
                });

            return result;
        }

        function onSubmitStep(): void {
            btaBase.captcha.executeCaptcha(submit).then().catch((reason: string) => {
                Error.log(ErrorType.Error, 'onSubmitStep', reason, true);
            });
        }

        function submit(): void {
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous, '');
        }

        function prepareSubmit(): void {
            stepsSubmitter.clearSubmitter()
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            Object.keys(inputOptions).forEach((field: string): void => {
                if (inputOptions[field].enabled && formHasValue(field)) {
                    stepsSubmitter.addSubmitCustomParam(field, inputOptions[field].value as DynamicDictionary);
                } else if (isSet(singleAutoSubmitOptions.value[field])) {
                    stepsSubmitter.addSubmitCustomParam(field, singleAutoSubmitOptions.value[field]);
                }
            });
            applySingleDamageSubtype();
            if (!form.field('repair-service-details').isEmpty()) {
                stepsSubmitter.addSubmitCustomParam('repair-service-details', form.field('repair-service-details').value);
            }
            stepsSubmitter.addSubmitCustomParam('dynamicStepper', btaBase.dynamicStepper.enableStates);
        }

        function onAppReady(): void {
            accidentCountryData();
            btaBase.dynamicStepper.enableAll();
            updateInitialPanelsVisibility();
            const params: DynamicDictionary = visibilityRequestParams();
            if (initialValuesCouldBeFetched(params)) {
                initialFindPanelsData(params);
            } else {
                canClearFormsAhead.value = true;
            }
            loadStolenCarText();
        }

        function initialValuesCouldBeFetched(params: DynamicDictionary) {
            return true;
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                repairServicesFetchParams,
                cascoClaimsPanelsNames,
                inputOptions,
                canClearFormsAhead,
                customPlaceholders,
                setupForm,
                applyStepUrls,
                onSubmitStep,
                submit,
                prepareSubmit,
                onAppReady,
                onRedirect,
                onProceedCompensation,
                applyRecipientDataUrl,
                applyContactDetailsUrl,
                applyUploadFilesUrl,
                onSuggestionsListSelect,
                visibilityRequestParams,
                transformAndApplyValueOnOptions,
                buildInitialOptions,
                onFormPanelInputChange,
                isVisiblePanel,
                proceedButtonIsVisible,
                proceedButtonIsDisabled,
                onProceed,
                hasSkippedRepairVehicleAbroadPanel,
                servicesCount,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        Translations.getInstance().addType('claims');
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe(() => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    }
});
</script>
