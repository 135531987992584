<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import {computed, getCurrentInstance, onMounted, ref, Ref} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import {TranslateReplaceParts, useTranslate} from '@/Composables/Translate';
import Translations from '@/services/translations.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import {Router, useRouter} from 'vue-router';
import {InputOption} from '@/interfaces/InputOptionInterface';
import IndemnityReceiverFieldNames from '@/Apps/ClaimsMtpl/Enums/IndemnityReceiverFieldNamesEnum';
import Value from '@/assets/libraries/form/value';
import {useDefine} from '@/Composables/Define';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import Validation from '@/services/validation.service';
import AppCountry from '@/assets/libraries/app/app-country';
import {CountryComponentParams} from '@/Components/InputCountry/CountryComponentParams';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import moment from 'moment';
import GuardsService from '@/Apps/ClaimsMtpl/Services/GuardsService';
import MtplClaimsStepUid from '@/Apps/ClaimsMtpl/Enums/MtplClaimsStepUidEnum';
import OneBaseService from '@/services/OneBaseService';
import UserCredentials from '@/interfaces/user.credentials.interface';
import OneBase from '@/interfaces/OneBaseInterface';
import Sanitizer from '@/services/sanitizer.service';
import InputDateLayout from '@/Components/InputDate/InputDateLayout';

const {translate, translateForType} = useTranslate();
const {isSet} = useDefine();
const router: Router = useRouter();


const btaBase: OneBase = OneBaseService.getInstance();
const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();


const personInputOptions: Ref<InputOption[]> = ref([]);
const personTypeInputOptions: Ref<InputOption[]> = ref([]);
const residentInputOptions: Ref<InputOption[]> = ref([]);
const form: Form = new Form();


const Step: number = 5;
const PersonIsMe: string = 'me';
const PersonIsOther: string = 'other';
const PersonTypeIsPrivate: string = 'private';
const PersonTypeIsLegal: string = 'legal';
const PersonIsResident: string = 'resident';
const PersonIsNonResident: string = 'non_resident';

const dateLayout: Ref<string> = computed(() => {
    return new AppCountry().isLT() ? InputDateLayout.yearMonthDay : InputDateLayout.dayMonthYear;
});

const recipientIsOther: Ref<boolean> = computed(() => {
    return form.field(IndemnityReceiverFieldNames.Person).value === PersonIsOther;
});

const recipientIsLegal: Ref<boolean> = computed(() => {
    return form.field(IndemnityReceiverFieldNames.PersonType).value === PersonTypeIsLegal &&
        recipientIsOther.value
});

const isResident: Ref<boolean> = computed(() => {
    return form.field(IndemnityReceiverFieldNames.Resident).value === PersonIsResident;
});

const countryIsVisible: Ref<boolean> = computed(() => {
    return recipientIsOther.value && !recipientIsLegal.value && !isResident.value;
});

const otherPrivateNonResident: Ref<boolean> = computed(() => {
    return recipientIsOther.value && !recipientIsLegal.value && !isResident.value;
});

const birthDateIsVisible: Ref<boolean> = computed(() => {
    const code: LimitedVariant = form.field(IndemnityReceiverFieldNames.PersonCode).value;
    let visibleForLv: boolean = false;
    if (!new Value(code).isEmpty() &&
        Validation.isValidNaturalPersonCode(code as string) &&
        new AppCountry().isLV() &&
        recipientIsOther.value
    ) {
        const regex: RegExp = /^3[2-9].*$/;
        visibleForLv = regex.exec(code as string) !== null;
    }

    return visibleForLv || countryIsVisible.value;
});

const isCompanyTypeInputVisible: Ref<boolean> = computed(() => {
    return !(new AppCountry()).isLT();
});

const shortenElementsClassIsPresent: Ref<boolean> = computed(() => {
    return countryIsVisible.value;
});

const canProceedToNextStep: Ref<boolean> = computed(() => {
    let countryIsValid: boolean = true;
    let personNameIsValid: boolean = true;
    let personSurnameIsValid: boolean = true;
    let personCodeIsValid: boolean = true;
    let personBirthDateIsValid: boolean = true;
    let companyNameIsValid: boolean = true;
    let companyTypeIsValid: boolean = true;
    let companyRegistrationNumberIsValid: boolean = true;
    if (recipientIsOther.value) {
        if (recipientIsLegal.value) {
            companyNameIsValid = form.field(IndemnityReceiverFieldNames.CompanyName).isValid &&
                form.field(IndemnityReceiverFieldNames.CompanyName).value !== '';
            companyTypeIsValid = isCompanyTypeInputVisible.value
                ? !form.field(IndemnityReceiverFieldNames.CompanyType).isEmpty()
                : true;
            companyRegistrationNumberIsValid = form.field(IndemnityReceiverFieldNames.RegistrationNumber).isValid &&
                form.field(IndemnityReceiverFieldNames.RegistrationNumber).value !== '';
        } else {
            if (isResident.value) {
                personNameIsValid = form.field(IndemnityReceiverFieldNames.Name).isValid;
                personSurnameIsValid = form.field(IndemnityReceiverFieldNames.Surname).isValid;
                personCodeIsValid = form.field(IndemnityReceiverFieldNames.PersonCode).isValid;
                if (birthDateIsVisible.value) {
                    personBirthDateIsValid = form.field(IndemnityReceiverFieldNames.BirthDate).isValid;
                }
            } else {
                personNameIsValid = form.field(IndemnityReceiverFieldNames.Name).isValid;
                personSurnameIsValid = form.field(IndemnityReceiverFieldNames.Surname).isValid;
                personCodeIsValid = form.field(IndemnityReceiverFieldNames.PersonCode).isValid;
                personBirthDateIsValid = form.field(IndemnityReceiverFieldNames.BirthDate).isValid;
                countryIsValid = form.field(IndemnityReceiverFieldNames.CountryOfResident).isValid;
            }
        }
    }
    const emailIsValid: boolean = form.field(IndemnityReceiverFieldNames.Email).isValid;
    const phoneIsValid: boolean = form.field(IndemnityReceiverFieldNames.MobilePhone).isValid;
    const bankAccountNumberIsValid: boolean = form.field(IndemnityReceiverFieldNames.BankAccountNumber).isValid;
    const result: boolean = countryIsValid &&
        personNameIsValid &&
        personSurnameIsValid &&
        personCodeIsValid &&
        personBirthDateIsValid &&
        companyNameIsValid &&
        companyTypeIsValid &&
        companyRegistrationNumberIsValid &&
        emailIsValid &&
        phoneIsValid &&
        bankAccountNumberIsValid;
    GuardsService.getInstance().applyStepValidity(Step, result, false);

    return result;
});


function prepare(): void {
    personInputOptions.value = [
        (new InputOptionBuilder)
            .setValue(PersonIsMe)
            .setName(localized(PersonIsMe))
            .build(),
        (new InputOptionBuilder)
            .setValue(PersonIsOther)
            .setName(localized(PersonIsOther))
            .build(),
    ];
    personTypeInputOptions.value = [
        (new InputOptionBuilder)
            .setValue(PersonTypeIsPrivate)
            .setName(localized(PersonTypeIsPrivate))
            .build(),
        (new InputOptionBuilder)
            .setValue(PersonTypeIsLegal)
            .setName(localized(PersonTypeIsLegal))
            .build(),
    ];
    residentInputOptions.value = [
        (new InputOptionBuilder)
            .setValue(PersonIsResident)
            .setName(localized(PersonIsResident))
            .build(),
        (new InputOptionBuilder)
            .setValue(PersonIsNonResident)
            .setName(localized(PersonIsNonResident))
            .build(),
    ];
}

function setupForm(): void {
    const validators: (string | Record<string, any>)[] = [
        '',
        '',
        '',
        'required',
        nameValidator(),
        surnameValidator(),
        personCodeValidator(),
        birthDateValidator(),
        'required',
        'required',
        'required',
        companyNameValidator(),
        'required',
        companyRegistrationNumberValidator()
    ];
    const sanitizers: (string | Record<string, any>)[] = [
        '',
        '',
        '',
        '',
        Sanitizer.cleanName,
        Sanitizer.cleanName,
        clearPersonCode,
        '',
        '',
        '',
        '',
        '',
        '',
        Sanitizer.cleanCompanyRegistrationNumber,
    ];
    Object.keys(IndemnityReceiverFieldNames).forEach((field: string, index: number) => {
        form.addField(new FormField(
            IndemnityReceiverFieldNames[field as keyof IndemnityReceiverFieldNames],
            '',
            validators[index],
            sanitizers[index]
        ));
    });
    form.setReady();
}

function clearPersonCode(value: string): string {
    let result: string = value;
    if (isResident.value) {
        result = Sanitizer.clearPersonCode(value);
    }

    return result;
}


function nameValidator(): object {
    return {
        nameIsValid: (value: string) => {
            return form.field(IndemnityReceiverFieldNames.Name).isTouched ?
                Validation.isValidCaption(value) : true;
        }
    }
}

function surnameValidator(): object {
    return {
        surnameIsValid: (value: string) => {
            return form.field(IndemnityReceiverFieldNames.Surname).isTouched ?
                Validation.isValidCaption(value) : true;
        }
    }
}

function companyNameValidator(): object {
    return {
        surnameIsValid: (value: string) => {
            return form.field(IndemnityReceiverFieldNames.CompanyName).isTouched ?
                Validation.isValidCaption(value) : true;
        }
    }
}

function companyRegistrationNumberValidator(): object {
    return {
        companyRegistrationNumberIsValid: (value: string) => {
            return form.field(IndemnityReceiverFieldNames.RegistrationNumber).isTouched ?
                Validation.isValidCompanyRegistrationNumber(value) : true;
        }
    }
}

function personCodeValidator(): object {
    return {
        personCodeIsValid: (value: string) => {
            let result: boolean = true;
            if (recipientIsOther.value) {
                if (isResident.value) {
                    result = Validation.isValidNaturalPersonCode(value);
                } else {
                    const country: CountryComponentParams =
                        form.field(IndemnityReceiverFieldNames.CountryOfResident).value;
                    result = Validation.isValidNaturalPersonCode(value, country.iso);
                }
            }

            return result;
        }
    }
}

function birthDateValidator(): object {
    return {
        birthDateIsValid: (value: string) => {
            let result: boolean = true;
            if (birthDateIsVisible.value && recipientIsOther.value) {
                const dateFromForm: Date | '' = form.field(IndemnityReceiverFieldNames.BirthDate).value;
                if (dateFromForm !== '') {
                    result = moment(dateFromForm).isValid();
                }
            }

            return result;
        }
    }
}

function storeFormToService(): void {
    Object.keys(IndemnityReceiverFieldNames).forEach((field: string, index: number) => {
        const key: string = IndemnityReceiverFieldNames[field as keyof IndemnityReceiverFieldNames];
        claimsMtplService.fields[key as keyof ClaimsMtplFormFields] = form.field(key).value;
    });
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = claimsMtplService.fields;
    Object.keys(IndemnityReceiverFieldNames).forEach((field: string) => {
        const keyName: string = IndemnityReceiverFieldNames[field as keyof IndemnityReceiverFieldNames];
        if (isSet(storedValues[keyName]) && new Value(storedValues[keyName]).isNotEmpty()) {
            form.field(keyName).setValue(storedValues[keyName]);
        }
    });
}

function applyDefaultValues(): void {
    if (new Value(form.field(IndemnityReceiverFieldNames.Person).value).isEmpty()) {
        form.field(IndemnityReceiverFieldNames.Person).patch(PersonIsMe);
    }
    if (new Value(form.field(IndemnityReceiverFieldNames.PersonType).value).isEmpty()) {
        form.field(IndemnityReceiverFieldNames.PersonType).patch(PersonTypeIsPrivate);
    }
    if (new Value(form.field(IndemnityReceiverFieldNames.Resident).value).isEmpty()) {
        form.field(IndemnityReceiverFieldNames.Resident).patch(PersonIsResident);
    }
}

function prefillValues(): void {
    prefillPersonBlock();
}

function localized(stringUid: string, replaceParts?: TranslateReplaceParts): string {
    return translateForType(stringUid, Translations.getInstance().type, replaceParts);
}

function proceedButton(): DynamicDictionary {
    return {
        title: localized('claims_mtpl_proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function onPersonChange(type: string): void {
    if (type === PersonIsMe) {
        prefillPersonBlock();
    } else {
        resetPrefill();
    }
}

function onPersonTypeChange(type: string): void {
    form.field(IndemnityReceiverFieldNames.Resident).patch(PersonIsResident);
}

function prefillPersonBlock(): void {
    if (!recipientIsOther.value) {
        const currentUser: UserCredentials = btaBase.user.current;
        form.field(IndemnityReceiverFieldNames.Name).patch(currentUser.firstname);
        form.field(IndemnityReceiverFieldNames.Surname).patch(currentUser.lastname);
        form.field(IndemnityReceiverFieldNames.PersonCode).patch(currentUser.personCode);
        form.field(IndemnityReceiverFieldNames.Email).patch(currentUser.email);
        form.field(IndemnityReceiverFieldNames.MobilePhone).patch({
            country: currentUser.phoneCode,
            phone: currentUser.phone
        });
        form.field(IndemnityReceiverFieldNames.BankAccountNumber).patch(currentUser.bank);
    }
}

function resetPrefill(): void {
    form.field(IndemnityReceiverFieldNames.Name).clear();
    form.field(IndemnityReceiverFieldNames.Surname).clear();
    form.field(IndemnityReceiverFieldNames.PersonCode).clear();
    form.field(IndemnityReceiverFieldNames.Email).clear();
    form.field(IndemnityReceiverFieldNames.MobilePhone).clear();
    form.field(IndemnityReceiverFieldNames.BankAccountNumber).clear();
}

function proceedToNextStep(): void {
    storeFormToService();
    router.push({name: 'claims-mtpl-your-contact-details'});
}


onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    prepare();
    setupForm();
    restoreValues();
    applyDefaultValues();
    prefillValues();
});

defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form
            v-if="form.isReady()"
            :form="form"
            @change="storeFormToService()"
            class="form">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <router-link class="back back-margin"
                             :to="{name: MtplClaimsStepUid.UploadFiles}"
                             :tag="'button'"
                             :disabled="!form.isValid()">
                    <img src="images/one/arrow-left.svg" alt="back">
                    <span>{{ translate('back_button') }}</span>
                </router-link>
                <label>{{ localized('mtpl_claim_indemnity_receiver') }}</label>
                <div class="whiteboard">
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ localized('mtpl_claim_indemnity_receiver_data') }}</h4>
                            <div class="person-buttons">
                                <app-input-radio-overlayed :form-field="form.field(IndemnityReceiverFieldNames.Person)"
                                                           :options="personInputOptions"
                                                           @change="onPersonChange($event)"
                                ></app-input-radio-overlayed>
                                <app-input-radio-overlayed v-if="recipientIsOther"
                                                           :form-field="form.field(IndemnityReceiverFieldNames.PersonType)"
                                                           :options="personTypeInputOptions"
                                                           @change="onPersonTypeChange($event)"
                                ></app-input-radio-overlayed>
                                <app-input-radio-overlayed v-if="recipientIsOther && !recipientIsLegal"
                                                           :form-field="form.field(IndemnityReceiverFieldNames.Resident)"
                                                           :options="residentInputOptions"
                                ></app-input-radio-overlayed>
                            </div>
                            <app-input-country class="country"
                                               v-if="countryIsVisible"
                                               :include-default-country="false"
                                               :patch-default-country="false"
                                               :placeholder="localized('select_country_of_residence')"
                                               :label="localized('country_of_residence')"
                                               :form-field="form.field(IndemnityReceiverFieldNames.CountryOfResident)"
                            ></app-input-country>
                            <div class="person-block"
                                 :class="{'shorten' : shortenElementsClassIsPresent}"
                                 v-if="(!recipientIsLegal && !countryIsVisible) || otherPrivateNonResident">
                                <app-input-text :disabled="!recipientIsOther"
                                                :label="localized('name')"
                                                :placeholder="localized('enter_name')"
                                                :form-field="form.field(IndemnityReceiverFieldNames.Name)"
                                ></app-input-text>
                                <app-input-text :disabled="!recipientIsOther"
                                                :label="localized('surname')"
                                                :placeholder="localized('enter_surname')"
                                                :form-field="form.field(IndemnityReceiverFieldNames.Surname)"
                                ></app-input-text>
                                <app-input-text :disabled="!recipientIsOther"
                                                :label="localized('person_code')"
                                                :placeholder="localized('enter_person_code')"
                                                :form-field="form.field(IndemnityReceiverFieldNames.PersonCode)"
                                ></app-input-text>
                                <app-input-date class="input-full input-date"
                                                v-if="birthDateIsVisible && recipientIsOther"
                                                :label="localized('birth_date')"
                                                :placeholder="localized('enter_birth_date')"
                                                :delimiter="'-'"
                                                :info-message="localized('date_format')"
                                                :layout="dateLayout"
                                                :validate-birth-date="true"
                                                :form-field="form.field(IndemnityReceiverFieldNames.BirthDate)"></app-input-date>
                            </div>
                            <div class="legal-block"
                                 v-if="(recipientIsLegal || countryIsVisible) && !otherPrivateNonResident">
                                <app-input-text :disabled="!recipientIsOther"
                                                :label="localized('company_name')"
                                                :placeholder="localized('enter_company_name')"
                                                :form-field="form.field(IndemnityReceiverFieldNames.CompanyName)"
                                ></app-input-text>
                                <app-input-company-type v-if="isCompanyTypeInputVisible"
                                                        :label="localized('company_type')"
                                                        :placeholder="localized('enter_company_type')"
                                                        :form-field="form.field(IndemnityReceiverFieldNames.CompanyType)"
                                ></app-input-company-type>
                                <app-input-text :disabled="!recipientIsOther"
                                                :label="localized('registration_number')"
                                                :placeholder="localized('enter_registration_number')"
                                                :form-field="form.field(IndemnityReceiverFieldNames.RegistrationNumber)"
                                ></app-input-text>
                            </div>
                            <div class="contact-block">
                                <app-input-email :label="localized('email')"
                                                 :placeholder="localized('enter_email')"
                                                 :form-field="form.field(IndemnityReceiverFieldNames.Email)"
                                                 :disable-error-text="true"
                                ></app-input-email>
                                <app-phone-with-country :label="localized('phone')"
                                                        :form-field="form.field(IndemnityReceiverFieldNames.MobilePhone)"
                                                        :placeholder="localized('enter_phone')"
                                                        :disable-error-text="true"
                                ></app-phone-with-country>
                                <app-input-bank-account :label="localized('bank_account')"
                                                        :placeholder="localized('enter_bank_account')"
                                                        :form-field="form.field(IndemnityReceiverFieldNames.BankAccountNumber)"
                                ></app-input-bank-account>
                            </div>
                        </div>
                    </div>
                    <app-button-with-callback class="button"
                                              data-type="indemnity_submit"
                                              v-bind="proceedButton()"
                                              :disabled="!canProceedToNextStep"
                                              v-on:button-callback-click="proceedToNextStep()"
                    ></app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    .button {
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .person-buttons {
        display: flex;
        flex-direction: column;
        gap: var(--size-small);
        margin-bottom: var(--size-big);

        :deep(.input-radio-overlayed) {
            .buttons {
                .overlay-button {
                    padding: var(--size-tiny);
                    width: 50%;
                }
            }
        }

        @include respond-below('sm') {
            margin-bottom: var(--size-tiny);
        }
    }

    .country {
        width: 100%;
    }

    .person-block {
        flex-wrap: wrap;

        > .input {
            flex: 1;
            width: 33%;
        }

        &.shorten {
            > .input,
            .input-date {
                max-width: calc(50% - 10px);
                flex-basis: 50%;
                flex-grow: initial;
                flex-shrink: initial;
            }
        }
    }

    .person-block,
    .legal-block {
        margin-top: var(--size-small);
        display: flex;
        gap: var(--size-small);

        .input {
            flex: 1;

            @include respond-below('sm') {
                width: 100%;
                flex-basis: 100%;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }

        .input-full {
            flex-basis: 100%;
        }

        :deep(.input-date) {
            .wrapper {
                justify-content: flex-start;
                min-height: 52px;
                padding: 0 30px 0 20px;
            }
        }
    }

    .contact-block {
        display: flex;
        gap: var(--size-small);
        margin-top: var(--size-small);

        @include respond-below('sm') {
            flex-direction: column;
        }

        > .input {
            flex: 1;
            width: 33%;

            @include respond-below('sm') {
                width: 100%;
                flex-basis: 100%;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
    }

    :deep(.input-country) {
        .label p {
            color: var(--text-color-default);
            font-weight: 600;
        }
    }

    .person-block,
    .legal-block,
    .contact-block {
        :deep(.input) {
            .label p,
            .label label {
                color: var(--text-color-default);
                font-weight: 600;
            }
        }
    }
}
</style>
