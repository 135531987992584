<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import {getCurrentInstance, onMounted} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import {useTranslate} from '@/Composables/Translate';
import YourContactDetailsPanelsNamesEnum from '@/Apps/ClaimsMtpl/Enums/YourContactDetailsPanelsNamesEnum';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import UserCredentials from '@/interfaces/user.credentials.interface';
import Translations from '@/services/translations.service';
import Url from '@/Enums/UrlEnum';
import {useAxios} from '@/Composables/Axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import InsuredPerson from '@/interfaces/insured.person.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import moment from 'moment/moment';
import PersonCodeValidator from '@/Validators/PersonCodeValidator';
import MtplClaimsStepUid from '@/Apps/ClaimsMtpl/Enums/MtplClaimsStepUidEnum';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {useNavigate} from '@/Composables/Navigate';
import {AxiosPromise} from 'axios';
import OneDate from '@/assets/libraries/Date/OneDate';

const {translate, translateForType} = useTranslate();
const stepsSubmitter = useStepsSubmitter();

const btaBase: OneBase = OneBaseService.getInstance();
const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();

const form: Form = new Form();

const claimType: string = 'mtpl';
const uploader: string = 'claims-mtpl';
const thankYouStepFacility: string = 'claims-thank-you';
const contactDetailsPanelId: string = 'contact-details';


function setupForm(): void {
    form.addField(new FormField(YourContactDetailsPanelsNamesEnum.Email));
    form.addField(new FormField(YourContactDetailsPanelsNamesEnum.PhoneNumber));
    form.setReady();
}

function restoreValues(): void {
    const currentUser: UserCredentials = btaBase.user.current;
    if (claimsMtplService.fields.contactEmail) {
        form.field(YourContactDetailsPanelsNamesEnum.Email).setValue(claimsMtplService.fields.contactEmail as LimitedVariant);
    } else {
        form.field(YourContactDetailsPanelsNamesEnum.Email).setValue(currentUser.email as LimitedVariant);
    }
    if (claimsMtplService.fields.contactPhoneNumber) {
        form.field(YourContactDetailsPanelsNamesEnum.PhoneNumber).setValue(claimsMtplService.fields.contactPhoneNumber as LimitedVariant);
    } else {
        form.field(YourContactDetailsPanelsNamesEnum.PhoneNumber).setValue({
            country: currentUser.phoneCode,
            phone: currentUser.phone
        } as LimitedVariant);
    }
}

function storeFormToService(): void {
    claimsMtplService.fields.contactEmail = form.field(YourContactDetailsPanelsNamesEnum.Email).value;
    claimsMtplService.fields.contactPhoneNumber = form.field(YourContactDetailsPanelsNamesEnum.PhoneNumber).value;
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function serviceFieldValues(): any {
    return Object.keys(claimsMtplService.fields)
        .reduce((accumulator: DynamicDictionary, field: string): DynamicDictionary => {
            accumulator[field] = fieldHandler(field)(claimsMtplService.fields[field as keyof ClaimsMtplFormFields]);

            return accumulator;
        }, {});
}

function fieldHandler(field: string): any {
    let handler: (input: any) => any;
    switch (field) {
        case 'whenEventHappened':
            handler = dateWithoutTime;
            break;
        case 'typeOfCompensation':
            handler = btaBase.settings.localeIso() === 'EE' && claimsMtplService.isExtraDetailsPageVisible()
                ? estoniaSpecificTypeOfCompensation
                : defaultHandler
            break;
        default:
            handler = defaultHandler;
    }

    return handler;
}

function dateWithoutTime(date: DynamicDictionary): DynamicDictionary {
    date.startDate = date.startDate.substring(0, 10);

    return date;
}

function estoniaSpecificTypeOfCompensation(field: DynamicDictionary|string): DynamicDictionary {
    field = {};
    field.selected = 'service';

    return field;
}

function defaultHandler(fieldValue: any): any {
    return fieldValue;
}

function applicant(): InsuredPerson {
    return {
        identityNumber: btaBase.user.current.personCode,
        firstName: btaBase.user.current.firstname,
        lastName: btaBase.user.current.lastname,
        companyName: '',
        companyType: '',
        registrationNumber: '',
        isLegal: false,
        isResident: true,
        residenceCountryIso: new AppCountry().iso(),
        birthDate: btaBase.user.current.birthDate,
        email: form.field(YourContactDetailsPanelsNamesEnum.Email).value,
        phoneCode: form.field(YourContactDetailsPanelsNamesEnum.PhoneNumber).value.country,
        phone: form.field(YourContactDetailsPanelsNamesEnum.PhoneNumber).value.phone,
        bankAccount: btaBase.user.current.bank
    }
}

function beneficiary(): InsuredPerson {
    return {
        identityNumber: claimsMtplService.fields.personCode,
        firstName: claimsMtplService.fields.name,
        lastName: claimsMtplService.fields.surname,
        companyName: claimsMtplService.fields.companyName,
        companyType: claimsMtplService.fields.companyType,
        registrationNumber: claimsMtplService.fields.registrationNumber,
        isLegal: claimsMtplService.fields.personType === 'legal',
        isResident: claimsMtplService.fields.resident === 'resident',
        residenceCountryIso: residenceCountryIso(),
        birthDate: beneficiaryBirthDate(),
        email: claimsMtplService.fields.email,
        phoneCode: claimsMtplService.fields.mobilePhone!.country,
        phone: claimsMtplService.fields.mobilePhone!.phone,
        bankAccount: claimsMtplService.fields.bankAccountNumber,
    }
}

function residenceCountryIso(): string {
    return claimsMtplService.fields.resident === 'resident'
        ? (new AppCountry()).iso()
        : claimsMtplService.fields.countryOfResident!.iso;
}

function beneficiaryBirthDate(): string {
    return claimsMtplService.fields.birthDate !== ''
        ? moment(claimsMtplService.fields.birthDate).format()
        : '';
}

function driverIsApplicant(): boolean {
    return claimsMtplService.fields.vehicleVehicleDriverIsApplicant!.selected === 'yes';
}

function driverIsResident(): boolean {
    return claimsMtplService.fields.vehicleVehicleDriverResident! === 'Y';
}

function showDriverResidentBirthDate(): boolean {
    return (new AppCountry).isLV()
        ? (new PersonCodeValidator()).isValidPersonCodeWithoutDate(
            claimsMtplService.fields.vehicleResidentDriverPersonCode ?? '',
            (new AppCountry).iso()
        )
        : false;
}

function driver(): InsuredPerson {
    const driver: InsuredPerson = {
        isLegal: false,
        isResident: true,
    }
    if (driverIsApplicant()) {
        driver.identityNumber = btaBase.user.current.personCode;
        driver.firstName = btaBase.user.current.firstname;
        driver.lastName = btaBase.user.current.lastname;
    } else {
        if (driverIsResident()) {
            driver.firstName = claimsMtplService.fields.vehicleResidentDriverName;
            driver.lastName = claimsMtplService.fields.vehicleResidentDriverSurname;
            driver.identityNumber = claimsMtplService.fields.vehicleResidentDriverPersonCode;
            if (showDriverResidentBirthDate()) {
                driver.birthDate = OneDate.iris(claimsMtplService.fields.vehicleResidentDriverBirthDate!);
            }
        } else {
            driver.firstName = claimsMtplService.fields.vehicleNonResidentDriverName;
            driver.lastName = claimsMtplService.fields.vehicleNonResidentDriverSurname;
            driver.birthDate = OneDate.iris(claimsMtplService.fields.vehicleNonResidentDriverBirthDate!);
            driver.driversLicense = claimsMtplService.fields.vehicleNonResidentDriverLicenseNumber;
            driver.isResident = false;
        }
    }

    return driver;
}

function includeDriverField(): boolean {
    return claimsMtplService.fields.whatWasDamaged!.selected.includes('vehicle');
}

function includeBeneficiaryField(): boolean {
    return claimsMtplService.isIndemnityReceiverPageVisible();
}

function submitClaimParams(): Record<string, string> {
    return {
        ...serviceFieldValues(),
        'claimType': claimType,
        'uploader': uploader,
        'applicant': applicant(),
        ...(includeBeneficiaryField() ? {'beneficiary': beneficiary()} : {}),
        ...(includeDriverField() ? {'driver': driver()} : {}),
    }
}

function prepareStepSubmit(): void {
    stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep())
    stepsSubmitter.addSubmitCustomParam('facility', btaBase.facility())
    if (claimsMtplService.fields.repairService) {
        stepsSubmitter.addSubmitParam('please-choose-repair-service', claimsMtplService.fields.repairService, true);
    }
    if (claimsMtplService.fields.vehicleVehicleIsDrivable) {
        stepsSubmitter.addSubmitParam('is-your-vehicle-in-drivable-condition', claimsMtplService.fields.vehicleVehicleIsDrivable, true);
    }
}

function onProceed(): void {
    storeFormToService();
    prepareStepSubmit();
    const thankYouStepNumber: number = 9;
    claimsMtplService.lockFormStorage();
    stepsSubmitter.saveParamsToStorageWithStep(thankYouStepNumber, thankYouStepFacility)
        .finally((): AxiosPromise<DynamicDictionary> => useAxios().post(Url.Ajax.claimsMtplSubmitClaim, submitClaimParams()))
        .catch((): void => {
            claimsMtplService.unlockFormStorage();
        })
        .then((): void => {
            useNavigate().navigate(claimsMtplService.thankYouStepUrl);
        });
}

function submitClaimButton(): ButtonWithCallbackParams {
    return {
        title: localized('submit_claim'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right
    };
}


onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    setupForm();
    restoreValues();
});

defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form
            v-if="form.isReady()"
            :form="form"
            @change="storeFormToService()"
            class="form">
            <div class="whiteboard-panel whiteboard-panel-margin"
                :data-scroll="contactDetailsPanelId">
                <router-link
                    class="back back-margin"
                    :to="{name: claimsMtplService.isIndemnityReceiverPageVisible() ?
                        MtplClaimsStepUid.IndemnityReceiver :
                        MtplClaimsStepUid.UploadFiles}"
                    :tag="'button'"
                    :disabled="!form.isValid()">
                    <img src="images/one/arrow-left.svg" alt="back">
                    <span>{{ translate('back_button') }}</span>
                </router-link>
                <label>{{ localized('your_contact_details_title') }}</label>
                <div class="whiteboard">
                    <div class="input-row">
                        <app-input-email
                            :form-field="form.field(YourContactDetailsPanelsNamesEnum.Email)"
                            :label="localized('contact_email_label')"
                            :placeholder="localized('contact_email_placeholder')">
                        </app-input-email>
                        <app-phone-with-country
                            :form-field="form.field(YourContactDetailsPanelsNamesEnum.PhoneNumber)"
                            :label="localized('contact_phone_number_label')"
                            :placeholder="localized('contact_phone_number_placeholder')"
                        ></app-phone-with-country>
                    </div>
                    <app-button-with-callback class="button"
                                              data-type="contact-details-submit"
                                              :disabled="!form.isValid()"
                                              v-bind="submitClaimButton()"
                                              @button-callback-click="onProceed">
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    .button {
        height: 52px;
        margin-top: var(--size-medium);
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .input-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--size-small);

        @include respond-above('sm') {
            flex-direction: row;
        }

        > div {
            width: 100%;
        }
    }
}

.whiteboard {
    :deep(.input) {
        .label p,
        .label label {
            color: var(--text-color-default);
            font-weight: 600;
        }
    }
}
</style>
