export default class VehicleDamageNames {
    public static readonly VehicleRegistrationNumber: string = 'vehicleRegistrationNumber';
    public static readonly ProceedWithAgreedStatement: string = 'proceedWithAgreedStatement';
    public static readonly AgreedStatementLabel: string = 'agreedStatementLabel';
    public static readonly VehicleIsDrivable: string = 'vehicleIsDrivable';
    public static readonly VehicleDriverIsApplicant: string = 'vehicleDriverIsApplicant';
    public static readonly VehicleDriverResident: string = 'vehicleDriverResident';
    public static readonly ResidentDriverName: string = 'residentDriverName';
    public static readonly ResidentDriverSurname: string = 'residentDriverSurname';
    public static readonly ResidentDriverPersonCode: string = 'residentDriverPersonCode';
    public static readonly ResidentDriverBirthDate: string = 'residentDriverBirthDate';
    public static readonly NonResidentDriverName: string = 'nonResidentDriverName';
    public static readonly NonResidentDriverSurname: string = 'nonResidentDriverSurname';
    public static readonly NonResidentDriverBirthDate: string = 'nonResidentDriverBirthDate';
    public static readonly NonResidentDriverLicenseNumber: string = 'nonResidentDriverLicenseNumber';
    public static readonly VehicleDamagedBeforeAccident: string = 'vehicleDamagedBeforeAccident';
    public static readonly DamageBeforeAccident: string = 'damageBeforeAccident';
}
