<script lang="ts">
import { computed, defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from '@/Composables/Navigate';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import FormField from '@/assets/libraries/form/form-field';
import User from '@/services/user.service';
import { Subscription } from 'rxjs';
import UserCredentials from '@/interfaces/user.credentials.interface';
import OneDate from '@/assets/libraries/Date/OneDate';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import moment from 'moment';
import Sanitizer from '@/services/sanitizer.service';
import Validation from '@/services/validation.service';
import ExtractDataService from '@/services/extract.data.service';
import UserStorage from '@/services/user.storage.service';
import SettingsService from '@/services/settings.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const extractDataService: ExtractDataService = new ExtractDataService();

        const { translate } = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 2;
        const Facility: string = 'bicycle_policy';
        const FormFields: string[] = [
            'insuredObject',
            'policyHolderIsAuthenticated',
            'policyHolderIsResident',
            'policyHolderFirstName',
            'policyHolderLastName',
            'policyHolderIdentityNumber',
            'policyHolderCountry',
            'policyHolderBirthDate',
        ];

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const settingsService: SettingsService = SettingsService.getInstance();

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        const isAuthenticated: Ref<boolean> = computed(() => {
            return form.field('policyHolderIsAuthenticated').value;
        });

        const isResident: Ref<boolean> = computed(() => {
            return form.field('policyHolderIsResident').value;
        });

        const isLithuanian: Ref<boolean> = computed(() => {
            return form.field('policyHolderCountry').value.iso === 'LT';
        });

        const isEstonian: Ref<boolean> = computed(() => {
            return form.field('policyHolderCountry').value.iso === 'EE';
        });

        const isAnonymousNumber: Ref<boolean> = computed(() => {
            return isResident.value && Validation.isValidPersonCodeWithoutDate(
                form.field('policyHolderIdentityNumber').value
            );
        });

        const birthDateRequired: Ref<boolean> = computed(() => {
            return isAnonymousNumber.value || requiresBirthDate();
        });

        function setupForm(): void {
            FormFields.forEach((field: string): void => {
                form.addField(new FormField(field));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function onAppReady(): void {
            applyFormValidationAndSanitization();
            if (isAuthenticatedUser() && form.field('policyHolderIsAuthenticated').value) {
                patchUserFields();
            }
        }

        function applyFormValidationAndSanitization(): void {
            form.field('policyHolderFirstName')
                .addValidators(captionValidator('policyHolderFirstName'));
            form.field('policyHolderLastName')
                .addValidators(captionValidator('policyHolderLastName'));
            form.field('policyHolderIdentityNumber')
                .addValidators(identityNumberValidator());
            form.field('policyHolderFirstName').addSanitizer(Sanitizer.cleanUppercaseName);
            form.field('policyHolderLastName').addSanitizer(Sanitizer.cleanUppercaseName);
            form.field('insuredObject').addSanitizer(Sanitizer.cleanBicycleModel);
        }

        function captionValidator(assignedToField: string): object {
            const field: FormField = form.field(assignedToField);
            return {
                validCaption: (): boolean => field.isTouched ?
                    Validation.isValidCaption(field.value) : true
            }
        }

        function isAuthenticatedUser(): boolean {
            return User.getInstance().isLogged();
        }

        function requiresBirthDate(): boolean {
            return !isResident.value && !isLithuanian.value && !isEstonian.value;
        }

        function identityNumberValidator(): object {
            return {
                validIdentityNumber: (): boolean => {
                    return form.field('policyHolderIdentityNumber').isTouched
                        ? isValidIdentityNumber()
                        : true;
                }
            }
        }

        function isValidIdentityNumber(): boolean {
            let result: boolean = true;
            if (isResident.value || isLithuanian.value || isEstonian.value) {
                const personCodeField: FormField = form.field('policyHolderIdentityNumber');
                const countryField: FormField = form.field('policyHolderCountry');
                const personIsResident: boolean = form.field('policyHolderIsResident').value;
                const validationResult: boolean = personIsResident ?
                    Validation.isValidNaturalPersonCode(personCodeField.value) :
                    Validation.isValidNaturalPersonCode(personCodeField.value, countryField.value.iso);
                result = validationResult && personCodeField.isNotEmpty();
            }

            return result;
        }

        function onAuthenticatedClick(): void {
            if (isAuthenticated.value) {
                patchUserFields();
            } else {
                patchGuestFields();
            }
        }

        function patchUserFields(): void {
            const currentUser: UserCredentials = btaBase.user.current;
            form.field('policyHolderFirstName').patch(currentUser.firstname);
            form.field('policyHolderLastName').patch(currentUser.lastname);
            form.field('policyHolderIdentityNumber').setValue(currentUser.personCode);
            patchDefaultHolderCountry();
        }

        function patchGuestFields(): void {
            form.field('policyHolderFirstName').clear();
            form.field('policyHolderLastName').clear();
            form.field('policyHolderIdentityNumber').clear();
        }

        function patchDefaultHolderCountry(): void {
            form.field('policyHolderCountry').patch({
                ic: '100000000',
                iso: settingsService.localeIso(),
                phoneCode: settingsService.phoneCode(),
            });
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function insuredPerson(): DynamicDictionary {
            const holderBirthDateField: FormField = form
                .field('policyHolderBirthDate');

            return {
                firstName: form.field('policyHolderFirstName').value,
                lastName: form.field('policyHolderLastName').value,
                personCode: form.field('policyHolderIdentityNumber').value,
                country: form.field('policyHolderCountry').value,
                birthDate: holderBirthDateField.isNotEmpty() ? OneDate.iris(
                    new Date(holderBirthDateField.value)
                ) : holderBirthDateField.value,
                isResident: form.field('policyHolderIsResident').value,
            };
        }

        function birthDate(): string {
            return isAuthenticated.value ? userDate() : guestDate();
        }

        function userDate(): string {
            return OneDate.iris(btaBase.user.current.birthDate);
        }

        function guestDate(): string {
            return birthDateRequired.value ? formFieldDate() : identityNumberDate();
        }

        function formFieldDate(): string {
            return OneDate.iris(moment(form.field('policyHolderBirthDate').value).toDate());
        }

        function identityNumberDate(): string {
            const extractedBirthDate: Date | undefined = extractDataService.birthDateFromPersonCode(
                form.field('policyHolderIdentityNumber').value,
                form.field('policyHolderCountry').value.iso
            );

            return extractedBirthDate ? OneDate.iris(extractedBirthDate) : '';
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function onSubmitStep(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            form.validate().then((): void => {
                if (form.isValid()) {
                    stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
                    stepsSubmitter.addSubmitCustomParam('facility', Facility);
                    stepsSubmitter.addSubmitCustomParam('productId', selectedProductId());
                    stepsSubmitter.addSubmitCustomParam('model', form.field('insuredObject').value);
                    stepsSubmitter.addSubmitCustomParam('insuredPerson', insuredPerson());
                    stepsSubmitter.proceedStep('', 0);
                } else {
                    const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                    if (invalidElements.length > 0) {
                        invalidElements[0].scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'center'
                        });
                    }
                }
            });
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
                field.markAsUntouched();
            });
            if (!birthDateRequired.value) {
                form.field('policyHolderBirthDate').clear().then();
            }
        }

        function touchAndRequireNecessaryFields(): void {
            if (isAuthenticated.value) {
                touchAndRequireUserFields();
            } else {
                touchAndRequireGuestFields();
            }
        }

        function touchAndRequireUserFields(): void {
            [
                'insuredObject',
            ].forEach((field: string): void => {
                form.field(field)
                    .addValidators('required')
                    .touch();
            })
        }

        function touchAndRequireGuestFields(): void {
            const guestFields: string[] = [
                'insuredObject',
                'policyHolderFirstName',
                'policyHolderLastName',
                'policyHolderIdentityNumber',
            ];
            if (!isResident.value) {
                guestFields.push('policyHolderCountry');
            }
            if (birthDateRequired.value) {
                guestFields.push('policyHolderBirthDate');
            }
            guestFields.forEach((field: string): void => {
                form.field(field)
                    .addValidators('required')
                    .touch();
            });
        }

        function hint(key: string, value: string): string {
            return useHtml().imgTag(cautionIconAttributes()) + key + ': ' + value;
        }

        function cautionIconAttributes(): ImageTagAttributes {
            return {
                class: '',
                src: 'images/one/caution.svg',
                width: 16,
                height: 16,
            }
        }

        function continueButtonParams(): ButtonWithCallbackParams {
            return {
                title: translate('btar_continue'),
                textColor: ButtonTextColor.White,
                backgroundColor: ButtonBackground.Red,
                icon: ButtonIcon.LongArrowRight,
                iconColor: ButtonIconColor.White,
                iconPosition: ButtonIconPosition.Right,
            };
        }

        function onBeforeFormRestore(): void {
            patchDefaultValues();
        }

        function patchDefaultValues(): void {
            form.field('policyHolderIdentityNumber').clearSanitizer();
            form.field('policyHolderIsResident').patch(true);
            if (isAuthenticatedUser()) {
                form.field('policyHolderIsAuthenticated').patch(true);
                patchUserFields();
            } else {
                form.field('policyHolderIsAuthenticated').patch(false);
                patchDefaultHolderCountry();
            }
        }

        function onIsResidentClick(): void {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            let residentCountry: DynamicDictionary = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            form.field('policyHolderIdentityNumber').clear();
            if (form.field('policyHolderIsResident').value) {
                patchDefaultHolderCountry();
                form.field('policyHolderCountry').patch(residentCountry);
            } else {
                form.field('policyHolderCountry').clear();
            }
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                isAuthenticated,
                isResident,
                isLithuanian,
                isEstonian,
                birthDateRequired,
                onSubmitStep,
                onBackClick,
                applyStepUrls,
                setupForm,
                hint,
                continueButtonParams,
                onBeforeFormRestore,
                onAuthenticatedClick,
                isAuthenticatedUser,
                onAppReady,
                birthDate,
                formFieldDate,
                onIsResidentClick,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm()
        this.dynamicStepper.enableAll();

        const onBeforeFormRestoredSubscription: Subscription =
            this.userStorage.onBeforeFormStorageDataIsRestored.subscribe((): void => {
                this.onBeforeFormRestore();
                onBeforeFormRestoredSubscription.unsubscribe();
            });

        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
