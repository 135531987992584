<script setup lang="ts">
import {computed, onMounted, ref, Ref, nextTick} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import Map from '@/Enums/MapEnum';
import MapControlsOptions from '@/Components/MapControls/MapControlsOptions';
import MapStorage from '@/interfaces/map.storage.interface';
import MapPlace from '@/interfaces/map.place.interface';
import RepairShopService from '@/services/repair.shop.service';
import FetchParams from '@/Components/MapWithPlaces/FetchParams';
import FilterParams from '@/interfaces/filter.params.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import InputTagIcon from '@/Components/InputText/InputTagIcon';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import Popup from '@/services/popup.service';
import PopupType from '@/Enums/PopupTypeEnum';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useMaps} from '@/Composables/Maps';
import {MarkerClusterer} from '@googlemaps/markerclusterer';
import {useDefine} from '@/Composables/Define';
import {useStrings} from '@/Composables/Strings';
import {useTranslate} from '@/Composables/Translate';
import AppMapControls from '@/Components/MapControls/MapControls.vue';
import AppInputText from '@/Components/InputText/InputText.vue';
import AppPopupFilter from '@/Components/PopupFilter/PopupFilter.vue';
import AppPopup from '@/Components/Popup/Popup.vue';

const props = defineProps({
    componentName: {type: String, default: 'MapWithPlaces'},
    translationType: {type: String, default: 'components'},
    formField: {type: FormField, default: () => new FormField('')},
    dataStoreDisabled: {type: Boolean, default: false},
    fetchParams: {type: FetchParams, default: () => new FetchParams()},
    fetchUrl: {type: String, default: ''},
    showSelectPlaceButton: {type: Boolean, default: true},
    startWithAllPlaces: {type: Boolean, default: false},
});
const emit = defineEmits(['change', 'list-select', 'map-ready']);
const {mapLoader, createMap, createClusterer, placeIcon, changeBasemap, showTopPlaces, mapSettings} = useMaps();
const {isSet} = useDefine();
const {diacriticString} = useStrings();
const {translateForType} = useTranslate();
const leftIcon: InputTagIcon = {
    name: 'search',
    cssClass: 'black-to-grey',
};
const rightIcon: InputTagIcon = {
    name: 'reset_input',
    cssClass: 'input-reset',
};
const options: MapControlsOptions = new MapControlsOptions();
options.zoomControl = true;
options.homeControl = true;
options.geolocateControl = true;
options.basemapControl = true;
const searchTerm: Ref<string> = ref('');
const filters: Ref<FilterParams[]> = ref([]);
const places: Ref<MapPlace[]> = ref([]);
const showingPlaces: Ref<MapPlace[]> = ref([]);
const topPlaces: Ref<number> = computed((): number => {
    return showTopPlaces;
});
const selectedFilters: Ref<FilterParams[]> = computed((): FilterParams[] => {
    return filters.value.filter(filter => filter.selected);
});
const selectedFiltersCount: Ref<number> = computed((): number => {
    return selectedFilters.value.length;
});
const searchResultsCount: Ref<number> = ref(0);
const showMorePlaces: Ref<boolean> = ref(props.startWithAllPlaces);
const filtersIsOpened: Ref<boolean> = ref(false);
const searchMode: Ref<boolean> = ref(true);

let map!: google.maps.Map;
let clusterer: MarkerClusterer | null = null;
let locationCoordinates: google.maps.LatLng | null = null;
let place: MapPlace = {
    id: '',
    latitude: 0,
    longitude: 0,
    distance: 0,
    expert_contract_id: '',
    title: '',
    city: '',
    address: '',
    region: '',
    email: '',
    phone: '',
    working_hours: '',
    special: '',
    infoWindow: null,
    marker: null
};
const selectedPlace: Ref<MapPlace> = ref(place);

onMounted((): void => {
    RepairShopService.getInstance().fetchPlaces(places.value, props.fetchParams, props.fetchUrl).then(() => {
        initMap();
    });
});

function initMap(): void {
    const mapId: string = 'map-with-places';
    mapLoader()
        .load()
        .then(() => {
            map! = createMap(mapId);
            map.addListener('zoom_changed', () => {
                unsetPlace(false);
            });
            setupMap();
            emit('map-ready', true);
        })
        .catch(() => {
            showError(Map.Error.MapCreationFail, initMap.name);
        });
}

function setupMap(): void {
    setupControls();
    addPlacesToMap();
    setupFilters();
    setupClusterer();
    restoreStoredPlace();
}

function setupClusterer(): void {
    clusterer = createClusterer(map!);
}

function setupFilters(): void {
    const regions: string[] = [...new Set(places.value.map(place => place.region))];
    regions.forEach(region => {
        const filter: FilterParams = {
            type: 'regions',
            param: region,
            selected: false
        };
        filters.value.push(filter);
    });
    filters.value.sort((param1: FilterParams, param2: FilterParams) => param1.param === defaultFilter()
        ? -1
        : (param2.param === defaultFilter() ? 1 : -1));
    setDefaultFilter();
}

function setupControls(): void {
    const mapControls: HTMLElement = document.getElementById('map-with-places-controls') as HTMLElement;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapControls);
}

function createMarker(place: MapPlace): google.maps.Marker {
    const marker: google.maps.Marker = new google.maps.Marker({
        position: {
            lat: Number(place.latitude),
            lng: Number(place.longitude)
        },
        title: place.title,
        map: map
    });
    marker.setVisible(false);
    placeIcon(marker, '', place.special);
    const mapPlace: HTMLElement = document.getElementById('place-info') as HTMLElement;
    const infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow({
        minWidth: 300,
        maxWidth: 350,
        content: mapPlace,
        disableAutoPan: false
    });
    marker.addListener('click', () => {
        selectPlace(place.id);
        selectedPlace.value.infoWindow = infoWindow;
        infoWindow.open({
            map: map,
            anchor: marker,
            shouldFocus: true
        });
    });
    marker.addListener('open', () => {
        selectedPlace.value.infoWindow = infoWindow;
        infoWindow.open({
            map: map,
            anchor: marker,
            shouldFocus: true
        });
    });
    if (String(place.id) === String(selectedPlace.value.id)) {
        selectedPlace.value.marker = marker;
        selectedPlace.value.infoWindow = infoWindow;
    }

    return marker;
}

function addPlacesToMap(): void {
    places.value.forEach((place: MapPlace) => {
        place.marker = createMarker(place);
    });
}

function clustererPlaces(): void {
    const markers: google.maps.Marker[] = [];
    clusterer!.clearMarkers();
    showingPlaces.value.forEach((place: MapPlace) => {
        if (isSet(place.marker)) {
            place.marker!.setVisible(true);
            markers.push(place.marker as google.maps.Marker);
        }
    });
    clusterer!.addMarkers(markers);
}

function onSearchKeyUp(term: string): void {
    searchTerm.value = term;
    updatePlaces();
}

function updatePlaces(): void {
    recalculateDistances(currentLocation());
    const topPlaces: MapPlace[] = searchInPlaces(searchTerm.value, true);
    const otherPlaces: MapPlace[] = searchInPlaces(searchTerm.value, false);
    if (searchMode.value) {
        const region: string = new AppCountry().iso().toLowerCase();
        const collator: Intl.Collator = new Intl.Collator(region, {
            ignorePunctuation: false,
            sensitivity: 'base',
            usage: 'sort'
        });
        otherPlaces.sort((place1: MapPlace, place2: MapPlace) => collator.compare(place1.title, place2.title));
    } else {
        topPlaces.sort((place1: MapPlace, place2: MapPlace) => place1.distance - place2.distance);
        otherPlaces.sort((place1: MapPlace, place2: MapPlace) => place1.distance - place2.distance);
    }
    nextTick(() => {
        showingPlaces.value = topPlaces.concat(otherPlaces);
        searchResultsCount.value = showingPlaces.value.length;
        trimTopResults();
        if (isSet(selectedPlace) && selectedPlace.value.id !== '') {
            const isStoredPlaceInSearchResults: boolean = showingPlaces.value.some(place => place.id === selectedPlace.value.id);
            if (!isStoredPlaceInSearchResults) {
                showingPlaces.value.push(selectedPlace.value);
            }
        }
        trimNearMeResults();
        clustererPlaces();
        if (searchMode.value || !showMorePlaces.value) {
            fitMap();
        }
    });
}

function currentLocation(): google.maps.LatLng {
    let coordinates!: google.maps.LatLng;
    if (isSet(locationCoordinates)) {
        coordinates = locationCoordinates!;
    } else {
        coordinates = mapSettings.defaultMapCenter;
    }

    return coordinates;
}

function selectPlace(id: string): void {
    const place: MapPlace = places.value.find(place => place.id === id) as MapPlace;
    if (isSet(place)) {
        selectedPlace.value = place;
        map.setCenter({lat: Number(place.latitude), lng: Number(place.longitude)});
        map.setZoom(mapSettings.maxZoom);
        placeIcon(place.marker!, 'selected', place.special);
        nextTick(() => {
            google.maps.event.trigger(place.marker as google.maps.Marker, 'open');
            emit('list-select');
        });
    }
}

function unsetPlace(cleanId: boolean = false): void {
    places.value.forEach((place: MapPlace) => {
        if (isSet(place.infoWindow)) {
            place.infoWindow!.close();
        }
        if (isSet(place.marker)) {
            placeIcon(place.marker!, '', place.special);
        }
    });
    if (cleanId) {
        selectedPlace.value.id = '';
    }
}

function storePlace(): void {
    const storedPlace: MapStorage = {
        id: selectedPlace.value.id,
        mapTypeId: String(map.getMapTypeId()),
        filters: filters.value,
    };
    props.formField.patch(storedPlace);
}

function restoreStoredPlace(): void {
    if (!new Value(props.formField.value.id).isEmpty()) {
        const storedPlace: MapPlace = places.value.find((place: MapPlace) => place.id === String(props.formField.value.id)) as MapPlace;
        if (isSet(storedPlace)) {
            filters.value = props.formField.value.filters;
            map.setMapTypeId(props.formField.value.mapTypeId);
            selectPlace(storedPlace.id);
        }
    }
}

function fitMap(): void {
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < showingPlaces.value.length; i++) {
        if (selectedPlace.value.marker !== showingPlaces.value[i].marker
            || isParamInFilter(showingPlaces.value[i].region)) {
            const coordinates: google.maps.LatLng = new google.maps.LatLng(showingPlaces.value[i].latitude, showingPlaces.value[i].longitude);
            bounds.extend(coordinates);
        }
    }
    if (!bounds.isEmpty()) {
        map!.fitBounds(bounds);
    }
}

function disableFilters(): void {
    showAllPlaces();
    clustererPlaces();
    searchTerm.value = '';
}

function setDefaultFilter(): void {
    if (filters.value.length > 0) {
        filters.value.forEach((filter: FilterParams) => filter.selected = false);
        filters.value[0].selected = true;
    }
    updatePlaces();
}

function showAllPlaces(): void {
    const allPlaces: MapPlace[] = places.value.filter((place: MapPlace) => place.special === 'true');
    const otherPlaces: MapPlace[] = places.value.filter((place: MapPlace) => place.special === 'false');
    showingPlaces.value = allPlaces.concat(otherPlaces);
    searchResultsCount.value = showingPlaces.value.length;
}

function recalculateDistances(mapCenter: google.maps.LatLng): void {
    places.value.forEach((place: MapPlace) => {
        place.distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(place.latitude, place.longitude), mapCenter)
    });
}

function onApplyFilters(params: FilterParams[]): void {
    searchTerm.value = '';
    filtersIsOpened.value = false;
    popupService().showPopup(PopupType.None);
    filters.value.forEach((filter: FilterParams) => {
        const selectedParam: FilterParams = params.find(param => param.selected && param.param === filter.param) as FilterParams;
        filter.selected = isSet(selectedParam);
    });
    updatePlaces();
    storePlace();
}

function onClearSearchString(): void {
    searchTerm.value = '';
    updatePlaces();
}

function onSearchModeChange(): void {
    searchTerm.value = '';
    unsetPlace(true);
    if (!searchMode.value) {
        searchMode.value = true;
        updatePlaces();
    } else {
        filtersIsOpened.value = true;
        popupService().showPopup(PopupType.CustomPopup);
    }
}

function onToggleMorePlacesList(): void {
    showMorePlaces.value = !showMorePlaces.value;
    updatePlaces();
}

function onChoosePlace(): void {
    storePlace();
    emit('change', selectedPlace.value.id);
    if (isSet(selectedPlace.value.infoWindow)) {
        selectedPlace.value.infoWindow!.close();
    }
}

function onCloseInfoWindow(): void {
    unsetPlace(true);
}

function onCloseFiltersPopup(): void {
    filtersIsOpened.value = false;
    popupService().showPopup(PopupType.None);
}

function onLocatePlacesNearMe(): void {
    onMapHome();
    searchMode.value = false;
    if (isSet(navigator.geolocation)) {
        navigator.geolocation.getCurrentPosition(
            (position: DynamicDictionary) => {
                const coordinates: google.maps.LatLng = new google.maps.LatLng(position.coords!.latitude, position.coords!.longitude);
                locationCoordinates = coordinates;
                showMorePlaces.value = false;
                searchMode.value = false;
                disableFilters();
                map!.setCenter(coordinates);
                updatePlaces();
                fitMap();
            }
        );
    } else {
        showError(Map.Error.MapUnsuportGeolocations, onLocatePlacesNearMe.name);
    }
}

function onZoomIn(): void {
    map!.setZoom(Number(map!.getZoom()) + 1);
}

function onZoomOut(): void {
    map!.setZoom(Number(map!.getZoom()) - 1);
}

function onMapHome(): void {
    unsetPlace();
    searchTerm.value = '';
    searchMode.value = true;
    setDefaultFilter();
    map!.setCenter(mapSettings.defaultMapCenter);
    recalculateDistances(map!.getCenter() as google.maps.LatLng);
    fitMap();
}

function onChangeBasemap(): void {
    changeBasemap(map!);
    storePlace();
}

function showPlaceInList(index: number): boolean {
    return index < topPlaces.value || showMorePlaces.value;
}

function showTopPlacesButton(): boolean {
    return searchResultsCount.value > topPlaces.value;
}

function showClearSearchInputButton(): boolean {
    return searchTerm.value.trim() !== '';
}

function defaultFilter(): string {
    return mapSettings.defaultCity;
}

function inputIconLeft(): InputTagIcon {
    return leftIcon;
}

function inputIconRight(): InputTagIcon {
    return rightIcon;
}

function filtersButtonLabel(): string {
    let label: string = translateForType(defaultFilter(), props.translationType);
    if (filters.value.length === selectedFiltersCount.value
        || selectedFiltersCount.value === 0) {
        label = translateForType('map_filter_all_regions', props.translationType);
    } else if (selectedFiltersCount.value === 1) {
        const selectedRegion: FilterParams = filters.value.find(filter => filter.selected) as FilterParams;
        if (isSet(selectedRegion)) {
            label = selectedRegion.param;
        }
    } else {
        label = translateForType('map_filter_regions', props.translationType) + ' ' + selectedFiltersCount.value;
    }

    return label;
}

function searchInPlaces(searchString: string, special: boolean): MapPlace[] {
    searchString = diacriticString(searchString.trim());

    return places.value.filter((place: MapPlace) => {
        let params = [...new Set(filters.value.map(param => param.param))];
        if (searchMode.value) {
            if (selectedFiltersCount.value > 0 && searchString === '') {
                const updatedFilters: FilterParams[] = filters.value.filter(filter => filter.selected) as FilterParams[];
                params = [...new Set(updatedFilters.map(param => param.param))];
            }
        }
        const title: string = diacriticString(place.title);
        const city: string = diacriticString(place.city);
        const address: string = diacriticString(place.address);

        return (title.match(searchString) || city.match(searchString) || address.match(searchString))
            && place.special === (special ? 'true' : 'false')
            && params.includes(place.region);
    });
}

function isParamInFilter(region: string): boolean {
    return filters.value.some(filter => filter.selected
        && filter.param === region);
}

function trimTopResults(): void {
    if (showingPlaces.value.length >= topPlaces.value && !showMorePlaces.value) {
        showingPlaces.value.length = topPlaces.value;
    }
}

function trimNearMeResults(): void {
    if (!searchMode.value && showMorePlaces.value) {
        showingPlaces.value = showingPlaces.value.filter((place: MapPlace) => place.distance < mapSettings.nearMeRadiusMeters);
    }
}

function showError(errorType: string, functionName: string): void {
    errorService().show(ErrorType.Error, props.componentName + '::'
        + functionName, translateForType(errorType, props.translationType));
}

function popupService(): Popup {
    return Popup.getInstance();
}

function errorService(): Error {
    return Error.getInstance();
}
</script>
<template>
    <div class="map-with-places"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <div class="map-container">
            <div class="popups" v-if="filtersIsOpened">
                <app-popup class="simple-popup">
                    <app-popup-filter
                        :filters="filters"
                        :default-filter="defaultFilter()"
                        :title="'map_filter_by_region'"
                        :search-input-placeholder="'map_search_region_placeholder'"
                        :toggle-filter-label="'map_filter_all_regions'"
                        @apply-filters="onApplyFilters($event)"
                        @close-filters-popup="onCloseFiltersPopup()">
                    </app-popup-filter>
                </app-popup>
            </div>
            <div class="search-panel">
                <app-input-text
                    class="search-box"
                    :left-icon="inputIconLeft()"
                    :placeholder="translateForType('map_search_places_placeholder', translationType)"
                    :right-icon="showClearSearchInputButton() ? inputIconRight() : undefined"
                    @keyup="onSearchKeyUp"
                    @left-icon-click="updatePlaces()"
                    @right-icon-click="onClearSearchString()">
                </app-input-text>
                <div class="buttons">
                    <button class="filters-button"
                            v-bind:class="{'selected': searchMode}"
                            @click="onSearchModeChange()">
                        {{ filtersButtonLabel() }}
                        <svg class="arrow" width="10" height="6" viewBox="0 0 10 6" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path class="stroke" d="M1 1L5 5L9 1" stroke="#9297A0" stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <button class="filters-button"
                            v-bind:class="{'selected': !searchMode}"
                            @click="onLocatePlacesNearMe()">
                        <svg class="crosshair" width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle class="stroke" cx="9.49858" cy="8.99956" r="5.79545" stroke="#9297A0"
                                    stroke-width="1.5"/>
                            <circle class="fill" cx="9.5" cy="9" r="3" fill="#9297A0"/>
                            <path class="stroke" d="M9.5 1V17" stroke="#9297A0" stroke-width="1.5"
                                  stroke-linecap="round"/>
                            <path class="stroke" d="M17.5 9L1.5 9" stroke="#9297A0" stroke-width="1.5"
                                  stroke-linecap="round"/>
                        </svg>
                        {{ translateForType('map_button_geolocation', translationType) }}
                    </button>
                </div>
                <ul v-if="searchResultsCount > 0"
                    class="search-results">
                    <li v-for="(place, index) in showingPlaces"
                        :key="place.id"
                        class="item"
                        v-show="showPlaceInList(index)"
                        @click="selectPlace(place.id)">
                        <div class="label">
                            <div class="title">
                                {{ place.title }}
                                <svg class="star" v-if="place.special === 'true'" width="12" height="11"
                                     viewBox="0 0 12 11"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.47527 6.72718C2.68742 6.88594 2.75814 7.15794 2.7111 7.40731L2.02746 10.1504C1.88602 10.7398 2.54615 11.2159 3.08838 10.8985L5.6112 9.42506C5.84704 9.28907 6.1299 9.28907 6.36575 9.42506L8.88857 10.8985C9.4308 11.2159 10.1146 10.7397 9.9495 10.1504L9.26534 7.40731C9.19462 7.15793 9.28886 6.88595 9.50118 6.72718L11.741 4.89098C12.2362 4.48301 11.9768 3.73488 11.3402 3.6895L8.41657 3.46274C8.15722 3.44012 7.92137 3.28136 7.80362 3.03197L6.6483 0.425054C6.41246 -0.141685 5.56367 -0.141685 5.32803 0.425054L4.19637 3.03197C4.10214 3.28135 3.86631 3.43995 3.58342 3.46274L0.659824 3.6895C0.0232032 3.73488 -0.236144 4.5056 0.259036 4.89098L2.47527 6.72718Z"
                                        fill="#00B4AD"/>
                                </svg>
                                <div class="arrow">
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9.5L5 5.5L1 1.5" stroke="#9297A0" stroke-width="1.5"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="address"
                                 v-html="place.address + ', ' + place.city"></div>
                        </div>
                    </li>
                </ul>
                <button v-if="showTopPlacesButton"
                        class="button outside show-more-button"
                        @click="onToggleMorePlacesList()"
                        v-text="showMorePlaces
            ? translateForType('map_button_show_less', translationType)
            : translateForType('map_button_show_more', translationType)">
                </button>
            </div>
            <div class="map-with-slot">
                <slot name="map-above"></slot>
                <div id="map-with-places" data-scroll="map-with-places" class="map"></div>
            </div>
            <div class="hidden">
                <app-map-controls
                    id="map-with-places-controls"
                    :options="options"
                    @map-zoom-in="onZoomIn"
                    @map-zoom-out="onZoomOut"
                    @map-home="onMapHome"
                    @map-geolocate="onLocatePlacesNearMe"
                    @map-basemap="onChangeBasemap">
                </app-map-controls>
                <div id="place-info" class="place-info">
                    <div class="google-info-box-close-button"></div>
                    <div class="label title-row">
                        <div class="title" v-text="selectedPlace.title"></div>
                        <!--button class="close-button"
                                @click="onCloseInfoWindow()">
                            <svg width="22" height="22" viewBox="-1 0 23 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="23" height="23" rx="12" fill="#F5F6F8"/>
                                <path d="M9 9L15 15M15 9L9 15" stroke="#9297A0" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </button-->
                    </div>
                    <div class="label address">
                        <svg class="icon" width="23" height="23" viewBox="2 0 23 23" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                                stroke="#00B4AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                                stroke="#00B4AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ selectedPlace.address + ', ' + selectedPlace.city }}
                    </div>
                    <div class="contacts">
                        <div v-if="selectedPlace.phone !== ''" class="label phone">
                            <svg class="icon" width="23" height="23" viewBox="0 0 23 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.278 4.90918C15.2547 5.09975 16.1523 5.57744 16.856 6.28112C17.5597 6.98479 18.0374 7.88244 18.228 8.85918M14.278 0.90918C16.3072 1.13462 18.1996 2.04335 19.6442 3.48619C21.0889 4.92902 22 6.82019 22.228 8.84918M21.228 16.8292V19.8292C21.2291 20.1077 21.172 20.3833 21.0605 20.6385C20.9489 20.8937 20.7853 21.1228 20.58 21.311C20.3748 21.4993 20.1325 21.6427 19.8687 21.7319C19.6049 21.8211 19.3253 21.8542 19.048 21.8292C15.9708 21.4948 13.015 20.4433 10.418 18.7592C8.00179 17.2238 5.9533 15.1754 4.41796 12.7592C2.72794 10.1504 1.6762 7.18017 1.34796 4.08918C1.32297 3.81265 1.35584 3.53394 1.44446 3.2708C1.53309 3.00767 1.67553 2.76587 1.86273 2.5608C2.04992 2.35573 2.27777 2.19189 2.53175 2.0797C2.78574 1.96751 3.0603 1.90944 3.33796 1.90918H6.33796C6.82327 1.9044 7.29375 2.07626 7.66172 2.39271C8.02969 2.70917 8.27004 3.14863 8.33796 3.62918C8.46458 4.58925 8.69941 5.53191 9.03796 6.43918C9.1725 6.7971 9.20162 7.18609 9.12187 7.56006C9.04211 7.93403 8.85682 8.27729 8.58796 8.54918L7.31796 9.81918C8.74152 12.3227 10.8144 14.3956 13.318 15.8192L14.588 14.5492C14.8598 14.2803 15.2031 14.095 15.5771 14.0153C15.951 13.9355 16.34 13.9646 16.698 14.0992C17.6052 14.4377 18.5479 14.6726 19.508 14.7992C19.9937 14.8677 20.4374 15.1124 20.7545 15.4867C21.0716 15.861 21.2401 16.3388 21.228 16.8292Z"
                                    stroke="#00B4AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {{ selectedPlace.phone }}
                        </div>
                        <div v-if="selectedPlace.email !== ''" class="label email">
                            <svg class="icon" width="23" height="23" viewBox="1 0 23 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                                    stroke="#00B4AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22 6L12 13L2 6" stroke="#00B4AD" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            {{ selectedPlace.email }}
                        </div>
                    </div>
                    <div v-if="selectedPlace.working_hours !== ''" class="label working-hours">
                        <svg class="icon" width="23" height="23" viewBox="1 0 23 23" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                stroke="#00B4AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 6V12L16 14" stroke="#00B4AD" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                        {{ selectedPlace.working_hours }}
                    </div>
                    <button class="button green choose-button" v-if="showSelectPlaceButton"
                            @click="onChoosePlace">
                    <span class="truncate">{{
                            translateForType('map_button_choose', translationType) + ' ' + selectedPlace.title
                        }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.map-with-places {
    width: 100%;

    .map-with-slot {
        width: 100%;

        @include respond-above('lg') {
            width: 546px;
        }
    }

    .map-container {
        display: flex;
        flex-wrap: wrap;

        .search-box {
            width: 100%;
            margin-bottom: var(--size-nano);
        }

        .search-panel {
            width: 100%;
            margin-right: 0;

            .buttons {
                display: flex;
                margin: 7px -2px var(--size-small) 1px;

                .filters-button {
                    display: flex;
                    border: 1px solid var(--component-color-border-default);
                    background-color: var(--white);
                    width: 50%;
                    align-items: center;
                    text-align: center;
                    margin-left: -1px;
                    margin-right: -1px;
                    justify-content: center;
                    min-height: 36px;

                    &.selected {
                        border: 2px solid var(--brand-teal);
                        border-radius: 4px;
                        z-index: 3;
                    }

                    &:hover {
                        .stroke {
                            stroke: var(--brand-teal);
                        }

                        .fill {
                            fill: var(--brand-teal);
                        }
                    }

                    .arrow {
                        margin-left: var(--size-nano);
                    }

                    .crosshair {
                        margin-right: var(--size-pico);
                    }
                }
            }

            .search-results {
                margin-bottom: 14px;
                border: 1px solid var(--component-color-border-default);
                max-height: 352px;
                overflow-y: auto;

                .item {
                    display: flex;
                    height: 85px;
                    cursor: pointer;

                    &:not(:first-child) {
                        border-top: 1px solid var(--component-color-border-default);
                    }

                    .label {
                        margin: auto auto auto var(--size-normal);
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;

                        .title {
                            font-weight: bold;
                        }

                        .star {
                            margin-left: 3px;
                        }

                        .arrow {
                            float: right;
                            margin: var(--size-pico);
                        }

                        .address {
                            font-size: var(--font-size-nano);
                        }

                        @include respond-above('lg') {
                            width: 300px;
                        }
                    }
                }
            }

            .show-more-button {
                width: 100%;
                margin-top: var(--size-small);
                margin-bottom: 35px;

                @include respond-above('lg') {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            @include respond-above('lg') {
                width: 314px;
                margin-right: var(--size-small);
            }
        }

        .map {
            width: 100%;
            height: 546px;
            border-radius: 8px;
        }
    }

    .google-info-box-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background-color: white;
        z-index: 2;
    }

    .place-info {
        flex-direction: column;
        margin: 10px;

        .title-row {
            display: flex;
            margin-top: 9px;

            .title {
                font-size: var(--font-size-small);
                font-weight: bolder;
                width: 100%;
                align-items: center;
            }

            .close-button {
                width: 24px;
                height: 24px;

                &:hover {
                    path {
                        stroke: var(--text-color-default);
                    }
                }
            }
        }

        .label {
            display: flex;
            font-size: var(--font-size-nano);
            line-height: 28px;
            margin-bottom: 20px;
            align-items: center;

            .icon {
                margin-right: var(--size-pico);
            }
        }

        .contacts {
            div:first-child {
                margin-right: var(--size-nano);
            }

            @include respond-above('lg') {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .working-hours {
            display: none;

            @include respond-above('lg') {
                display: flex;
            }
        }

        .choose-button {
            font-size: var(--font-size-nano);
            border: 1px solid var(--brand-teal);
            width: 100%;
            border-radius: 10px;
            background-color: var(--brand-teal);
            color: var(--white);
            padding: 0 10px;

            .truncate {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @include respond-above('lg') {
                font-size: var(--font-size-tiny);
            }
        }
    }
}
</style>
