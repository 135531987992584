import DataLayer from '@/services/data.layer.service';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import moment from 'moment';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useDefine} from '@/Composables/Define';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';

export default class TravelDataLayer {
    private btaBase: OneBase = OneBaseService.getInstance();
    private isSet = useDefine().isSet;

    public pushDataLayer(policyPlan: string): void {
        const travellers: DynamicDictionary = this.dataLayerTravellers();
        const dataLayer: DataLayer = DataLayer.getInstance();
        const metric0: number = 0;
        const metric1: number = 1;
        const metric2: number = 2;
        dataLayer
            .useCheckout()
            .addActionParam('step', this.btaBase.step.value)
            .addActionParam('option', DataLayerType.ProductTypeTravel)
            .newProduct()
            .addProductParam('name', DataLayerType.TravelInsurance)
            .addProductParam('id', DataLayerType.TravelProductFakeId)
            .addProductParam('category', DataLayerType.TravelInsurance)
            .addProductParam('coupon', '')
            .addProductParam('price', this.dataLayerProductPrice(policyPlan))
            .addProductParam('quantity', travellers.total !== 0 ? travellers.total : '1')
            .addProductParam('variant', this.variant(policyPlan))
            .addProductParam('dimension1', this.activityName())
            .addProductParam('dimension2', this.dataLayerTripDuration())
            .addProductParam('dimension8', this.destination())
            .addProductParam('metric1', this.isSet(travellers.groups[metric0]) ? travellers.groups[metric0] : '')
            .addProductParam('metric2', this.isSet(travellers.groups[metric1]) ? travellers.groups[metric1] : '')
            .addProductParam('metric3', this.isSet(travellers.groups[metric2]) ? travellers.groups[metric2] : '')
            .buildAndPush();
    }

    private variant(policyPlan: string): string {
        let result: string = '';
        if (this.isSet(policyPlan)) {
            result = policyPlan;
        } else if (this.isSet(this.btaBase.userStorage.stepStorageData.policyPlanId)) {
            result = this.btaBase.userStorage.stepStorageData.policyPlanId;
        }

        return result;
    }

    private dataLayerProductPrice(policyPlan: string): number {
        let price: number = 0;
        const currentPolicyPlan: string = this.variant(policyPlan);
        if (currentPolicyPlan !== '') {
            this.btaBase.userStorage.storageData.forEach((value: DynamicDictionary) => {
                if (value.id === currentPolicyPlan) {
                    price = this.btaBase.user.isLogged() ?
                        value.authenticatedPrice : value.guestPrice;
                }
            });
        }

        return price;
    }

    private dataLayerTravellers(): DynamicDictionary {
        let total: number = 0;
        const groups: number[] = [];
        if (this.isSet(this.btaBase.userStorage.stepStorageData.travelers)) {
            const travellers: DynamicDictionary[] = JSON.parse(this.btaBase.userStorage.stepStorageData.travelers);
            travellers.forEach((value: DynamicDictionary) => {
                total += value.count;
                groups.push(value.count);
            });
        }

        return {
            total: total,
            groups: groups
        };
    }

    private activityName(): string {
        return this.isSet(this.btaBase.userStorage.stepStorageData.activityName) ?
            this.btaBase.userStorage.stepStorageData.activityName : '';
    }

    private dataLayerTripDuration(): string {
        let result: string = '';
        if (this.isSet(this.btaBase.userStorage.stepStorageData.multipleTrips) &&
            this.btaBase.userStorage.stepStorageData.multipleTrips) {
            result = String(DataLayerType.TravelProductMultipleDays)
                .split('@')
                .join(this.btaBase.userStorage.stepStorageData.tripDuration);
        } else {
            if (this.isSet(this.btaBase.userStorage.stepStorageData.travelStartDate) &&
                this.isSet(this.btaBase.userStorage.stepStorageData.travelEndDate)) {
                const startDate: moment.Moment = moment(this.btaBase.userStorage.stepStorageData.travelStartDate);
                const endDate: moment.Moment = moment(this.btaBase.userStorage.stepStorageData.travelEndDate);
                const difference: number = endDate.diff(startDate, 'days');
                result = String(DataLayerType.TravelProductDays)
                    .split('@')
                    .join(String(difference));
            }
        }

        return result;
    }

    private destination(): string {
        return this.isSet(this.btaBase.userStorage.stepStorageData.destination) ?
            this.btaBase.userStorage.stepStorageData.destination : ''
    }
}
