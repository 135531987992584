<script setup lang="ts">
import PayByLinkService from '@/Apps/PayByLink/Pay/Services/PayByLinkService';
import OneDate from '@/assets/libraries/Date/OneDate';
import AgreementPeriodMap from '@/Apps/PayByLink/Classes/AgreementPeriodMap';
import OfferRisk from '@/Apps/PayByLink/Pay/Interfaces/OfferRiskInterface';
import {useTranslate} from '@/Composables/Translate';
import {useHtml} from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import {computed, getCurrentInstance, onMounted, ref, Ref} from 'vue';
import {AxiosResponse} from 'axios';
import {useDefine} from '@/Composables/Define';
import Url from '@/Enums/UrlEnum';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import RequestService from '@/services/request.service';
import {useScroll} from '@/Composables/Scroll';
import {useRouter, Router} from 'vue-router';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import EventBus from '@/services/event.bus.service';
import AppTextWithTip from '@/Components/TextWithTip/TextWithTip.vue';
import OneBaseService from '@/services/OneBaseService';
import {usePrice} from '@/Composables/Price';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';
import SettingsService from '@/services/settings.service';
import VehicleType from '@/Apps/PayByLink/Pay/Enums/VehicleType';
import { usePayByLink } from '@/Apps/PayByLink/Composables/PayByLink';

const {translateForType} = useTranslate();
const {sparse} = usePrice();
const {acceptCalculationResponse} = usePayByLink();

const router: Router = useRouter();
const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
const TranslationType: string = 'mtpl_renewal';

const paymentCount = payByLinkService.payByLink.value.paymentCount;
const editRenewalOfferMandatory: Ref<boolean> = computed((): boolean => {
    return SettingsService.getInstance().editRenewalOfferMandatory();
});

const canEditOffer: Ref<boolean> = computed((): boolean => {
    return !payByLinkService.hasCalculationError.value;
});

const showYoungestDriver: Ref<boolean> = computed((): boolean => {
    return payByLinkService.payByLink.value.vehicleTypeId !== VehicleType.C3;
});

let viewIsReady: Ref<boolean> = ref(false);

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    EventBus.getInstance().subscribe('offer-failed', showFetchOfferError)
    useScroll().scrollToTop();
    fetchOffer().then((): void => {
    }).catch((): void => {
        payByLinkService.hasCalculationError.value = true;
        viewIsReady.value = true;
    });
});

async function fetchOffer(): Promise<void> {
    return RequestService.getInstance().get({
        uri: Url.Ajax.mtplRenewalFetchOffer,
        content: {
            hash: payByLinkService.payByLink.value.id,
            driverAge: String(payByLinkService.payByLink.value.youngestDriver),
        },
    }).then((response: AxiosResponse): void => {
        if (useDefine().isSet(response)) {
            if (acceptCalculationResponse(response)) {
                TransferStateService.getInstance().set('mtplOffer', response.data.data.body.offer);
                payByLinkService.init();
                viewIsReady.value = true;
            } else {
                throw response;
            }
        }
    });
}

function offerValidity(): string {
    return OneDate.short(payByLinkService.payByLink.value.offerValidity.date);
}

function monthsLabel(): string {
    const map: AgreementPeriodMap = new AgreementPeriodMap();
    const periodIc: string = payByLinkService.payByLink.value.periodIc;
    const months: number = map.monthsByIc(periodIc);
    const monthsLabel: string = map.monthsLabelByIc(periodIc);

    return `${String(months)} ${monthsLabel}`
}

function showFetchOfferError(): void {
    PopupService.getInstance().show(
        new OnePopup().withType().simpleError
            .withDescription(useTranslate()
                .translateForType('fetch_offer_error', TranslationType))
    );
}

function showRisksSection(): boolean {
    return includedRisks().length > 0
        || (allowedRisks().length > 0 && !payByLinkService.hasCalculationError.value);
}

function showAllowedRiskSection(): boolean {
    return allowedRisks().length > 0 && viewIsReady.value && canEditOffer.value;
}

function showLineBreak(): boolean {
    return includedRisks().length > 0
        && allowedRisks().length > 0
        && viewIsReady.value
        && canEditOffer.value;
}

function includedRisks(): OfferRisk[] {
    return payByLinkService.payByLink.value.offerRisks
        .filter((risk: OfferRisk): boolean => risk.included);
}

function allowedRisks(): OfferRisk[] {
    return payByLinkService.payByLink.value.offerRisks
        .filter((risk: OfferRisk): boolean => !risk.included && risk.canInclude);
}

function riskIcon(riskIc: string, status: string): string {
    const iconName: string = [riskIc, status].join('-');

    return useHtml().imgTag(iconAttributes(iconName));
}

function offerPeriodLabel(): string {
    const [startDateString, endDateString] = payByLinkService.payByLink.value.insurancePeriod.split(' - ');
    const startDate = new MomentBuilder().withInitialDate(startDateString).build();
    const endDate = new MomentBuilder().withInitialDate(endDateString).build();

    const formatDate = (date: moment.Moment) => {
        return `(${date.format('HH:mm')})`;
    };

    return `${OneDate.short(startDate.toDate())} ${formatDate(startDate)} - ${OneDate.short(endDate.toDate())} ${formatDate(endDate)}`;
}

function iconAttributes(iconName: string): ImageTagAttributes {
    return {
        class: '',
        src: `images/one/mtpl-renewal/policy-options-round/${iconName}.svg`,
        width: 52,
        height: 52,
    }
}

function onMakePaymentClick(): void {
    payByLinkService.fields.offerPeriod = '';
    payByLinkService.fields.offerPaymentCount = '';
    payByLinkService.fields.driverAge = '';
    router.push({name: 'pay-by-link-pay-summary'});
}

function onEditOfferClick(): void {
    if (payByLinkService.hasCalculationError.value) {
        showFetchOfferError();
    } else {
        router.push({name: 'pay-by-link-pay-edit'});
    }
}
</script>

<template>
    <div class="step-container">
        <img src="images/one/mtpl-renewal/auto-renewal-desktop.png" class="item-desktop hero-banner" alt="">
        <img src="images/one/mtpl-renewal/auto-renewal-mobile.png" class="item-mobile hero-banner-mobile" alt="">
        <div class="agreement-details">
            <div class="header">{{ translateForType('details_view_title', TranslationType) }}</div>
            <section class="whiteboard" id="whiteboard-0">
                <div class="whiteboard-title">{{ translateForType('mtpl_price', TranslationType) }}</div>
                <div class="summary">
                    <app-text-with-tip
                        :title="translateForType('payment_due_now', TranslationType)"
                        :tip-description="translateForType('payment_due_now_description', TranslationType)">
                    </app-text-with-tip>
                    <span class="total">{{ sparse(payByLinkService.payByLink.value.paymentAmount, false) }}
                        <span class="currency">&euro;</span>
                    </span>
                </div>
                <hr class="line-break"/>
                <ul class="summary-list">
                    <li class="item" id="whiteboard-item-0-0">
                        <span class="key">{{ translateForType('number_of_payments', TranslationType) }}</span>
                        <span class="value">{{ paymentCount }}</span>
                    </li>
                    <li class="item" id="whiteboard-item-0-1">
                        <span class="key">{{ translateForType('total_price', TranslationType) }}</span>
                        <span
                            class="value">{{
                                sparse(payByLinkService.payByLink.value.premium, false) + '&nbsp;&euro;'
                            }}</span>
                    </li>
                    <li class="item" id="whiteboard-item-0-2">
                        <span class="key">{{ translateForType('valid_till', TranslationType) }}</span>
                        <span class="value">{{ offerValidity() }}</span>
                    </li>
                </ul>
                <app-content-loader v-if="!viewIsReady"></app-content-loader>
                <div v-if="viewIsReady" class="buttons">
                    <a class="button red" v-if="canEditOffer && editRenewalOfferMandatory" @click="onEditOfferClick()">
                        <span>{{ translateForType('edit_and_pay', TranslationType) }}</span>
                        <img src="images/one/arrow-right.svg" alt="arrow-right">
                    </a>
                    <a class="button outside" v-if="!canEditOffer || !editRenewalOfferMandatory" @click="onEditOfferClick()"
                       :class="{'disabled': !canEditOffer}">
                        <img src="images/one/edit-item.svg" alt="edit-item">
                        <span>{{ translateForType('edit_policy', TranslationType) }}</span>
                    </a>
                    <a class="button red" v-if="!canEditOffer || !editRenewalOfferMandatory" @click="onMakePaymentClick()">
                        <span>{{ translateForType('pay', TranslationType) }}</span>
                        <img src="images/one/arrow-right.svg" alt="arrow-right">
                    </a>
                </div>
            </section>
            <section class="whiteboard" id="whiteboard-1">
                <div class="whiteboard-title">{{ translateForType('mtpl_details', TranslationType) }}</div>
                <ul class="list list-border">
                    <li class="item" id="whiteboard-item-1-0">
                        <span>{{ translateForType('insured', TranslationType) }}</span>
                        <span class="item-value">{{ payByLinkService.payByLink.value.objectName }}</span>
                    </li>
                    <li class="item" id="whiteboard-item-1-1">
                        <span>{{ translateForType('mtpl_period', TranslationType) }}</span>
                        <div class="item-name period-info">
                            <span class="period-duration">{{ monthsLabel() }}</span>
                            <span class="period-dates">{{ offerPeriodLabel() }}</span>
                        </div>
                    </li>
                    <li class="item" id="whiteboard-item-1-2">
                        <span>{{ translateForType('policy_holder', TranslationType) }}</span>
                        <span class="item-value">{{ payByLinkService.payByLink.value.holderName }}</span>
                    </li>
                    <li v-if="payByLinkService.payByLink.value.youngestDriver && showYoungestDriver"
                        class="item" id="whiteboard-item-1-3">
                        <span>{{ translateForType('youngest_driver', TranslationType) }}</span>
                        <span class="item-value">{{ payByLinkService.payByLink.value.youngestDriver }}</span>
                    </li>
                </ul>
            </section>
            <section class="whiteboard" v-if="showRisksSection()" id="whiteboard-2">
                <div class="whiteboard-title">{{ translateForType('options', TranslationType) }}</div>
                <div class="options" v-if="includedRisks().length > 0">
                    <div class="title">{{ translateForType('included_risks', TranslationType) }}</div>
                    <div class="item" v-for="(item, index) in includedRisks()" :key="index"
                         :id="'whiteboard-included-item-2-' + index">
                        <div class="icon" v-html="riskIcon(item.ic, 'included')"></div>
                        {{ translateForType(item.ic, TranslationType) }}
                    </div>
                    <hr class="line-break" v-if="showLineBreak()"/>
                </div>
                <app-content-loader v-if="!viewIsReady"></app-content-loader>
                <div class="options" v-if="showAllowedRiskSection()">
                    <div class="title">{{ translateForType('can_add_at_next_step', TranslationType) }}</div>
                    <div class="item" v-for="(item, index) in allowedRisks()" :key="index"
                         :id="'whiteboard-allowed-item-2-' + index">
                        <div class="icon" v-html="riskIcon(item.ic, 'grey')"></div>
                        {{ translateForType(item.ic, TranslationType) }}
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    margin-bottom: var(--size-big);

    .hero-banner {
        margin-top: -30px;
        margin-bottom: var(--size-small);
        height: 420px;
        width: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 30px;
    }

    .hero-banner-mobile {
        width: 100%;
    }

    .agreement-details {
        margin-top: var(--size-small);
        padding: var(--size-small);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-small);

        @include respond-above('sm') {
            margin-top: 220px;
            gap: var(--size-big);
            align-items: center;
        }

        .header {
            width: 100%;
            position: relative;
            color: var(--text-color-default);
            text-align: center;
            font-size: var(--font-size-big);
            font-weight: 700;
            line-height: 120%;

            @include respond-above('sm') {
                display: flex;
                height: 115px;
                align-items: flex-end;
                color: var(--text-color-inverse);
                letter-spacing: -0.48px;
                font-size: var(--font-size-xl);
                max-width: 800px;
            }
        }

        .whiteboard {
            display: flex;
            padding: var(--size-small);
            flex-direction: column;
            align-items: flex-start;
            border-radius: var(--size-tiny);
            background: var(--component-color-background-base);
            gap: var(--size-small);
            width: 100%;
            max-width: 800px;

            @include respond-above('sm') {
                padding: var(--size-big);

            }

            .whiteboard-title {
                color: var(--text-color-default);
                font-size: var(--font-size-medium);
                font-weight: 700;
            }

            .title {
                font-size: var(--font-size-small);
                font-weight: 700;
            }

            .summary {
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;

                .total {
                    white-space: nowrap;
                    color: var(--system-color-success-dark);
                    font-size: var(--font-size-big);
                    font-weight: 700;
                    letter-spacing: -0.32px;
                }

                .currency {
                    font-size: var(--font-size-tiny);
                    font-weight: 600;
                }
            }

            .summary-list {
                display: flex;
                flex-direction: column;
                gap: var(--size-nano);
                align-self: stretch;

                .item {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .key {
                        color: var(--text-color-subtle);
                        font-size: var(--font-size-nano);
                        font-weight: 500;
                    }

                    .value {
                        font-weight: 600;
                    }
                }
            }

            .line-break {
                width: 100%;
                height: 1px;
                background-color: var(--component-color-border-default);
            }

            .content-loader {
                align-self: center;

                :deep(img) {
                    height: 40px;
                }
            }

            .buttons {
                display: flex;
                align-items: flex-start;
                gap: var(--size-nano);
                align-self: stretch;
                flex-direction: column-reverse;

                @include respond-above('sm') {
                    flex-direction: row;
                    gap: var(--size-small);
                }

                .button {
                    display: flex;
                    height: 52px;
                    min-height: 52px;
                    padding: 0 var(--button-horizontal-padding);
                    justify-content: center;
                    align-items: center;
                    gap: var(--size-nano, 12px);
                    flex: 1 0 0;
                }

                .border {
                    border-radius: var(--button-corner-radius);
                    border: 1px solid var(--button-color-border-secondary-default);
                }
            }

            .list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;

                .item {
                    display: flex;
                    padding: var(--size-small);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--size-pico);
                    align-self: stretch;
                    font-size: var(--font-size-nano);

                    @include respond-above('sm') {
                        flex-direction: row;
                        justify-content: space-between;
                        padding: var(--size-small);
                    }

                    .item-value {
                        font-weight: 600;

                        @include respond-above('sm') {
                            width: 50%;
                            text-align: right;
                        }
                    }

                    .item-name {
                        font-weight: 600;

                        @include respond-above('sm') {
                            width: 50%;
                            text-align: right;
                        }

                        &.payment {
                            text-align: left;
                        }

                        &.period-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            @include respond-above('sm') {
                                width: 50%;
                                text-align: right;
                                align-items: flex-end;
                            }
                        }
                    }
                }
            }

            .list-border {
                border-radius: var(--size-pico);
                border: 1px solid var(--black-100);

                .item {
                    &:not(:last-child) {
                        border-bottom: 1px solid var(--black-100);
                    }
                }
            }

            .options {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                gap: 12px;

                .item {
                    display: flex;
                    align-items: center;
                    gap: var(--size-tiny);
                    align-self: stretch;
                    font-size: var(--size-tiny);
                    font-weight: 600;
                }
            }

        }
    }

    @include respond-above('sm') {
        margin-bottom: var(--size-huge);
    }

    a {
        cursor: pointer;

        &.disabled {
            img {
                filter: var(--to-color-grey);
            }
        }
    }
}
</style>
